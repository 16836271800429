var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AuditFormSchema, Field, FIELD_WIDGET_AUDIO, FIELD_WIDGET_CHECKBOXINPUT, FIELD_WIDGET_DATEINPUT, FIELD_WIDGET_DATETIMEINPUT, FIELD_WIDGET_DOBINPUT, FIELD_WIDGET_FILEINPUT, FIELD_WIDGET_FLOATINPUT, FIELD_WIDGET_MODEL_HEADING, FIELD_WIDGET_MODEL_SELECT, FIELD_WIDGET_MODEL_SELECT_MULTIPLE, FIELD_WIDGET_MULTIFILEINPUT, FIELD_WIDGET_NUMBERINPUT, FIELD_WIDGET_RADIOSELECT, FIELD_WIDGET_REMOTE_FORM_SELECT, FIELD_WIDGET_SELECT, FIELD_WIDGET_SELECTMULTIPLE, FIELD_WIDGET_SHORTDATEINPUT, FIELD_WIDGET_STOPWATCH, FIELD_WIDGET_TEXTAREA, FIELD_WIDGET_TEXTINPUT, FIELD_WIDGET_TIMEINPUT, INSTITUTION, ROOM, } from '../../api/models/audit-form-schema';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { merge, of } from 'rxjs';
import { evaluateConditionHasValue, getConditionalValue, getDefaultConditionalValue, getFieldChoices, getFieldImage, getSetValue, getSubObservationValuesFromAudit, isFieldRequired, isFieldVisible } from '../../utils/conditionals';
import { AuditForm, CUSTOM_OBSERVATION_NAME, LayoutType } from '../../api/models/audit-form';
import { isHandHygieneAudit } from '../../utils/misc';
import { COMPLIANCE_INCOMPLIANT, ComplianceCalculator, Incompliant } from '../../compliance/calculator';
import { AuditFormService } from '../../audit-form.service';
import { MatDialog } from '@angular/material';
import { Audit } from '../audit';
import { SettingsService } from '../../settings/settings.service';
import { QipService } from '../../qip/qip.service';
import { IssueDialogArguments } from '../../qip/issues-widget/issue-dialog-arguments';
import { CreateIssueDialogComponent } from '../../qip/issues-widget/create-issue-dialog/create-issue-dialog.component';
import { Issue } from '../../api/models/issue';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { choiceValidator, createErrorMessages, createFormFieldValidators, integerValidator } from '../observation/validators';
import { AuditService } from '../audit.service';
import { InstitutionService } from '../../institution.service';
import { debounceTime, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import { IssuesDialogComponent } from '../../qip/issues-widget/issues-dialog/issues-dialog.component';
/**
 * Component representing a Question in Audit form.
 * Wraps specialized widget field depending on field type
 */
var FormFieldComponent = /** @class */ (function () {
    function FormFieldComponent(auditFormService, dialog, settingsService, qipService, domSanitizer, translateService, logger, auditService, institutionService, apiService, cdr) {
        this.auditFormService = auditFormService;
        this.dialog = dialog;
        this.settingsService = settingsService;
        this.qipService = qipService;
        this.domSanitizer = domSanitizer;
        this.translateService = translateService;
        this.logger = logger;
        this.auditService = auditService;
        this.institutionService = institutionService;
        this.apiService = apiService;
        this.cdr = cdr;
        this.subForm = null;
        this.editing = false;
        this.setValuePlaceholders = {};
        this.hardcodedData = {};
        this.complianceCalculator = null;
        this.subscriptions = [];
        this.maxDate = new Date();
        this.startDOBDate = new Date(1970, 0, 1);
        // supported widget types:
        this.widgetTypes = {
            SELECT: FIELD_WIDGET_SELECT,
            SELECT_RADIO: FIELD_WIDGET_RADIOSELECT,
            SELECT_MULTIPLE: FIELD_WIDGET_SELECTMULTIPLE,
            TEXT_INPUT: FIELD_WIDGET_TEXTINPUT,
            TEXT_AREA: FIELD_WIDGET_TEXTAREA,
            NUMBER_INPUT: FIELD_WIDGET_NUMBERINPUT,
            STOPWATCH_INPUT: FIELD_WIDGET_STOPWATCH,
            CHECKBOX_INPUT: FIELD_WIDGET_CHECKBOXINPUT,
            DATE_INPUT: FIELD_WIDGET_DATEINPUT,
            TIME_INPUT: FIELD_WIDGET_TIMEINPUT,
            DATE_TIMEINPUT: FIELD_WIDGET_DATETIMEINPUT,
            FLOAT_INPUT: FIELD_WIDGET_FLOATINPUT,
            SHORT_DATE_INPUT: FIELD_WIDGET_SHORTDATEINPUT,
            DOB_INPUT: FIELD_WIDGET_DOBINPUT,
            FILE_INPUT: FIELD_WIDGET_FILEINPUT,
            MULTIFILE_INPUT: FIELD_WIDGET_MULTIFILEINPUT,
            AUDIO_INPUT: FIELD_WIDGET_AUDIO,
            MODEL_SELECT: FIELD_WIDGET_MODEL_SELECT,
            REMOTE_FORM_SELECT: FIELD_WIDGET_REMOTE_FORM_SELECT,
            MODEL_SELECT_MULTIPLE: FIELD_WIDGET_MODEL_SELECT_MULTIPLE,
            HEADING: FIELD_WIDGET_MODEL_HEADING,
        };
        this.filteredChoices = of([]);
        this.filteredRemoteFormChoices = of([]);
        this.filteredMultiSelectChoices = of([]);
        this.multiChoiceFormControl = new FormControl();
        this.remoteFormChoices = [];
        this.showCommmentBox = false;
        this.accordionAnswers = [];
        this.questionImageSize = false;
    }
    Object.defineProperty(FormFieldComponent.prototype, "showTimer", {
        /**
         * Tells whether timer component should be shown
         */
        get: function () {
            return this.field.field_name === 'duration' && isHandHygieneAudit(this.auditForm);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "compliance", {
        /**
         * Compliance based on currently entered answer
         * or null of not answered, or field is not used for compliance.
         * @return {number | null}
         */
        get: function () {
            if (this.complianceCalculator === null || this.formField.value === undefined || this.formField.value === null) {
                return null;
            }
            else {
                try {
                    return this.complianceCalculator.calculateFieldCompliance(this.field, this.formField.value);
                }
                catch (e) {
                    if (e instanceof Incompliant)
                        return COMPLIANCE_INCOMPLIANT;
                    else {
                        this.logger.error(e);
                        return null;
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "isVisible", {
        /**
         * Tells whether field should be shown
         *
         * All fields are shown by default, but some fields may be conditional
         * and depend on another field to have a certain value
         */
        get: function () {
            var config = this.auditForm.config;
            if (!this.isRequired && config && this.settings && !config.show_non_required_fields && !this.settings.showOptionalFields)
                return false;
            var visible = isFieldVisible(this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform);
            // if the field was hidden but still has value, clear the value
            if (!visible) {
                if (this.formField.value && !this.canAutoCycle) {
                    this.resetField();
                }
                // use conditional default value if exists
                this.setDefaultValue();
            }
            return visible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "isRequired", {
        get: function () {
            return isFieldRequired(this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "autoCycleChoices", {
        /**
         * Returns choices that haven't been selected yet in previous observation inside session
         */
        get: function () {
            var _this = this;
            var choices = getFieldChoices(this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform);
            if (this.formField.value) {
                // Once selected don't allow user to update the value
                return choices.filter(function (_a) {
                    var value = _a.value;
                    return value === _this.formField.value;
                });
            }
            var existingFieldValues = this.audit.auditSession.observations.map(function (obs, index) {
                var value = _this.auditService.getFieldValue(_this.field.field_name, _this.audit.auditSession.observations[index], _this.auditFormSchema);
                if (value instanceof Array)
                    value = value[value.length - 1];
                return value;
            });
            return choices.filter(function (_a) {
                var value = _a.value;
                return !existingFieldValues.includes(value);
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "choices", {
        get: function () {
            if (this.remoteFormChoices.length > 0) {
                return this.remoteFormChoices;
            }
            else if (this.canAutoCycle) {
                return this.autoCycleChoices;
            }
            return getFieldChoices(this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "fieldClass", {
        get: function () {
            if (this.field.widget === this.widgetTypes.HEADING)
                return 'none';
            else
                return isFieldRequired(this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform) ? 'required' : 'optional';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "showClearButton", {
        get: function () {
            if (this.field.widget === this.widgetTypes.HEADING)
                return false;
            else
                return !this.field.required && this.form.controls[this.field.field_name].value !== null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "formField", {
        get: function () {
            return this.form.get(this.field.field_name);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "useAutocompleteWidget", {
        /**
         * Whether an autocomplete text field should be used for this select field.
         * Decides whether widget for this field should be overridden with an autocomplete widget
         * based on how many choices it has and whether its a multiselect.
         */
        get: function () {
            if (this.choices.length < 38)
                return false;
            return this.field.widget === FIELD_WIDGET_SELECT || this.field.widget === FIELD_WIDGET_MODEL_SELECT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "useAutocompleteMultiSelectWidget", {
        get: function () {
            if (this.choices.length < 38)
                return false;
            return this.field.widget === FIELD_WIDGET_SELECTMULTIPLE || this.field.widget === FIELD_WIDGET_MODEL_SELECT_MULTIPLE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "useIntegerWidget", {
        get: function () {
            return this.field.widget === this.widgetTypes.NUMBER_INPUT;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Updated "Required" property of the field
     * and re-runs validation
     */
    FormFieldComponent.prototype.updateValidators = function () {
        // redundant call to updateValueAndValidity to address issue #22632
        this.formField.updateValueAndValidity({ emitEvent: false });
        var validators = createFormFieldValidators(this.field.validators || [], false, this.apiService);
        if (this.isRequired)
            validators.push(Validators.required);
        if (this.useIntegerWidget)
            validators.push(integerValidator());
        if (this.useAutocompleteWidget)
            validators.push(choiceValidator(this.choices));
        this.formField.setValidators(validators);
        // @ts-ignore
        this.formField.setAsyncValidators(createFormFieldValidators(this.field.validators || [], true, this.apiService));
        this.formField.updateValueAndValidity({ emitEvent: false });
    };
    FormFieldComponent.prototype.addIssue = function () {
        var _this = this;
        var subscription = this.qipService.getCommonIssues(this.auditForm, this.subForm).pipe(mergeMap(function (commonIssues) {
            if (commonIssues.length === 0) {
                // No common issues, display Add Issue Form
                return _this.dialog.open(CreateIssueDialogComponent, {
                    height: '90%',
                    data: new IssueDialogArguments(_this.observation.issues, _this.audit, undefined, null, undefined, true, 'qip.issues', false, _this.issue_label, _this.issue_label_plural),
                }).afterClosed().pipe(tap(function (issue) {
                    if (issue instanceof Issue) {
                        issue.field_name = _this.field.field_name;
                        _this.observation.issues.push(issue);
                    }
                    else
                        throw new Error("issue is not of type Issue. " + issue);
                }));
            }
            else {
                // Show list of common issues
                return _this.dialog.open(IssuesDialogComponent, {
                    height: '90%',
                    data: new IssueDialogArguments(_this.observation.issues, _this.audit, commonIssues, _this.field.field_name, undefined, true, 'qip.issues', false, _this.issue_label, _this.issue_label_plural),
                }).afterClosed();
            }
        })).subscribe();
        this.subscriptions.push(subscription);
    };
    Object.defineProperty(FormFieldComponent.prototype, "isRemoteDefaultValueEnabled", {
        get: function () {
            return this.field.config && this.field.config.default_value && this.field.config.default_value.enabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "remoteDefaultValueMatchFields", {
        get: function () {
            if (!this.isRemoteDefaultValueEnabled || !this.field.config || !this.field.config.default_value)
                return [];
            return this.field.config.default_value.match_fields || [];
        },
        enumerable: true,
        configurable: true
    });
    FormFieldComponent.prototype.getMatchFieldValue = function (field_name) {
        var value = [];
        if (this.form.controls[field_name].value === undefined) {
            // if this subform does not contain this field, look into other subforms within this observation
            if (this.isAccordionSubform) {
                this.accordionAnswers.filter(function (fieldAnswer) { return fieldAnswer.field_name === field_name; })
                    .forEach(function (fieldAnswer) {
                    if (Array.isArray(fieldAnswer.answer)) {
                        value.push.apply(value, fieldAnswer.answer);
                    }
                    else {
                        value.push(fieldAnswer.answer);
                    }
                });
            }
            else {
                var observationValues = getSubObservationValuesFromAudit(this.audit, this.auditFormSchema, field_name);
                value.push.apply(value, observationValues);
            }
        }
        else {
            // Use value from the same subobservations
            var v = this.form.controls[field_name].value;
            if (Array.isArray(v))
                value.push.apply(value, v);
            else
                value.push(v);
        }
        return value;
    };
    Object.defineProperty(FormFieldComponent.prototype, "remoteDefaultValueMatchFieldsData", {
        get: function () {
            var _this = this;
            var matchFieldsData = {};
            this.remoteDefaultValueMatchFields.map(function (field_name) {
                var matchFieldValue = _this.getMatchFieldValue(field_name);
                if (!matchFieldValue || (Array.isArray(matchFieldValue) && matchFieldValue.filter(function (x) { return x !== null; }).length === 0))
                    return;
                matchFieldsData[field_name] = matchFieldValue;
            });
            return matchFieldsData;
        },
        enumerable: true,
        configurable: true
    });
    FormFieldComponent.prototype.updateRemoteDefaultValue = function (resetDefaultOnChange) {
        var _this = this;
        if (resetDefaultOnChange === void 0) { resetDefaultOnChange = false; }
        if (!this.isRemoteDefaultValueEnabled)
            return;
        if (!this.form.controls[this.field.field_name].pristine)
            return;
        var matchFieldsData = this.remoteDefaultValueMatchFieldsData;
        // If any match field value is null or empty don't fetch default
        if (Object.keys(this.remoteDefaultValueMatchFieldsData).length !== this.remoteDefaultValueMatchFields.length) {
            this.formField.setValue(null);
            return;
        }
        var subscription = this.apiService.fetchFieldDefaultValues(this.field.id, matchFieldsData).pipe(debounceTime(250), tap(function (values) {
            var currentValue = _this.getMatchFieldValue(_this.field.field_name);
            if (values.length > 0 && values[0] !== currentValue) {
                _this.formField.setValue(values[0], { emitEvent: false });
            }
            else if (resetDefaultOnChange) {
                _this.formField.setValue(null);
            }
            return values;
        })).subscribe();
        this.subscriptions.push(subscription);
    };
    FormFieldComponent.prototype.updateDefaultValue = function () {
        if (this.remoteDefaultValueMatchFields.length === 0)
            return;
        this.updateRemoteDefaultValue(true);
    };
    FormFieldComponent.prototype.updateConditionalValue = function () {
        var _this = this;
        var conditionalValue = getConditionalValue(this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform, this.setValuePlaceholders);
        if (conditionalValue !== undefined) {
            setTimeout(function () {
                _this.formField.setValue(conditionalValue, { emitEvent: false });
            });
        }
    };
    FormFieldComponent.prototype.setDefaultValue = function () {
        var conditionalDefaultValue = getDefaultConditionalValue(this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform, this.setValuePlaceholders);
        if (conditionalDefaultValue !== undefined) {
            this.formField.setValue(conditionalDefaultValue);
        }
        else {
            this.updateRemoteDefaultValue();
        }
    };
    FormFieldComponent.prototype.clearField = function () {
        this.setDefaultValue();
        this.updateValidators();
    };
    FormFieldComponent.prototype.resolveFieldImage = function (image) {
        if (image !== null && image !== undefined) {
            if (image[0] === '/')
                return this.apiService.buildUrl(image);
            else
                return of(this.domSanitizer.bypassSecurityTrustResourceUrl(image));
        }
        else
            return of('');
    };
    FormFieldComponent.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var accordionAnswersSubscription, fieldImage, settingsSubscription, autocompleteSubscription, formChanges, formChangesUpdateDefaultValue;
            var _this = this;
            return __generator(this, function (_a) {
                this.complianceCalculator = new ComplianceCalculator(this.auditFormSchema);
                this.isAccordionSubform = this.auditForm.config.form_layout === LayoutType.Accordion;
                if (this.isAccordionSubform) {
                    accordionAnswersSubscription = this.auditService.accordionAnswers$.subscribe(function (fieldAnswers) {
                        fieldAnswers.forEach(function (fieldAnswer) {
                            _this.field.conditions.forEach(function (condition) {
                                if (condition.field_name !== fieldAnswer.field_name)
                                    return;
                                if (condition.set_value &&
                                    evaluateConditionHasValue(condition, fieldAnswer.answer) &&
                                    _this.form.controls[_this.field.field_name].pristine) {
                                    _this.form.controls[_this.field.field_name].setValue(getSetValue(condition.set_value, _this.setValuePlaceholders));
                                }
                            });
                        });
                        _this.accordionAnswers = fieldAnswers;
                        _this.cdr.detectChanges();
                    });
                    this.subscriptions.push(accordionAnswersSubscription);
                    this.accordionAnswers = this.auditService.getCachedAccordionAnswers();
                    this.updateAccordionAnswersSubject(this.formField.value);
                }
                fieldImage = this.resolveFieldImage(getFieldImage(this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform)).pipe(tap(function (value) {
                    _this.fieldImage = value || _this.field.image_base64 || '';
                })).subscribe();
                this.subscriptions.push(fieldImage);
                /*
                * Set default value on component load when a condition with has_value as None exists
                * */
                if (this.form.pristine)
                    this.clearField();
                settingsSubscription = this.settingsService.readSettings().subscribe(function (settings) {
                    _this.settings = settings;
                    if (_this.canAutoCycle) {
                        var choice = _this.autoCycleChoices[0];
                        var fieldValue = choice !== null ? choice.value : null;
                        _this.form.controls[_this.field.field_name].setValue(fieldValue);
                    }
                    else if (_this.canAutoSelect(settings)) {
                        var numObservations = _this.audit.auditSession.observations.length;
                        if (numObservations > 0) {
                            var lastObservation = _this.audit.auditSession.observations[numObservations - 1];
                            var fieldValue = _this.auditService.getFieldValue(_this.field.field_name, lastObservation, _this.auditFormSchema);
                            if (fieldValue !== null) {
                                if (fieldValue instanceof Array)
                                    fieldValue = fieldValue[fieldValue.length - 1];
                                _this.form.controls[_this.field.field_name].setValue(fieldValue);
                            }
                        }
                    }
                });
                this.subscriptions.push(settingsSubscription);
                this.filteredChoices = this.formField.valueChanges.pipe(startWith(''), map(function (value) { return typeof value === 'number' ? value.toString() : value; }), tap(function (value) {
                    // Update form field value by label when choice is entered manually
                    var choice = _this.choices.find(function (c) { return c.label === value; });
                    if (choice) {
                        _this.formField.setValue(choice.label, {
                            emitEvent: false,
                        });
                    }
                }), map(function (value) { return _this.filterChoices(value); }));
                this.filteredRemoteFormChoices = this.formField.valueChanges.pipe(debounceTime(500), startWith(this.formField.value || ''), mergeMap(function (value) {
                    if (!_this.field || !value)
                        return of([]);
                    return _this.apiService.fetchRemoteFormModelFieldChoices(_this.field.id, value).pipe(tap(function (choices) {
                        var currentValue = _this.formField.value;
                        _this.remoteFormChoices = choices;
                        // Refresh field value to fetch choice label
                        if (currentValue && choices.map(function (x) { return x.label; }).includes(currentValue)) {
                            _this.formField.setValue(_this.formField.value, {
                                emitEvent: false,
                            });
                        }
                        return choices;
                    }));
                }));
                this.filteredMultiSelectChoices = this.multiChoiceFormControl.valueChanges.pipe(startWith(''), map(function (value) {
                    return _this.filterChoices(typeof value === 'string' ? value : '');
                }));
                this.limitModelFieldChoices();
                this.preselectModelFieldChoice();
                this.updateConditionalValue();
                this.updateDefaultValue();
                if (this.form.controls['comment-' + this.field.field_name].value) {
                    this.showCommmentBox = true;
                }
                autocompleteSubscription = this.formField.valueChanges.subscribe(function (value) {
                    if (_this.useAutocompleteWidget && value === '') {
                        _this.resetField();
                    }
                    _this.updateAccordionAnswersSubject(value);
                });
                formChanges = this.form.valueChanges.pipe(map(function () { return getFieldImage(_this.field, _this.form, _this.audit, _this.auditFormSchema, _this.accordionAnswers, _this.hardcodedData, _this.isAccordionSubform); }), mergeMap(function (image) { return _this.resolveFieldImage(image); }), tap(function (image) {
                    _this.fieldImage = image;
                    _this.cdr.detectChanges();
                }), tap(function () { return _this.updateConditionalValue(); }), tap(function () { return _this.updateValidators(); })).subscribe();
                this.subscriptions.push(formChanges);
                if (this.remoteDefaultValueMatchFields.length > 0) {
                    formChangesUpdateDefaultValue = (merge.apply(void 0, this.remoteDefaultValueMatchFields.map(function (f) { return _this.form.get(f).valueChanges; })).pipe(debounceTime(250), tap(function () { return _this.updateDefaultValue(); })).subscribe());
                    this.subscriptions.push(formChangesUpdateDefaultValue);
                }
                this.subscriptions.push(autocompleteSubscription);
                this.issue_label = this.auditService.getIssueLabel(this.auditForm);
                this.issue_label_plural = this.auditService.getIssueLabel(this.auditForm, true);
                if (this.editing)
                    this.formField.markAsTouched();
                return [2 /*return*/];
            });
        });
    };
    FormFieldComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.init()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error(this.field.field_name, error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FormFieldComponent.prototype.radioChangeHandler = function ($event) {
        this.updateAccordionAnswersSubject($event.value);
    };
    FormFieldComponent.prototype.updateAccordionAnswersSubject = function (value) {
        if (this.isAccordionSubform) {
            this.auditService.addAccordionAnswer({
                field_name: this.field.field_name,
                accordion_index: this.accordionIndex,
                answer: value,
            });
        }
    };
    FormFieldComponent.prototype.canAutoSelect = function (settings) {
        if (this.auditForm.observation_model === CUSTOM_OBSERVATION_NAME)
            return this.field.autoselect;
        return this.auditForm.config.autoselect && this.field.widget === this.widgetTypes.SELECT;
    };
    Object.defineProperty(FormFieldComponent.prototype, "canAutoCycle", {
        get: function () {
            return (this.auditForm.observation_model === CUSTOM_OBSERVATION_NAME &&
                this.auditForm.config.auto_cycle_field === this.field.field_name);
        },
        enumerable: true,
        configurable: true
    });
    FormFieldComponent.prototype.filterChoices = function (value) {
        if (!this.field)
            return [];
        var choices = this.choices;
        if (value == null || value.length === 0)
            return choices;
        else {
            value = value.toLowerCase();
            return choices.filter(function (choice) { return choice.label.toLowerCase().indexOf(value) !== -1; });
        }
    };
    FormFieldComponent.prototype.limitModelFieldChoices = function () {
        var _this = this;
        if (!this.field)
            return;
        var choices = this.field.choices;
        if (this.field.widget === this.widgetTypes.MODEL_SELECT && this.field.model) {
            if (this.field.model.model === INSTITUTION) {
                this.field.choices = choices.filter(function (choice) { return _this.institutionService.getInstitutionOrNull(parseInt(choice.value, 10)); });
            }
            else if (this.field.model.model === ROOM && this.audit.wardId) {
                this.institutionService.getWard(this.audit.wardId).subscribe(function (ward) {
                    var roomIds = ward.rooms.filter(function (room) { return room.hasOwnProperty('id') && Boolean(room.id); })
                        .map(function (room) { return room.id; });
                    _this.field.choices = choices.filter(function (choice) { return roomIds.indexOf(parseInt(choice.value, 10)) >= 0; });
                });
            }
        }
        this.field.choices = choices;
    };
    FormFieldComponent.prototype.preselectModelFieldChoice = function () {
        var _this = this;
        if (!this.field)
            return;
        if (this.field.widget === this.widgetTypes.MODEL_SELECT && this.field.model) {
            if (this.field.model.model === INSTITUTION) {
                if (!this.form.controls[this.field.field_name].value) {
                    this.institutionService.getWardData(this.audit.wardId).subscribe(function (wardData) {
                        _this.form.controls[_this.field.field_name].setValue(wardData[0].id);
                    });
                }
            }
        }
    };
    FormFieldComponent.prototype.displayChoiceGetter = function () {
        var _this = this;
        return function (selectedValue) { return _this.labelFromValue(selectedValue); };
    };
    FormFieldComponent.prototype.labelFromValue = function (value) {
        if (value === null)
            return '';
        var result = this.choices.find(function (c) { return c.value === value; });
        if (result)
            return result.label;
        else
            return '';
    };
    FormFieldComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        this.subscriptions = [];
    };
    Object.defineProperty(FormFieldComponent.prototype, "errorMessages", {
        get: function () {
            var fieldErrors = this.formField.errors || {};
            return createErrorMessages(this.field.validators || [], fieldErrors, this.translateService);
        },
        enumerable: true,
        configurable: true
    });
    FormFieldComponent.prototype.resetField = function () {
        this.form.controls[this.field.field_name].reset();
    };
    FormFieldComponent.prototype.toggleCommentBox = function () {
        if (this.showCommmentBox) {
            this.form.controls['comment-' + this.field.field_name].reset();
        }
        this.showCommmentBox = !this.showCommmentBox;
    };
    FormFieldComponent.prototype.multiChoicesSelected = function () {
        return this.form.controls[this.field.field_name].value;
    };
    FormFieldComponent.prototype.multiChoiceSelected = function (event) {
        var value = this.labelToValue(event.option.viewValue);
        var selected = this.form.controls[this.field.field_name].value;
        selected = selected ? selected : [];
        var index = selected.indexOf(value);
        if (index >= 0) {
            this.form.controls[this.field.field_name].value.splice(index, 1);
        }
        else {
            this.form.controls[this.field.field_name].setValue(selected.concat([value]));
            this.choiceInput.nativeElement.value = '';
            this.multiChoiceFormControl.setValue(null);
        }
    };
    FormFieldComponent.prototype.multiChoiceIsSelected = function (value) {
        var selected = this.form.controls[this.field.field_name].value;
        if (selected) {
            var index = this.form.controls[this.field.field_name].value.indexOf(value);
            return index >= 0;
        }
        return false;
    };
    FormFieldComponent.prototype.removeMultiChoice = function (value) {
        var index = this.form.controls[this.field.field_name].value.indexOf(value);
        if (index >= 0) {
            this.form.controls[this.field.field_name].value.splice(index, 1);
        }
    };
    FormFieldComponent.prototype.labelToValue = function (label) {
        var choice = this.choices.find(function (c) { return c.label === label; });
        return choice ? choice.value : '';
    };
    FormFieldComponent.prototype.toggleQuestionImage = function () {
        this.questionImageSize = !this.questionImageSize;
    };
    return FormFieldComponent;
}());
export { FormFieldComponent };
