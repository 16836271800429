<div class="meg-table audit-table" *ngIf="showYesToAllButton || showNaToAllButton">
  <div class="meg-row">
      <button *ngIf="showYesToAllButton" class="yes-btn" mat-button (click)="answerYesToAll()">
        {{'audit-form.yes-to-all' | translate}}
      </button>
      <button *ngIf="showNaToAllButton" class="na-btn" mat-button (click)="answerNaToAll()">
        {{'audit-form.na-to-all' | translate}}
      </button>
  </div>
</div>

<mat-error *ngFor="let error of fieldErrors?.non_field_errors">{{error}}</mat-error>
<mat-error *ngFor="let error of fieldErrors?.ward">{{error}}</mat-error>
<mat-error *ngFor="let error of fieldErrors?.date">{{error}}</mat-error>

<form [formGroup]="form" *ngIf="fields?.length">

  <ng-container *ngFor="let group of groupedFields">
    <ng-template #renderFields>
      <div *ngFor="let field of group.fields">
        <meg-wrapped-field
          [field]="field"
          [fieldErrors]="fieldErrors"
          [form]="form"
          [observation]="observation"
          [audit]="audit"
          [auditForm]="auditForm"
          [auditFormSchema]="auditFormSchema"
          [subForm]="subFormSchema"
          [accordionIndex]="accordionIndex"
          [setValuePlaceholders]="setValuePlaceholders"
          [hardcodedData]="hardcodedData"
          [editing]="observationId !== null"
        ></meg-wrapped-field>

        <div class="observation-related" *ngFor="let sourceData of relatedData">
          <div  *ngIf="sourceData.source.matching_fields[0] === field.field_name">
            <div class="observation-group" *ngFor="let observation of sourceData.data">
              <h1>{{ sourceData.source.label }}</h1>
              <h2>{{observation.observation_name}}</h2>
              <div class="observation-field" *ngFor="let fieldData of observation.fields">
                <strong class="observation-title">{{fieldData.label}}:</strong> {{fieldData.value}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-template>

    <ng-container *ngIf="group.heading === null || isAccordionHeadingVisible(group.heading)">
      <mat-expansion-panel *ngIf="group.heading else renderFields" class="meg-accordion" [ngClass]="{'required': group.required}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-2-lines">{{group.heading.label}}</div>
          </mat-panel-title>
          <mat-icon class="error_icon" *ngIf="getAccordionHeadingError(group)">error_outline</mat-icon>
        </mat-expansion-panel-header>
        <ng-template [ngTemplateOutlet]="renderFields"></ng-template>
      </mat-expansion-panel>
    </ng-container>
  </ng-container>
</form>

<mat-card class="accordion-card" *ngFor="let item of accordionItems let i = index" [ngStyle]="{'animation-delay': (0.1 * i) + 's'}">
  <mat-expansion-panel class="mat-observation-accordion">
    <mat-expansion-panel-header [collapsedHeight]="'26px'" [expandedHeight]="'26px'">
      <mat-panel-title>
        <div class="ellipsis-2-lines">{{item.subform.display_name}}</div>
        <mat-panel-description>{{item.description}}</mat-panel-description>
        <div class="observation-error" *ngIf="getSubObservationErrorMessages(item, i)">
          <mat-error *ngFor="let msg of getSubObservationErrorMessages(item, i)">
            {{msg}}
          </mat-error>
        </div>
      </mat-panel-title>
      <mat-icon class="error_icon" *ngIf="getSubObservationErrorMessages(item, i)">error_outline</mat-icon>
    </mat-expansion-panel-header>
    <meg-observation
      class="accordion-card-observation"
      [observation]="item.subObservation"
      [schema]="item.subform"
      [auditForm]="auditForm"
      [audit]="audit"
      [fieldErrors]="item.fieldErrors"
      [auditFormSchema]="auditFormSchema"
      [isAccordionSubObservation]="true"
      [accordionIndex]="i"
    ></meg-observation>
    <mat-action-row>
      <button mat-raised-button class="btn-left" color="warn" (click)="clearSubform(item.subObservation)"><mat-icon>clear</mat-icon> {{'clear' | translate}}</button>
      <button *ngIf="hasMultipleForms(item)" color="warn" mat-raised-button (click)="deleteSubObservation(item, i)"><mat-icon>delete</mat-icon> {{'delete' | translate}}</button>
      <button *ngIf="item.showAddAnotherBtn" mat-raised-button color="primary" (click)="addSubObservation(item.subform)"><mat-icon>add</mat-icon> {{'add-another' | translate}}</button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-card>

<div class="action-group sticky" *ngIf="!this.isAccordionSubObservation && auditForm?.config.show_save_button !== false">
  <button class="button-order-2" *ngIf="!this.isAccordionSubObservation && auditForm?.config.show_save_button !== false" mat-raised-button class="save-progress-btn" (click)="onProgressSave(); false;" color="primary"><mat-icon>save</mat-icon> {{ 'save' | translate }}</button>
</div>
<div [ngClass]="!this.isAccordionSubObservation && auditForm?.config.show_save_button !== false ? 'action-group save-btn-right' : 'action-group'">
  <button class="button-order-2" mat-raised-button *ngIf="!this.isAccordionSubObservation && observationId === null" (click)="onClearClicked(); false;" color="warn"><mat-icon>clear</mat-icon> {{ 'clear' | translate }}</button>
  <button class="button-order-2" mat-raised-button *ngIf="!this.isAccordionSubObservation && observationId !== null" (click)="onDeleteClicked(); false;" color="warn"><mat-icon>delete</mat-icon> {{ 'delete' | translate }}</button>
  <meg-issues-widget class="meg-issues-widget" *ngIf="this.showQip && this.isAccordionSubObservation || this.showQip && this.accordionItems.length === 0" [observation]="observation" [subForm]="schema" [audit]="audit" [errors]="fieldErrors?.issues" ></meg-issues-widget>
  <button mat-raised-button class="float-right right-btn button-back" *ngIf="!isAccordionSubObservation" [disabled]="!observation" (click)="onBackClicked()"><mat-icon>arrow_backward</mat-icon> {{ 'back' | translate }}</button>
  <button class="button-order-2" mat-raised-button *ngIf="!this.isAccordionSubObservation" color="accent" [disabled]="!observation || isNextLoading" (click)="onNextClicked()">{{ 'next' | translate }}<mat-icon *ngIf="!isNextLoading">arrow_forward</mat-icon><mat-icon *ngIf="isNextLoading"><mat-spinner style="top: 0px" diameter="20"></mat-spinner></mat-icon></button>
</div>
