/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wrapped-field.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../field/field.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../field/field.component";
import * as i5 from "../../audit-form.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../settings/settings.service";
import * as i8 from "../../qip/qip.service";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@ngx-translate/core";
import * as i11 from "ngx-logger";
import * as i12 from "../audit.service";
import * as i13 from "../../institution.service";
import * as i14 from "../../api/api.service";
import * as i15 from "./wrapped-field.component";
var styles_WrappedFieldComponent = [i0.styles];
var RenderType_WrappedFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WrappedFieldComponent, data: {} });
export { RenderType_WrappedFieldComponent as RenderType_WrappedFieldComponent };
function View_WrappedFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "meg-field", [], null, null, null, i2.View_FormFieldComponent_0, i2.RenderType_FormFieldComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(2, 245760, [[1, 4]], 0, i4.FormFieldComponent, [i5.AuditFormService, i6.MatDialog, i7.SettingsService, i8.QipService, i9.DomSanitizer, i10.TranslateService, i11.NGXLogger, i12.AuditService, i13.InstitutionService, i14.ApiService, i1.ChangeDetectorRef], { field: [0, "field"], form: [1, "form"], errors: [2, "errors"], observation: [3, "observation"], audit: [4, "audit"], auditForm: [5, "auditForm"], auditFormSchema: [6, "auditFormSchema"], subForm: [7, "subForm"], accordionIndex: [8, "accordionIndex"], editing: [9, "editing"], setValuePlaceholders: [10, "setValuePlaceholders"], hardcodedData: [11, "hardcodedData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.field.required ? "field-required" : "field-optional"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.field; var currVal_2 = _co.form; var currVal_3 = ((_co.fieldErrors === null) ? null : _co.fieldErrors[_co.field.field_name]); var currVal_4 = _co.observation; var currVal_5 = _co.audit; var currVal_6 = _co.auditForm; var currVal_7 = _co.auditFormSchema; var currVal_8 = _co.subForm; var currVal_9 = _co.accordionIndex; var currVal_10 = _co.editing; var currVal_11 = _co.setValuePlaceholders; var currVal_12 = _co.hardcodedData; _ck(_v, 2, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
function View_WrappedFieldComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "meg-field", [], null, null, null, i2.View_FormFieldComponent_0, i2.RenderType_FormFieldComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(2, 245760, [[1, 4]], 0, i4.FormFieldComponent, [i5.AuditFormService, i6.MatDialog, i7.SettingsService, i8.QipService, i9.DomSanitizer, i10.TranslateService, i11.NGXLogger, i12.AuditService, i13.InstitutionService, i14.ApiService, i1.ChangeDetectorRef], { field: [0, "field"], form: [1, "form"], errors: [2, "errors"], observation: [3, "observation"], audit: [4, "audit"], auditForm: [5, "auditForm"], auditFormSchema: [6, "auditFormSchema"], subForm: [7, "subForm"], accordionIndex: [8, "accordionIndex"], editing: [9, "editing"], setValuePlaceholders: [10, "setValuePlaceholders"], hardcodedData: [11, "hardcodedData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.required ? ("field-required columns cols-" + _co.field.layout_columns) : ("field-optional columns cols-" + _co.field.layout_columns)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = _co.form; var currVal_3 = ((_co.fieldErrors === null) ? null : _co.fieldErrors[_v.context.$implicit.field_name]); var currVal_4 = _co.observation; var currVal_5 = _co.audit; var currVal_6 = _co.auditForm; var currVal_7 = _co.auditFormSchema; var currVal_8 = _co.subForm; var currVal_9 = _co.accordionIndex; var currVal_10 = _co.editing; var currVal_11 = _co.setValuePlaceholders; var currVal_12 = _co.hardcodedData; _ck(_v, 2, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
function View_WrappedFieldComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "meg-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WrappedFieldComponent_3)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.fields; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WrappedFieldComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { formFieldComponents: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WrappedFieldComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WrappedFieldComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isField; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isField; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_WrappedFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-wrapped-field", [], null, null, null, View_WrappedFieldComponent_0, RenderType_WrappedFieldComponent)), i1.ɵdid(1, 114688, null, 0, i15.WrappedFieldComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WrappedFieldComponentNgFactory = i1.ɵccf("meg-wrapped-field", i15.WrappedFieldComponent, View_WrappedFieldComponent_Host_0, { field: "field", form: "form", errors: "errors", observation: "observation", audit: "audit", auditForm: "auditForm", auditFormSchema: "auditFormSchema", subForm: "subForm", fieldErrors: "fieldErrors", accordionIndex: "accordionIndex", editing: "editing", setValuePlaceholders: "setValuePlaceholders", hardcodedData: "hardcodedData" }, {}, []);
export { WrappedFieldComponentNgFactory as WrappedFieldComponentNgFactory };
