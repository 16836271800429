import { ApiService } from '../../api/api.service';
import { of, from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { QipService } from '../qip.service';
import { InstitutionService } from '../../institution.service';
import { API_ISSUES } from '../../api/constants';
import { DB_KEY_QIP_FILTER_STATE, StorageService } from '../../storage.service';
import { IssueFilter } from './issue-filter/filter';
import { NGXLogger } from 'ngx-logger';
import { DownloadService } from '../../loading/download.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api/api.service";
import * as i2 from "../qip.service";
import * as i3 from "../../institution.service";
import * as i4 from "../../storage.service";
import * as i5 from "../../loading/download.service";
import * as i6 from "ngx-logger";
var IssueService = /** @class */ (function () {
    function IssueService(apiService, qipService, institutionService, storageService, downloadService, logger) {
        this.apiService = apiService;
        this.qipService = qipService;
        this.institutionService = institutionService;
        this.storageService = storageService;
        this.downloadService = downloadService;
        this.logger = logger;
        this.pageSize = 100;
    }
    IssueService.prototype.getIssueHandlerName = function (handlerId, auditFormId, placeholder) {
        if (placeholder === void 0) { placeholder = '-'; }
        if (handlerId === null || auditFormId == null)
            return of(placeholder);
        else
            return this.qipService.getIssueHandlers(null, auditFormId).pipe(map(function (handlers) {
                return handlers.find(function (handler) { return handler.id === handlerId; });
            }), map(function (handler) { return handler === undefined ? placeholder : handler.name; }));
    };
    IssueService.prototype.getIssues = function (page, issueHandlerId, auditFormId, wardId, statuses) {
        var _this = this;
        if (page === void 0) { page = 0; }
        return this.apiService.fetchIssues(page, this.pageSize, statuses, issueHandlerId, auditFormId, wardId).pipe(mergeMap(function (response) {
            var formIds = Array.from(new Set(response.results
                .map(function (x) { return x.audit_form; })
                .filter(function (x) { return x !== undefined; })));
            // attempt to download form issue handlers if not already downloaded
            return from(_this.downloadService.downloadFormIssueHandlers(formIds, true)).pipe(map(function () { return response; }));
        }));
    };
    IssueService.prototype.getIssue = function (id) {
        return this.apiService.fetchIssueDetail(id);
    };
    IssueService.prototype.getIssueStatusData = function (issue) {
        return issue.status || null;
    };
    IssueService.prototype.getStatuses = function (issueId) {
        return this.apiService.fetchStatuses(issueId);
    };
    /**
     * Creates a location string based off room.
     * @param forRoom input room, can be null
     * @param placeholder string returned if room is not found
     * @returns string representing location (e.g. "Room, ward"), or "-" if location isn't set or room isn't known
     */
    IssueService.prototype.getLocationLabel = function (forRoom, placeholder) {
        var _this = this;
        if (placeholder === void 0) { placeholder = '-'; }
        var noLocation = of(placeholder);
        if (forRoom === null || forRoom.id === undefined)
            return noLocation;
        return this.institutionService.getRoomData(forRoom.id).pipe(map(function (location) {
            var institution = location[0], department = location[1], ward = location[2], room = location[3];
            return room.name + ", " + ward.name;
        }), catchError(function (e) {
            _this.logger.warn('Cannot find room', forRoom);
            return noLocation;
        }));
    };
    /**
     * Saves updated Issue object to the back-end
     * @param issue
     */
    IssueService.prototype.saveIssue = function (issue) {
        var url = "" + API_ISSUES + issue.id + "/";
        return this.apiService.patch(url, {
            qipstatus: issue.qipstatus,
            handler: issue.handler,
            comment: issue.comment,
            actiontaken: issue.actiontaken,
        });
    };
    /**
     * Save filter options selected by user
     */
    IssueService.prototype.saveFilters = function (filter) {
        return this.storageService.setItem(DB_KEY_QIP_FILTER_STATE, filter);
    };
    /**
     * Load last filter options selected by user
     */
    IssueService.prototype.loadFilters = function () {
        return this.storageService.getItemOrDefault(DB_KEY_QIP_FILTER_STATE, new IssueFilter());
    };
    IssueService.ngInjectableDef = i0.defineInjectable({ factory: function IssueService_Factory() { return new IssueService(i0.inject(i1.ApiService), i0.inject(i2.QipService), i0.inject(i3.InstitutionService), i0.inject(i4.StorageService), i0.inject(i5.DownloadService), i0.inject(i6.NGXLogger)); }, token: IssueService, providedIn: "root" });
    return IssueService;
}());
export { IssueService };
