var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Values for `Field.compliance_calculation` field
import { Model } from './model';
/** Field not used in compliance calculation @type {number} */
export var FIELD_COMPLIANCE_IGNORE = 0;
/** Field used to calculate average compliance @type {number} */
export var FIELD_COMPLIANCE_USE = 1;
/** Field essential in compliance calculation:
 * if field value is incompliant, the observation is considered incompliant regardless of answers to other questions @type {number} */
export var FIELD_COMPLIANCE_REQUIRE = 2;
// values for `Field.widget` field
export var FIELD_WIDGET_SELECT = 'django.forms.widgets.Select';
export var FIELD_WIDGET_RADIOSELECT = 'django.forms.widgets.RadioSelect';
export var FIELD_WIDGET_SELECTMULTIPLE = 'django.forms.widgets.SelectMultiple';
export var FIELD_WIDGET_TEXTINPUT = 'django.forms.widgets.TextInput';
export var FIELD_WIDGET_TEXTAREA = 'django.forms.widgets.Textarea';
export var FIELD_WIDGET_NUMBERINPUT = 'django.forms.widgets.NumberInput';
export var FIELD_WIDGET_STOPWATCH = 'meg_forms.audit_builder.form_widget.StopwatchInput';
export var FIELD_WIDGET_CHECKBOXINPUT = 'django.forms.widgets.CheckboxInput';
export var FIELD_WIDGET_DATEINPUT = 'django.forms.widgets.DateInput';
export var FIELD_WIDGET_TIMEINPUT = 'django.forms.widgets.TimeInput';
export var FIELD_WIDGET_DATETIMEINPUT = 'django.forms.widgets.DateTimeInput';
export var FIELD_WIDGET_FLOATINPUT = 'meg_forms.audit_builder.form_widget.FloatInput';
export var FIELD_WIDGET_SHORTDATEINPUT = 'audit_builder.widgets.ShortDateWidget';
export var FIELD_WIDGET_DOBINPUT = 'audit_builder.widgets.DOBDateWidget';
export var FIELD_WIDGET_FILEINPUT = 'django.forms.widgets.ClearableFileInput';
export var FIELD_WIDGET_MULTIFILEINPUT = 'audit_builder.widgets.MultiFileClearableInput';
export var FIELD_WIDGET_AUDIO = 'audit_builder.widgets.AudioWidget';
export var FIELD_WIDGET_MODEL_SELECT = 'audit_builder.widgets.ModelSelect';
export var FIELD_WIDGET_REMOTE_FORM_SELECT = 'audit_builder.widgets.RemoteFormSelect';
export var FIELD_WIDGET_MODEL_SELECT_MULTIPLE = 'audit_builder.widgets.ModelSelectMultiple';
export var FIELD_WIDGET_MODEL_HEADING = 'audit_builder.widgets.HeadingWidget';
export var FIELD_WIDGET_ACCORDION = 'audit_builder.widgets.AccordionHeadingWidget';
// values for `Field.model.model`
export var INSTITUTION = 'institution';
export var ROOM = 'room';
export var ConditionSearch;
(function (ConditionSearch) {
    ConditionSearch["ANY"] = "*";
    ConditionSearch["EXACT"] = "exact_match";
    ConditionSearch["CONTAINS"] = "contains";
})(ConditionSearch || (ConditionSearch = {}));
var Choice = /** @class */ (function () {
    function Choice() {
    }
    return Choice;
}());
export { Choice };
/** Field/Answer combination used to evaluate complex conditional logic */
var ConditionFieldAnswer = /** @class */ (function () {
    function ConditionFieldAnswer() {
    }
    return ConditionFieldAnswer;
}());
export { ConditionFieldAnswer };
/** Allows multiple fields to be compared to evaluate complex conditional logic */
var NestedCondition = /** @class */ (function () {
    function NestedCondition() {
    }
    return NestedCondition;
}());
export { NestedCondition };
/** Defines condition on a field to trigger action in another field */
var Condition = /** @class */ (function () {
    function Condition() {
    }
    return Condition;
}());
export { Condition };
var FieldValidator = /** @class */ (function () {
    function FieldValidator() {
    }
    return FieldValidator;
}());
export { FieldValidator };
var FieldConfig = /** @class */ (function () {
    function FieldConfig() {
    }
    return FieldConfig;
}());
export { FieldConfig };
var Field = /** @class */ (function () {
    function Field() {
    }
    Field.prototype.toString = function () {
        return this.label;
    };
    return Field;
}());
export { Field };
/** A group of fields to be nested
 * within a question group, and displayed inline in UI. */
var InlineFieldGroup = /** @class */ (function () {
    function InlineFieldGroup() {
        this.fields = [];
    }
    Object.defineProperty(InlineFieldGroup.prototype, "required", {
        /**
         * Returns true if group contains any required fields
         */
        get: function () {
            return this.fields.find(function (field) { return field.required; }) !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    return InlineFieldGroup;
}());
export { InlineFieldGroup };
var SubFormSchema = /** @class */ (function () {
    function SubFormSchema() {
    }
    SubFormSchema.prototype.toString = function () {
        return this.display_name;
    };
    return SubFormSchema;
}());
export { SubFormSchema };
var FieldGroup = /** @class */ (function () {
    function FieldGroup() {
    }
    FieldGroup.prototype.toString = function () {
        return this.layout_columns;
    };
    return FieldGroup;
}());
export { FieldGroup };
var AuditFormSchema = /** @class */ (function (_super) {
    __extends(AuditFormSchema, _super);
    function AuditFormSchema() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AuditFormSchema;
}(Model));
export { AuditFormSchema };
var ModelFieldModelSchema = /** @class */ (function () {
    function ModelFieldModelSchema() {
    }
    return ModelFieldModelSchema;
}());
export { ModelFieldModelSchema };
/** Used to record answers given to accordion subform
 * For use in conditional logic. */
var AccordionFieldAnswer = /** @class */ (function (_super) {
    __extends(AccordionFieldAnswer, _super);
    function AccordionFieldAnswer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AccordionFieldAnswer;
}(ConditionFieldAnswer));
export { AccordionFieldAnswer };
