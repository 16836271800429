var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { IssueService } from '../issue.service';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, mergeMap, retry } from 'rxjs/operators';
import { tap } from 'rxjs/internal/operators/tap';
import { Location } from '@angular/common';
import { AccountService } from '../../../accounts.service';
import { QipService } from '../../qip.service';
import { of } from 'rxjs/internal/observable/of';
var IssueDetailComponent = /** @class */ (function (_super) {
    __extends(IssueDetailComponent, _super);
    function IssueDetailComponent(injector, issueService, route, location, accountService, qipService) {
        var _this = _super.call(this, injector) || this;
        _this.issueService = issueService;
        _this.route = route;
        _this.location = location;
        _this.accountService = accountService;
        _this.qipService = qipService;
        _this.status = null;
        _this.editable = false;
        _this.statusChoices = [];
        _this.issueHandlers = [];
        return _this;
    }
    IssueDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.route.params.pipe(map(function (params) {
            _this.id = +params.issue_id;
            if (isNaN(_this.id))
                throw Error("Invalid id " + params.issue_id);
            return _this.id;
        }), mergeMap(function (id) { return _this.issueService.getIssue(id); }), tap(function (issue) { return _this.issue = issue; }), tap(function (issue) { return _this.status = _this.issueService.getIssueStatusData(issue); }), tap(function (issue) { return _this.location$ = _this.issueService.getLocationLabel(issue.room); }), tap(function (issue) { return _this.issueHandlerName$ = _this.issueService.getIssueHandlerName(issue.handler, issue.audit_form || null); }), mergeMap(function (issue) { return _this.qipService.getIssueHandlers(issue.institution || null, issue.audit_form || null); }), tap(function (issueHandlers) { return _this.issueHandlers = issueHandlers; }), retry(3), catchError(function (error) {
            _this.logger.error('Error loading data', error);
            return _this.translateService.get('qip.issue-detail.error').pipe(tap(function (message) { return alert(message); }), tap(function () { return _this.goBack(); }));
        })).subscribe());
        this.subscriptions.push(this.accountService.hasPermission('qip.change_issue').pipe(tap(function (hasEditPermission) { return _this.editable = hasEditPermission; })).subscribe());
        if (this.id > 0) {
            this.subscriptions.push(this.issueService.getStatuses(this.id).pipe(tap(function (statuses) { return _this.statusChoices = statuses; })).subscribe());
        }
    };
    IssueDetailComponent.prototype.compareStatuses = function (status1, status2) {
        return status1.slug === status2.slug;
    };
    IssueDetailComponent.prototype.goBack = function () {
        this.location.back();
    };
    IssueDetailComponent.prototype.saveClicked = function () {
        var _this = this;
        this.issue.qipstatus = this.issue.status.slug;
        this.subscriptions.push(this.issueService.saveIssue(this.issue).pipe(tap(function () { return _this.snackbar.open('Issue Saved', undefined, {
            duration: 2000,
        }); }), tap(function () { return _this.location.back(); }), catchError(function (error) {
            _this.logger.error(error);
            return of(false);
        })).subscribe());
    };
    return IssueDetailComponent;
}(BaseComponent));
export { IssueDetailComponent };
