import { OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { AccountService } from '../accounts.service';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { API_APP_VERSION, API_AUDIT_FORMS, API_AUDIT_FORMS_v3, API_AUDIT_SESSION, API_AUDITORS, API_AUTHENTICATION_CHECK, API_AVATARS, API_BACKUP, API_BACKUP_MEDIA, API_BACKUP_UPLOAD_MEDIA, API_CALENDAR, API_CHANNELS, API_CHANNELS_SEND_P2P_MESSAGE, API_DASHBOARD, API_FEEDBACK, API_INFORMATION, API_INSTITUTIONS, API_ISSUE_PHOTOS, API_ISSUES, API_ISSUES_STATUSES_CHOICES, API_LANGUAGES, API_LOGIN, API_RELATED_FORM_DATA, API_SUBMISSIONS_IN_PROGRESS, API_TOKEN_LOGIN, API_UPCOMING_AUDITS, API_USERS, API_VALIDATORS, API_ANALYTICS, API_REMOTE_FORM_FIELD_CHOICES, AUDIT_FORM_CHECK_UPDATE, AUDIT_FORM_COMMON_ISSUES, AUDIT_FORM_SCHEMA, AUDIT_FORM_SUBMIT, CHANNEL_MARK_READ, CHANNEL_MESSAGES, CHANNEL_SEND_MESSAGE, CHANNEL_UNREAD_MESSAGES, MOBILE_FORGOT_PASSWORD, VALIDATORS_VALIDATE, API_CUSTOM_FIELD_DEFAULT_VALUE, API_CUSTOM_FIELDS } from './constants';
import { appData } from '../../../appData';
import { QIP_STATUS_IN_PROGRESS_SLUG, QIP_STATUS_OPEN_SLUG } from './models/issue';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { fromPromise } from 'rxjs/internal-compatibility';
import { isNullOrUndefined } from '../utils/misc';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AppConfigService } from '../app-config.service';
export var STATUS_OK = 200;
export var STATUS_NOT_MODIFIED = 304;
export var STATUS_REQUEST_ERROR = 400;
export var STATUS_UNAUTHORISED = 401;
export var STATUS_FORBIDDEN = 403;
export var STATUS_NOT_FOUND = 404;
export var STATUS_SERVER_ERROR = 500;
export var HEADER_IF_MODIFIED_SINCE = 'If-Modified-Since';
export var HEADER_LAST_MODIFIED = 'Last-Modified';
export var IOS = 'ios';
export var ANDROID = 'android';
export var WINDOWS = 'windows';
export var WEB = 'web';
var ApiService = /** @class */ (function () {
    function ApiService(httpClient, accounts, logger, router, appConfig) {
        this.httpClient = httpClient;
        this.accounts = accounts;
        this.logger = logger;
        this.router = router;
        this.appConfig = appConfig;
        /**
         * Network Status, while true means that network is available
         */
        this.networkStatus = false;
        this.networkStatus$ = Subscription.EMPTY;
        this.checkNetworkStatus();
    }
    ApiService.prototype.ngOnDestroy = function () {
        this.networkStatus$.unsubscribe();
    };
    ApiService.prototype.checkNetworkStatus = function () {
        var _this = this;
        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(map(function () { return navigator.onLine; }))
            .subscribe(function (status) {
            _this.networkStatus = status;
        });
    };
    ApiService.prototype.getDeviceType = function () {
        var cordova = window.cordova;
        if (cordova !== undefined) {
            return cordova.platformId.toLowerCase();
        }
        else {
            return WEB;
        }
    };
    Object.defineProperty(ApiService.prototype, "region", {
        get: function () {
            var _this = this;
            return this.accounts.getRegion().pipe(map(function (region) {
                if (isNullOrUndefined(region))
                    return _this.appConfig.getDefaultRegion();
                else
                    return region;
            }), catchError(function () { return of(_this.appConfig.getDefaultRegion()); }));
        },
        enumerable: true,
        configurable: true
    });
    /** Adds default headers to the request, including authentication header if user is logged in */
    ApiService.prototype.buildHeaders = function () {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'app-version': appData.app_version,
            'xVersion-code': appData.build_number.toString(),
            'Accept-Language': this.accounts.getLanguage() || navigator.language,
            'xPlatform': this.getDeviceType(),
            'Access-Control-Allow-Methods': '*',
        });
        if (this.accounts.isAuthenticated()) {
            headers = headers.set('Authorization', this.accounts.getAuthorizationHeader());
        }
        return headers;
    };
    /**
     * Pre-pend backend url to the relative url
     * @param path relative url
     * @param region override region for this single request
     */
    ApiService.prototype.buildUrl = function (path, region) {
        var observable = region === undefined ? this.region : of(region);
        return observable.pipe(map(function (reg) { return reg.domain; }), map(function (domain) {
            var scheme = domain.indexOf(':') === -1 ? 'https' : 'http';
            return scheme + "://" + domain + path;
        }));
        // use http scheme if domain has a port number
    };
    ApiService.prototype.login = function (credentials, region) {
        var _this = this;
        return this.buildUrl(API_LOGIN, region || this.appConfig.getDefaultRegion()).pipe(mergeMap(function (url) { return _this.httpClient.post(url, credentials, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.tokenLogin = function (token, region) {
        var _this = this;
        return this.buildUrl(API_TOKEN_LOGIN, region).pipe(mergeMap(function (url) { return _this.httpClient.post(url, {
            token: token,
        }, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.fetchAuditForms = function (limit, offset) {
        var _this = this;
        if (limit === void 0) { limit = 100; }
        if (offset === void 0) { offset = 0; }
        return this.buildUrl(API_AUDIT_FORMS_v3 + "?limit=" + limit + "&offset=" + offset).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }));
    };
    /**
     * Gets all audit forms by making multiple paginated requests and returning them in a single list
     */
    ApiService.prototype.fetchAllAuditForms = function (pageSize) {
        var _this = this;
        if (pageSize === void 0) { pageSize = 500; }
        // Fetch first page
        return this.fetchAuditForms(pageSize).pipe(mergeMap(function (response) {
            var forms = response.results.filter(function (form) { return !form.archive; });
            // fetch subsequent pages
            var requests = [];
            for (var offset = pageSize; offset < response.count; offset += pageSize) {
                _this.logger.debug("Creating request for offset " + offset + " of " + response.count);
                var pageRequest = _this.fetchAuditForms(pageSize, offset).pipe(tap(function (pageResponse) { return forms = forms.concat(pageResponse.results); }));
                requests.push(pageRequest);
            }
            if (requests.length === 0)
                return of(forms);
            return forkJoin(requests).pipe(map(function () { return forms; }));
        }));
    };
    ApiService.prototype.fetchChannels = function () {
        var _this = this;
        return this.buildUrl(API_CHANNELS).pipe(tap(function (url) { return _this.logger.debug('Fetching channels from url', url); }), mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.fetchChannel = function (channelId) {
        var _this = this;
        return this.buildUrl(API_CHANNELS + channelId).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.fetchStatuses = function (issueId) {
        var _this = this;
        return this.buildUrl(API_ISSUES_STATUSES_CHOICES + issueId).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }));
    };
    /**
     * Fetches messages from given feed url
     * @param messagesUrl
     */
    ApiService.prototype.fetchMessages = function (messagesUrl) {
        var _this = this;
        return this.buildUrl(messagesUrl).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.fetchChannelMessages = function (channelId) {
        return this.fetchMessages(API_CHANNELS + channelId + CHANNEL_MESSAGES);
    };
    ApiService.prototype.fetchChannelUnreadMessages = function (channelId) {
        return this.fetchMessages(API_CHANNELS + channelId + CHANNEL_UNREAD_MESSAGES);
    };
    /**
     * Marks user's read position at given point in the channel
     * @param channelId channel ID
     * @param date date object or string representing time
     */
    ApiService.prototype.markMessagesAsRead = function (channelId, date) {
        var _this = this;
        return this.buildUrl(API_CHANNELS + channelId + CHANNEL_MARK_READ).pipe(mergeMap(function (url) { return _this.httpClient.post(url, {
            last_read: date,
        }, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.sendChannelMessage = function (channelId, message) {
        var _this = this;
        return this.buildUrl(API_CHANNELS + channelId + CHANNEL_SEND_MESSAGE).pipe(mergeMap(function (url) { return _this.httpClient.post(url, { content: message }, {
            headers: _this.buildHeaders(),
        }); }));
    };
    /**
     * Sends message to individual user, returns channel object for conversation with that user.
     * @param userId recipient user
     * @param message message content
     */
    ApiService.prototype.sendP2pMessage = function (userId, message) {
        var _this = this;
        return this.buildUrl(API_CHANNELS_SEND_P2P_MESSAGE).pipe(mergeMap(function (url) { return _this.httpClient.post(url, {
            content: message,
            to: userId,
        }, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.fetchUsers = function (search) {
        var _this = this;
        return this.buildUrl(API_USERS).pipe(mergeMap(function (url) {
            if (search)
                url = url + "?search=" + search;
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.requestPasswordReset = function (username) {
        var _this = this;
        return this.buildUrl(MOBILE_FORGOT_PASSWORD).pipe(mergeMap(function (url) {
            var body = new HttpParams().set('username', username);
            return _this.httpClient.post(url, body.toString(), {
                headers: _this.buildHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
                responseType: 'text',
            });
        }));
    };
    ApiService.prototype.fetchInstitutions = function (limit) {
        var _this = this;
        if (limit === void 0) { limit = 10000; }
        return this.buildUrl(API_INSTITUTIONS + "?limit=" + limit).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.fetchInformations = function (limit, offset) {
        var _this = this;
        if (limit === void 0) { limit = 10000; }
        if (offset === void 0) { offset = 0; }
        return this.buildUrl(API_INFORMATION + "?limit=" + limit + "&offset=" + offset).pipe(mergeMap(function (url) {
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.fetchAuditFormIssueHandlers = function (auditFormId) {
        var _this = this;
        return this.buildUrl("" + API_AUDIT_FORMS_v3 + auditFormId + "/issue_handlers/").pipe(mergeMap(function (url) {
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.fetchAuditFormSchema = function (formId, lastModified) {
        var _this = this;
        return this.buildUrl("" + API_AUDIT_FORMS_v3 + formId + AUDIT_FORM_SCHEMA).pipe(mergeMap(function (url) {
            var headers = _this.buildHeaders();
            if (lastModified !== null)
                headers = headers.set(HEADER_IF_MODIFIED_SINCE, lastModified);
            return _this.httpClient.get(url, {
                headers: headers,
                observe: 'response',
            });
        }));
    };
    ApiService.prototype.fetchCommonIssues = function (formId, lastModified) {
        var _this = this;
        return this.buildUrl("" + API_AUDIT_FORMS + formId + AUDIT_FORM_COMMON_ISSUES).pipe(mergeMap(function (url) {
            var headers = _this.buildHeaders();
            if (lastModified !== null)
                headers = headers.set(HEADER_IF_MODIFIED_SINCE, lastModified);
            return _this.httpClient.get(url, {
                headers: headers,
                observe: 'response',
            });
        }));
    };
    ApiService.prototype.submitAudit = function (formId, auditData) {
        var _this = this;
        return this.buildUrl("" + API_AUDIT_FORMS + formId + AUDIT_FORM_SUBMIT).pipe(mergeMap(function (url) {
            if (auditData.end_time == null) {
                auditData.end_time = new Date();
            }
            if (auditData.id === undefined) {
                return _this.httpClient.post(url, auditData, {
                    headers: _this.buildHeaders(),
                });
            }
            else {
                return _this.httpClient.put(url, auditData, {
                    headers: _this.buildHeaders(),
                });
            }
        }));
    };
    ApiService.prototype.fetchDashboards = function () {
        var _this = this;
        return this.buildUrl(API_DASHBOARD).pipe(mergeMap(function (url) {
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.uploadPhoto = function (photo) {
        var _this = this;
        return this.buildUrl(API_ISSUE_PHOTOS).pipe(mergeMap(function (url) { return _this.httpClient.post(url, photo, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.checkAppVersion = function (appId) {
        var _this = this;
        return this.buildUrl(API_APP_VERSION + "?app_id=" + appId).pipe(mergeMap(function (url) {
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.getAvatars = function () {
        var _this = this;
        return this.buildUrl(API_AVATARS).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }));
    };
    /**
     * Get a month view of audit calendar with schedules and past audits
     * @param year
     * @param month
     */
    ApiService.prototype.getCalendar = function (year, month) {
        var _this = this;
        return this.buildUrl(API_CALENDAR + "/" + year + "/" + month + "/").pipe(mergeMap(function (url) {
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.updateAuditor = function (auditor) {
        var _this = this;
        return this.buildUrl("" + API_AUDITORS + auditor.id + "/").pipe(mergeMap(function (url) {
            return _this.httpClient.put(url, auditor, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.sendFeedback = function (feedback) {
        var _this = this;
        return this.buildUrl(API_FEEDBACK).pipe(mergeMap(function (url) {
            return _this.httpClient.post(url, feedback, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.getLanguages = function () {
        var _this = this;
        return this.buildUrl(API_LANGUAGES).pipe(mergeMap(function (url) {
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.checkAuditFormUpdate = function (auditFormId, lastModifiedDate) {
        var _this = this;
        return this.buildUrl("" + API_AUDIT_FORMS + auditFormId + AUDIT_FORM_CHECK_UPDATE).pipe(mergeMap(function (url) {
            var headers = _this.buildHeaders();
            if (lastModifiedDate !== null)
                headers = headers.set(HEADER_IF_MODIFIED_SINCE, lastModifiedDate);
            return _this.httpClient.get(url, {
                headers: headers, observe: 'response',
            });
        }));
    };
    ApiService.prototype.fetchAuditForm = function (id, lastModifiedDate) {
        var _this = this;
        return this.buildUrl("" + API_AUDIT_FORMS_v3 + id + "/").pipe(mergeMap(function (url) {
            var headers = _this.buildHeaders();
            if (lastModifiedDate !== null)
                headers = headers.set(HEADER_IF_MODIFIED_SINCE, lastModifiedDate);
            return _this.httpClient.get(url, {
                headers: headers,
            });
        }));
    };
    ApiService.prototype.fetchIssues = function (page, pageSize, statuses, issueHandlerId, auditFormId, wardId) {
        var _this = this;
        if (page === void 0) { page = 0; }
        if (pageSize === void 0) { pageSize = 30; }
        if (statuses === void 0) { statuses = [QIP_STATUS_OPEN_SLUG, QIP_STATUS_IN_PROGRESS_SLUG]; }
        return this.buildUrl(API_ISSUES + "?limit=" + pageSize + "&offset=" + page).pipe(mergeMap(function (url) {
            if (issueHandlerId)
                url += "&handler=" + issueHandlerId;
            if (auditFormId)
                url += "&audit_form=" + auditFormId;
            if (wardId)
                url += "&room__ward=" + wardId;
            statuses.forEach(function (status) { return url += "&status=" + status; });
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.fetchScheduledAudits = function () {
        var _this = this;
        return this.buildUrl(API_UPCOMING_AUDITS).pipe(mergeMap(function (url) {
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    /**
     * Backs up audit to cloud
     */
    ApiService.prototype.createBackup = function (audit) {
        var _this = this;
        return this.buildUrl(API_BACKUP).pipe(mergeMap(function (url) {
            _this.logger.debug('Backing up', audit);
            return _this.httpClient.post(url, {
                data: audit,
            }, {
                headers: _this.buildHeaders(),
            }).pipe(tap(function (response) { return _this.logger.debug('Backup response', response); }), retry(3));
        }));
    };
    ApiService.prototype.uploadBackupMedia = function (backupId, media) {
        var _this = this;
        return this.buildUrl("" + API_BACKUP + backupId + API_BACKUP_UPLOAD_MEDIA).pipe(mergeMap(function (url) { return _this.httpClient.post(url, media, {
            headers: _this.buildHeaders(),
        }); }), retry(3), map(function (value) { return true; }));
    };
    /**
     * Downloads QIP photos for given backup
     * @param backupId primary key of the backup object in the back-end
     */
    ApiService.prototype.getBackupMedia = function (backupId) {
        var _this = this;
        return this.buildUrl("" + API_BACKUP + backupId + API_BACKUP_MEDIA).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }), retry(3));
    };
    /**
     * Backs up audit to cloud
     */
    ApiService.prototype.updateBackup = function (audit, backupId) {
        var _this = this;
        return this.buildUrl("" + API_BACKUP + backupId + "/").pipe(mergeMap(function (url) {
            _this.logger.debug('Updating backed-up audit', audit);
            return _this.httpClient.put(url, {
                data: audit,
            }, {
                headers: _this.buildHeaders(),
            }).pipe(tap(function (response) { return _this.logger.debug('Backup response', response); }), retry(3));
        }));
    };
    ApiService.prototype.getBackupList = function () {
        var _this = this;
        return this.buildUrl(API_BACKUP).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }), map(function (response) { return response.results; }));
    };
    ApiService.prototype.fetchIssueDetail = function (id) {
        var _this = this;
        return this.buildUrl(API_ISSUES + id).pipe(mergeMap(function (url) {
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    /**
     * Make PATCH request ato an arbitrary url within region with any data dictionary
     * @param relativeUrl
     * @param object
     */
    ApiService.prototype.patch = function (relativeUrl, object) {
        var _this = this;
        return this.buildUrl(relativeUrl).pipe(mergeMap(function (url) {
            return _this.httpClient.patch(url, object, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    /**
     * Checks if user is still authenticated on the back end.
     * If session is expired, logs out and redirects user to the login screen.
     * Returns boolean false if user session expired.
     * In any other case, or error returns true.
     */
    ApiService.prototype.checkAuthentication = function () {
        var _this = this;
        var token = this.accounts.token;
        return this.buildUrl(API_AUTHENTICATION_CHECK).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(), observe: 'response',
        }); }), tap(function (r) { return _this.logger.debug("Auth check for token " + _this.accounts.token, r); }), map(function () { return true; }), catchError(function (e) {
            _this.logger.debug('Auth check error', e);
            if (e instanceof HttpErrorResponse && e.status === 401) {
                if (token === _this.accounts.token)
                    return of(false);
                else {
                    _this.logger.warn('Token out of date. auth check invalid.');
                    return of(true);
                }
            }
            else
                return of(true);
        }), mergeMap(function (loginValid) {
            if (!loginValid) {
                _this.accounts.clearLogin();
                var promise = _this.router.navigate(['login']);
                return fromPromise(promise).pipe(map(function () { return loginValid; }));
            }
            else {
                return of(loginValid);
            }
        }));
    };
    ApiService.prototype.fetchRelatedFormData = function (config, answers) {
        var _this = this;
        var relativeUrl = "" + API_RELATED_FORM_DATA + config.id + "/get_data/";
        return this.buildUrl(relativeUrl).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
            params: new HttpParams({ fromObject: answers }),
        }); }));
    };
    ApiService.prototype.validateFormField = function (validator, value) {
        var _this = this;
        return this.buildUrl("" + API_VALIDATORS + validator.id + VALIDATORS_VALIDATE).pipe(mergeMap(function (url) { return _this.httpClient.post(url, {
            value: value,
        }, {
            headers: _this.buildHeaders(), observe: 'response',
        }); }), map(function () { }), catchError(function (e) {
            if (e instanceof HttpErrorResponse && e.status === 400) {
                var message = e.error.value[0];
                return of({
                    'backend-validation': {
                        message: message,
                        value: value,
                    },
                });
            }
            else
                return of({});
        }));
    };
    ApiService.prototype.fetchSubmissionsList = function () {
        var _this = this;
        return this.buildUrl(API_SUBMISSIONS_IN_PROGRESS).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.downloadAuditData = function (sessionId) {
        var _this = this;
        return this.buildUrl("" + API_AUDIT_SESSION + sessionId + "/submission-data/").pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
        }); }));
    };
    ApiService.prototype.submitAnalyticsEntry = function (modelName, modelObjectId) {
        var _this = this;
        return this.buildUrl(API_ANALYTICS).pipe(mergeMap(function (url) {
            return _this.httpClient.post(url, {
                model_name: modelName, model_object_id: modelObjectId
            }, {
                headers: _this.buildHeaders(),
            });
        }));
    };
    ApiService.prototype.fetchRemoteFormModelFieldChoices = function (customFieldId, search) {
        var _this = this;
        return this.buildUrl("" + API_REMOTE_FORM_FIELD_CHOICES + customFieldId + "/").pipe(mergeMap(function (url) {
            return _this.httpClient.get(url, {
                headers: _this.buildHeaders(),
                params: new HttpParams({ fromObject: { q: search } })
            });
        }), map(function (data) {
            var results = data['results'];
            if (!results || results.length === 0)
                return [];
            return results.map(function (result) { return ({ 'value': result['id'], 'label': result['text'] }); });
        }));
    };
    ApiService.prototype.fetchFieldDefaultValues = function (customFieldId, params) {
        var _this = this;
        return this.buildUrl("" + API_CUSTOM_FIELDS + customFieldId + API_CUSTOM_FIELD_DEFAULT_VALUE).pipe(mergeMap(function (url) { return _this.httpClient.get(url, {
            headers: _this.buildHeaders(),
            params: params,
        }); }), map(function (data) { return data || []; }));
    };
    return ApiService;
}());
export { ApiService };
