// possible role values
import {Model} from './model';

// possible level values
export const LEVEL_INSTITUTION = 'institution';
export const LEVEL_INSTITUTION_GROUP = 'institution_group';
// Account types
export const ACCOUNT_TYPE_REGULAR = 'regular';
export const ACCOUNT_TYPE_PUBLIC = 'public';


export class Auditor extends Model {
  id: number;
  auditor_id: string|null;
  receive_emails: boolean;
  role: string;
  level: string;
  /** audit form ids accessible to this user */
  forms: number[];
  patient_data_access: boolean | null;
  user: number;
  institution: number;
  avatar: number | null;
  num_audits: number;
  language: string;
  wards: number[];
  account_type: string;
  default_language: string;
  title: string;
  display_name: string;
  phone_number: string | null;
}
