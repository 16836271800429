import { ComplianceCalculator } from '../compliance/calculator';
import { Error } from 'tslint/lib/error';
import { AuditFormService } from '../audit-form.service';
var ChartsCalculator = /** @class */ (function () {
    function ChartsCalculator(observations, auditFormSchema, auditForm, complianceService) {
        this.observations = observations;
        this.auditFormSchema = auditFormSchema;
        this.auditForm = auditForm;
        this.complianceService = complianceService;
    }
    ChartsCalculator.prototype.getFieldsFromAuditForm = function (fieldNames) {
        var fields = [];
        for (var _i = 0, _a = this.auditFormSchema.sub_forms; _i < _a.length; _i++) {
            var subFrom = _a[_i];
            for (var _b = 0, _c = subFrom.fields; _b < _c.length; _b++) {
                var field = _c[_b];
                if (fieldNames.indexOf(field.field_name) > -1) {
                    fields.push(field);
                }
            }
        }
        for (var _d = 0, _e = this.auditFormSchema.fields; _d < _e.length; _d++) {
            var field = _e[_d];
            if (fieldNames.indexOf(field.field_name) > -1) {
                fields.push(field);
            }
        }
        return fields;
    };
    ChartsCalculator.prototype.getField = function (name) {
        var results = this.getFieldsFromAuditForm([name]);
        if (results.length === 1)
            return results[0];
        else
            throw new Error("Field " + name + " does not exist in this audit");
    };
    ChartsCalculator.prototype.getValuesFromObservation = function (field, observation) {
        var answers = [];
        this.auditFormSchema.sub_forms.forEach(function (subForm) {
            if (!observation[subForm.name])
                return;
            // add issues from each subobservation
            var subObservations = [];
            if (Array.isArray(observation[subForm.name]))
                subObservations.push.apply(subObservations, observation[subForm.name]);
            else
                subObservations.push(observation[subForm.name]);
            subObservations.forEach(function (subObservation) {
                var value = subObservation[field.field_name];
                if (value !== null && value !== undefined) {
                    answers.push(AuditFormService.getFieldAnswer(field, value));
                }
            });
        });
        if (observation[field.field_name] !== null && observation[field.field_name] !== undefined) {
            var value = observation[field.field_name];
            answers.push(AuditFormService.getFieldAnswer(field, value));
        }
        return answers;
    };
    ChartsCalculator.prototype.getNoOfAnswers = function (fieldName, observation) {
        var count = 0;
        this.auditFormSchema.sub_forms.forEach(function (subForm) {
            if (!observation[subForm.name])
                return;
            // add issues from each subobservation
            var subObservations = [];
            if (Array.isArray(observation[subForm.name]))
                subObservations.push.apply(subObservations, observation[subForm.name]);
            else
                subObservations.push(observation[subForm.name]);
            subObservations.forEach(function (subObservation) {
                if (subObservation[fieldName] !== null && subObservation[fieldName] !== undefined)
                    count++;
            });
        });
        if (observation[fieldName] !== null && observation[fieldName] !== undefined)
            count++;
        return count;
    };
    /**
     * Getting chart data for scoring based on a form field
     * @param {Widget} widget
     * @returns {MEGChartData[]} returns array of tuple containing chart data
     */
    ChartsCalculator.prototype.getFieldChartData = function (widget) {
        var data = [];
        var fieldComplianceDict = {};
        for (var _i = 0, _a = this.observations; _i < _a.length; _i++) {
            var observation = _a[_i];
            if (widget.config.field_name !== null && widget.config.field_name !== undefined) {
                var fields = this.getFieldsFromAuditForm([widget.config.field_name]);
                var complianceValue = this.complianceService.calculateObservationCompliance(this.auditFormSchema, observation);
                if (fields.length > 0 && complianceValue != null) {
                    var values = this.getValuesFromObservation(this.getField(fields[0].field_name), observation);
                    for (var _b = 0, values_1 = values; _b < values_1.length; _b++) {
                        var value = values_1[_b];
                        if (value instanceof Array || value instanceof Date)
                            continue;
                        if (fieldComplianceDict[value] === undefined) {
                            fieldComplianceDict[value] = { sumOfCompliance: complianceValue, sumOfWeights: fields[0].compliance_weight };
                        }
                        else {
                            fieldComplianceDict[value].sumOfCompliance += complianceValue;
                            fieldComplianceDict[value].sumOfWeights += fields[0].compliance_weight;
                        }
                    }
                }
            }
            else if (widget.config.field_names !== null) {
                var calc = new ComplianceCalculator(this.auditFormSchema);
                var fieldsCompliances = calc.calculateFieldsComplianceFromObservation(this.getFieldsFromAuditForm(widget.config.field_names), observation);
                for (var _c = 0, fieldsCompliances_1 = fieldsCompliances; _c < fieldsCompliances_1.length; _c++) {
                    var fieldCompliance = fieldsCompliances_1[_c];
                    if (fieldComplianceDict[fieldCompliance[0].label] === undefined) {
                        fieldComplianceDict[fieldCompliance[0].label] = {
                            sumOfCompliance: fieldCompliance[1],
                            sumOfWeights: fieldCompliance[0].compliance_weight
                        };
                    }
                    else {
                        fieldComplianceDict[fieldCompliance[0].label].sumOfCompliance += fieldCompliance[1];
                        fieldComplianceDict[fieldCompliance[0].label].sumOfWeights += fieldCompliance[0].compliance_weight;
                    }
                }
            }
        }
        for (var key in fieldComplianceDict) {
            if (fieldComplianceDict.hasOwnProperty(key)) {
                var complianceScore = fieldComplianceDict[key];
                var complianceLevel = 0;
                var complianceValue = complianceScore.sumOfCompliance / complianceScore.sumOfWeights;
                if (this.auditForm) {
                    complianceLevel = this.complianceService.getComplianceLevel(this.auditForm, complianceValue);
                }
                var compliance = widget.config.compliance ? complianceValue * 100 : complianceValue;
                var chartData = [key, compliance, complianceLevel];
                data.push(chartData);
            }
        }
        return data;
    };
    /**
     * Getting chart data for compliance scoring based on sub forms
     * @returns {MEGChartData[]} returns array of tuple containing chart data
     */
    ChartsCalculator.prototype.getSubFormChartData = function () {
        var _this = this;
        var data = [];
        var totalCompliantValue = 0;
        var keyPercentage;
        var complianceCalculator = new ComplianceCalculator(this.auditFormSchema);
        this.observations.forEach(function (observation, index) {
            totalCompliantValue = 0;
            var subFormName = '';
            _this.auditFormSchema.sub_forms.forEach(function (subForm) {
                if (!observation[subForm.name])
                    return;
                // add issues from each subobservation
                var subObservations = Array.isArray(observation[subForm.name])
                    ? observation[subForm.name]
                    : [observation[subForm.name]];
                subFormName = subForm.display_name;
                var subFormComplianceValue = 0;
                var allFieldsNA = true;
                subObservations.forEach(function (subObservation) {
                    var subFormCompliance = complianceCalculator.calculateSubObservationCompliance(subObservation, subForm);
                    if (subFormCompliance !== null) {
                        subFormComplianceValue += subFormCompliance;
                        allFieldsNA = false; // At least one field has a valid answer
                    }
                });
                if (allFieldsNA) {
                    totalCompliantValue = 'N/A'; // Set compliance to N/A if all fields are N/A
                    keyPercentage = subFormName + ": N/A";
                }
                else {
                    totalCompliantValue = subFormComplianceValue / subObservations.length; // Calculate average compliance
                    var valuePercentage = (totalCompliantValue * 100);
                    keyPercentage = subFormName + ":  " + valuePercentage.toFixed(0) + "%";
                }
            });
            var complianceLevel = null;
            if (_this.auditForm !== null) {
                complianceLevel = _this.complianceService.getComplianceLevel(_this.auditForm, totalCompliantValue);
            }
            if (complianceLevel === null) {
                complianceLevel = 0;
            }
            var chartData = [keyPercentage, totalCompliantValue, complianceLevel];
            data.push(chartData);
        });
        return data;
    };
    /**
     * Getting chart data based on the number of entries made on a form field
     * @param {Widget} widget
     * @returns {MEGChartData[]} returns array of tuple containing chart data
     */
    ChartsCalculator.prototype.calculateNoOfEntries = function (widget) {
        var _this = this;
        var countEntries = {};
        var fieldName = widget.config.field_name;
        if (fieldName !== null) {
            var fields_1 = this.getFieldsFromAuditForm([fieldName]);
            if (fields_1.length > 0) {
                this.observations.forEach(function (observation, index) {
                    if (fields_1[0].field_name === fieldName) {
                        var values = _this.getValuesFromObservation(_this.getField(fieldName), observation);
                        countEntries[index] = values.length;
                    }
                });
            }
        }
        var data = [];
        var percentPerObservation = 100 / this.observations.length;
        var percentUsing = Object.keys(countEntries).length * percentPerObservation;
        var percentNotUsing = 100 - percentUsing;
        var chartDataYes = ["Yes: " + percentUsing.toFixed(0) + "%", percentUsing, 0];
        var chartDataNo = ["No: " + percentNotUsing.toFixed(0) + "%", percentNotUsing, 0];
        data.push(chartDataYes);
        data.push(chartDataNo);
        return data;
    };
    /**
     * Getting chart data based on field value being entered
     * @param {Widget} widget
     * @returns {MEGChartData[]} returns array of tuple containing chart data
     */
    ChartsCalculator.prototype.getPieChartData = function (widget) {
        var choices = {};
        var totalValuesAnswered = 0;
        if (widget.config.field_name !== null && widget.config.field_name !== undefined) {
            var fieldName = widget.config.field_name;
            for (var _i = 0, _a = this.observations; _i < _a.length; _i++) {
                var observation = _a[_i];
                var values = this.getValuesFromObservation(this.getField(fieldName), observation);
                for (var _b = 0, values_2 = values; _b < values_2.length; _b++) {
                    var value = values_2[_b];
                    if (value instanceof Array || value instanceof Date)
                        continue;
                    totalValuesAnswered += 1;
                    var previousValue = choices[value];
                    if (previousValue) {
                        previousValue += 1;
                    }
                    else {
                        previousValue = 1;
                    }
                    choices[value] = previousValue;
                }
            }
        }
        var data = [];
        for (var key in choices) {
            if (choices.hasOwnProperty(key)) {
                var value = (choices[key]);
                var valuePercentage = (value * 100) / totalValuesAnswered;
                var keyPercentage = key + ":  " + valuePercentage.toFixed(0) + "%";
                var chartData = [keyPercentage, valuePercentage, 0];
                data.push(chartData);
            }
        }
        return data;
    };
    /**
     * Getting table chart data based on compliance scoring per form
     * @param {Widget} widget
     * @returns {{}} returns dictionary of data for table
     */
    ChartsCalculator.prototype.getTableData = function (widget) {
        var tableData = [];
        var fieldName = '';
        if (widget.config !== null && widget.config.field_name !== null) {
            fieldName = widget.config.field_name;
        }
        var field = this.getField(fieldName);
        var calculator = new ComplianceCalculator(this.auditFormSchema);
        var complianceScores = this.observations.map(function (observation) {
            var value = observation[fieldName];
            return {
                compliance: calculator.calculateObservationCompliance(observation),
                no_observation: 1,
                value: value,
                answer: AuditFormService.getFieldAnswer(field, value),
            };
        });
        var _loop_1 = function (complianceScore) {
            var data = tableData.find(function (rowData) { return rowData.value === complianceScore.value; });
            if (data !== undefined) {
                data.no_observation += 1;
                data.compliance = (data.compliance + complianceScore.compliance) / data.no_observation;
            }
            else {
                tableData.push(complianceScore);
            }
        };
        for (var _i = 0, complianceScores_1 = complianceScores; _i < complianceScores_1.length; _i++) {
            var complianceScore = complianceScores_1[_i];
            _loop_1(complianceScore);
        }
        return tableData;
    };
    return ChartsCalculator;
}());
export { ChartsCalculator };
