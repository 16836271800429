import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {Observation, SubObservation} from '../../api/models/observation';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  AccordionFieldAnswer,
  AuditFormSchema,
  Field,
  FIELD_WIDGET_ACCORDION,
  InlineFieldGroup,
  SubFormSchema
} from '../../api/models/audit-form-schema';
import {AuditForm, LayoutType} from '../../api/models/audit-form';
import {Audit} from '../audit';
import {AuditService} from '../audit.service';
import {AccordionItem} from './accordion-item';
import {isFieldRequired, isFieldVisible} from '../../utils/conditionals';
import {Errors, ObservationErrors} from '../../api/models/errors';
import {TranslateService} from '@ngx-translate/core';
import {isArrayOfType, isNullOrUndefined} from '../../utils/misc';
import {createFormFieldValidators} from './validators';
import {ApiService} from '../../api/api.service';
import {FormRelatedData, FormRelatedDataResponse} from '../../api/models/form-relation';
import {map, switchMap, filter} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {combineLatest, forkJoin, merge, of, Subscription, timer} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {AccountService} from '../../accounts.service';
import {InstitutionService} from '../../institution.service';
import {FormFieldComponent} from '../field/field.component';
import {WrappedFieldComponent} from '../wrapped-field/wrapped-field.component';

/**
 * Represents a subset of questions within the form.
 * If the group has a heading field, it becomes an accordion.
 */
class QuestionGroup {
  fields: (Field | InlineFieldGroup)[];
  heading: Field | null;
  component: ObservationComponent;

  constructor(component: ObservationComponent, heading?: Field) {
    this.component = component;
    this.heading = heading || null;
    this.fields = [];
  }

  private get allFields(): Field[] {
    const fields: Field[] = [];
    this.fields.forEach(field => {
      if (field instanceof InlineFieldGroup) fields.push(...field.fields);
      else fields.push(field);
    });
    return fields;
  }

  public get required(): boolean {
    if (this.allFields.find(field => field.required) !== undefined) return true;
    if (this.component === undefined) return false;
    return this.allFields.find((field: Field): boolean => {
      return isFieldRequired(field, this.component.form, this.component.audit, this.component.auditFormSchema);
    }) !== undefined;
  }
}

/**
 * Form component responsible for rendering forms for a single observation
 */
@Component({
  selector: 'meg-observation',
  templateUrl: './observation.component.html',
  styleUrls: ['./observation.component.css', '../../../animations.css']
})
export class ObservationComponent implements OnInit, OnDestroy {
  @Input() audit: Audit;
  @Input() auditForm: AuditForm;
  @Input() schema: AuditFormSchema | SubFormSchema;
  @Input() auditFormSchema: AuditFormSchema;
  @Input() observation: Observation | SubObservation;
  @Input() observationId: number | null = null;
  @Input() fieldErrors: ObservationErrors | null = null;
  public form: FormGroup;
  public accordionItems: AccordionItem[];
  @ViewChildren(ObservationComponent) public childObservations: QueryList<ObservationComponent>;
  @ViewChildren(WrappedFieldComponent) public wrappedFormFieldComponents: QueryList<WrappedFieldComponent>;
  public validated = false;
  public groupedFields: QuestionGroup[] = [];
  public relatedData: { source: FormRelatedData, data: FormRelatedDataResponse[] }[] = [];
  private subscriptions: Subscription[] = [];
  @Output() clearClicked = new EventEmitter<null>();
  @Output() deleteClicked = new EventEmitter<null>();
  @Output() progressSaved = new EventEmitter<null>();
  @Output() nextClicked = new EventEmitter<null>();
  @Output() backClicked = new EventEmitter<null>();
  @Input() isAccordionSubObservation = false;
  @Input() accordionIndex: number;
  @Input() public isNextLoading: boolean;
  public invalidObservationsCount = 0;
  public setValuePlaceholders: any = {};
  public hardcodedData: any = {};
  private accordionAnswers: AccordionFieldAnswer[] = [];

  constructor(private auditService: AuditService, private translateService: TranslateService, private apiService: ApiService,
              private accountService: AccountService, private institutionService: InstitutionService) {
  }

  async ngOnInit() {
    combineLatest([
      this.accountService.currentUser$,
      this.institutionService.getWardData(this.audit.wardId)
    ]).pipe(
      tap(([user, [institution, department, ward]]) => {
        let auditorName = null;
        let auditor_id = null;
        let title = null;
        if (user && user.first_name && user.last_name) {
          auditorName = `${user.first_name} ${user.last_name}`;
        } else if (user && user.username) {
          auditorName = user.username;
        }
        let auditorDisplayName = auditorName;
        if (user && user.auditor) {
          if (user.auditor.display_name) {
            auditorDisplayName = user.auditor.display_name;
          }
          if (user.auditor.auditor_id) {
            auditor_id = user.auditor.auditor_id;
          }
          if (user.auditor.title) {
            title = user.auditor.title;
          }
        }

        this.setValuePlaceholders = {
          '{auditor.id}': user ? user.auditor.id : null,
          '{auditor.name}': auditorName,
          '{auditor.display_name}': auditorDisplayName,
          '{auditor.phone_number}': user ? user.auditor.phone_number : null,
          '{auditor.email}': user ? user.email : null,
          '{auditor.staff_id}': auditor_id,
          '{auditor.title}': title,
          '{ward.manager}': ward ? ward.managed_by_id : null
        };

        this.hardcodedData = {
          '_hardcoded_institution': institution ? `${institution.id}` : null,
          '_hardcoded_department': department ? `${department.id}` : null,
          'ward': ward ? `${ward.id}` : null,
        };
        this.init();
      })).subscribe();
  }

  init() {
    this.buildFormGroup();
    this.createAccordionItems();
    this.setupLinkedFormsData();
    this.setupAccordionAnswers();
  }

  setupAccordionAnswers() {
    if (this.isAccordionSubObservation) {
      const accordionAnswersSubscription = this.auditService.accordionAnswers$.subscribe((fieldAnswers: AccordionFieldAnswer[]) => {
        this.accordionAnswers = fieldAnswers;
      });
      this.subscriptions.push(accordionAnswersSubscription);
      this.accordionAnswers = this.auditService.getCachedAccordionAnswers();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    if (!this.isAccordionSubObservation) {
      this.auditService.resetAccordionAnswers();
    } else if (this.accordionIndex) {
      this.auditService.deleteAccordionAnswers(this.accordionIndex);
    }
  }

  public setupLinkedFormsData() {
    const relatedDataSources = this.auditFormSchema.related_data_sources;
    if (relatedDataSources) {
      relatedDataSources.forEach((source) => {
        const sourceData: { source: FormRelatedData, data: FormRelatedDataResponse[] } = {
          source: source,
          data: [],
        };
        this.relatedData.push(sourceData);
        // prepare observables
        const observables: Observable<{ [fieldName: string]: string }>[] = source.matching_fields.map(fieldName => {
          return this.form.controls[fieldName].valueChanges.pipe(
            map(value => ({[fieldName]: value})),
          );
        });

        // subscribe to all observables at once
        const subscription = merge(...observables).pipe(
          tap(values => console.log('received values', values)),
          map(values => {
            source.matching_fields.forEach(fieldName => {
              // add other answers from this form to the request
              if (values[fieldName] === undefined) values[fieldName] = this.form.value[fieldName];
            });
            return values;
          }),
          tap(values => console.log('sending request with values', values)),
          switchMap((values) => this.apiService.fetchRelatedFormData(source, values)),
          tap(value => console.log('Received related data', value)),
          tap((value: FormRelatedDataResponse[]) => sourceData.data = value),
        ).subscribe();
        this.subscriptions.push(subscription);
      });
    }
  }

  public get subFormSchema(): SubFormSchema | null {
    if ((this.schema as AuditFormSchema).sub_forms === undefined) return this.schema as SubFormSchema;
    else return null;
  }

  public get fields(): Field[] {
    if (this.audit.auditSession.id) return this.schema.fields;
    return this.schema.fields.filter(field => field.show_in_app !== false);
  }

  private buildFormGroup() {
    const formControls: { [fieldName: string]: FormControl } = {};
    this.fields.forEach((field: Field) => {
      const validators: any[] = createFormFieldValidators(field.validators || [], false, this.apiService);
      const asyncValidators: any[] = createFormFieldValidators(field.validators || [], true, this.apiService);
      if (field.required) validators.push(Validators.required);
      formControls[field.field_name] = new FormControl(this.observation[field.field_name], validators, asyncValidators);
      if (this.observation.answer_comments === undefined) this.observation.answer_comments = [];
      const comments = this.observation.answer_comments.filter((comment) => comment.field_name === field.field_name);
      formControls['comment-' + field.field_name] = new FormControl(comments.length > 0 ? comments[0].comment : '');
    });
    this.form = new FormGroup(formControls);
    this.buildFieldGroups();
  }

  private buildFieldGroups() {
    this.groupedFields = [];
    let currentGroup = new QuestionGroup(this);
    let currentInlineGroup = new InlineFieldGroup();
    this.fields.forEach((field: Field) => {
      if (!field.field_group && currentInlineGroup.fields.length > 0) {
        currentGroup.fields.push(currentInlineGroup);
        currentInlineGroup = new InlineFieldGroup();
      }
      if (field.widget === FIELD_WIDGET_ACCORDION) {
        if (currentGroup.fields.length > 0) this.groupedFields.push(currentGroup);
        currentGroup = new QuestionGroup(this, field);
      } else if (field.field_group) {
        currentInlineGroup.fields.push(field);
        if (!currentInlineGroup.layout_columns) currentInlineGroup.layout_columns = this.getInlineGroupLayoutColumns(field.field_group);
      } else {
        currentGroup.fields.push(field);
      }
    });
    if (currentInlineGroup.fields.length > 0) currentGroup.fields.push(currentInlineGroup);
    if (currentGroup.fields.length > 0) this.groupedFields.push(currentGroup);
  }

  private getInlineGroupLayoutColumns(fieldGroupId: number) {
    return this.auditFormSchema.field_groups.filter(group => group.id === fieldGroupId)[0].layout_columns;
  }

  public isAccordionHeadingVisible(field: Field): boolean {
    return isFieldVisible(
      field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubObservation
    );
  }

  /**
   * Checks whether there are errors in given field group
   * @param group
   */
  public getAccordionHeadingError(group: QuestionGroup): boolean {
    const numErrors: number = group.fields.map((field: Field): number => {
      const formControl = this.form.controls[field.field_name];
      if (formControl.touched && !formControl.valid) return 1;
      if (this.fieldErrors !== null && this.fieldErrors !== undefined) {
        const errors = this.fieldErrors[field.field_name];
        if (errors instanceof Array) return errors.length;
      }
      return 0;
    }).reduce((a, b) => a + b, 0);
    return numErrors > 0;
  }

  public createAccordionItems() {
    this.accordionItems = [];
    const fieldErrors: Errors = this.fieldErrors as Errors || {};
    this.subforms.forEach((subform: SubFormSchema) => {
      const subformFieldErrors = fieldErrors[subform.name];
      if (subform.many) {
        const subObservations: SubObservation[] = this.observation[subform.name] as SubObservation[]
          || [this.auditService.createSubObservation()];
        const subformErrors: Errors[] = subformFieldErrors as Errors[] || [];
        const items = subObservations.map((subObs, index) => {
          return new AccordionItem(subform, subObs, subformErrors.length > index ? subformErrors[index] : null);
        });
        this.accordionItems.push(...items);
      } else {
        const item = new AccordionItem(subform, this.getSubObservation(subform), subformFieldErrors as Errors);
        this.accordionItems.push(item);
      }
    });
  }

  /**
   * Checks whether user input any data into the form
   * Filters out answer comment values.
   */
  private isEmpty(): boolean {
    if (this.observation.issues.length > 0) return false;
    const answers = Object.keys(this.form.controls)
      .filter(val => val.indexOf('comment-') < 0)
      .map((key: string) => this.form.value[key])
      .filter((value) => value !== null);
    return answers.length === 0;
  }

  private addError(key: string | undefined, error: Errors | Errors[] | string[]) {
    if (key) {
      if (!this.audit.errors) {
        this.audit.errors = {};
      }
      this.audit.errors[key] = error;
    }
  }

  /**
   * Checks whether form is valid;
   * if yes, copies values from each field into the observation
   */
  public onSave(validate: boolean, index: number | undefined): Observable<boolean> {
    const schema: SubFormSchema = this.schema as SubFormSchema;
    // sub-observation can be ignored if its not required (and is not an observation)
    const ignore = this.auditForm.config.form_layout === LayoutType.Accordion && schema.required !== undefined
      && !schema.required && this.isEmpty();

    if (!ignore) {
      // mark all fields as touched to show error messages
      Object.keys(this.form.controls)
        .map((key: string) => this.form.controls[key])
        .forEach((field: AbstractControl) => {
          field.markAsTouched({onlySelf: true});
          field.updateValueAndValidity({onlySelf: true});
        });
    }

    if (this.form.pending) {
      // Only wait for status change when form is "pending"
      return this.form.statusChanges.pipe(
        filter(status => status !== 'PENDING'),
        switchMap((status) => {
          this.save(validate, index);
          return of(true);
        }),
      );
    } else {
      // Add small-wait time to keep loading spinner from flashing
      return timer(250).pipe(
        switchMap(() =>  {
          this.save(validate, index);
          return of(true);
        })
      );
    }
  }

  private save(validate: boolean, index: number | undefined) {
    const schema: SubFormSchema = this.schema as SubFormSchema;
    // sub-observation can be ignored if it's not required (and is not an observation)
    const ignore = this.auditForm.config.form_layout === LayoutType.Accordion && schema.required !== undefined
      && !schema.required && this.isEmpty();

    let schemaKey: string = schema.name;
    if (schemaKey && index !== undefined) {
      schemaKey += `-${index}`;
    }

    if (!ignore) {
      if (this.form.valid || !validate) {
        const value = this.form.value;
        this.setupAccordionAnswers();
        this.schema.fields
          .forEach((field) => {
            if (isFieldVisible(
              field, this.form, this.audit, this.auditFormSchema,
              this.accordionAnswers, this.hardcodedData, this.isAccordionSubObservation
            )) {
              this.observation[field.field_name] = value[field.field_name];
              let answerComment: string | null = value['comment-' + field.field_name];
              if (answerComment) answerComment = answerComment.trim();
              if (this.observation.answer_comments === undefined) this.observation.answer_comments = [];
              const answerIndex = this.observation.answer_comments.findIndex(comment => comment.field_name === field.field_name);
              if (answerComment && answerIndex === -1) {
                  this.observation.answer_comments.push({
                    field_name: field.field_name,
                    comment: answerComment
                  });
              } else if (answerComment && answerIndex !== -1) {
                  this.observation.answer_comments[answerIndex].comment = answerComment;
              } else if (!answerComment && answerIndex !== -1) {
                 this.observation.answer_comments.splice(answerIndex, 1);
              }
            } else {
              delete this.observation[field.field_name];
            }
          });
      } else {
        const firstInvalid: Field | undefined = this.schema.fields.find((field) => this.form.controls[field.field_name].valid === false);
        if (firstInvalid !== undefined) {
          const fieldElement = document.getElementById(`question-${firstInvalid.field_name}`);
          if (fieldElement !== null) fieldElement.scrollIntoView();
          this.addError(schemaKey, []);
        }
        throw new Error(this.translateService.instant('audit-form.required-fields-error'));
      }
    }

    // For accordion layout
    if (this.accordionItems.length > 0) {
      // Get the first observation where a question has been answered
      const firstAnsweredObservation = this.childObservations.find((subObservationComponent: ObservationComponent) => {
        const subFormSchema = subObservationComponent.schema as SubFormSchema;
        return !isNullOrUndefined(subFormSchema.fields.find((field: Field) =>
          !isNullOrUndefined(subObservationComponent.form.controls[field.field_name].value)));
      });
      // If no observation with questions answered then throw an error
      if (isNullOrUndefined(firstAnsweredObservation)) {
        throw new Error(this.translateService.instant('audit-form.empty-forms-error'));
      }
    } else if (validate) {
      const formIsEmpty = this.isEmpty();
      // Checks if no question has been answered and if the schema is required.
      if (formIsEmpty && schema.required) {
        this.addError(schemaKey, [this.translateService.instant('audit-form.form-required')]);
        throw new Error(this.translateService.instant('audit-form.form-required'));
      }
      // If no question has been answered then throw an error
      if (formIsEmpty && this.auditForm.config.form_layout === LayoutType.Default) {
        this.addError(schemaKey, [this.translateService.instant('audit-form.empty-questions-error')]);
        throw new Error(this.translateService.instant('audit-form.empty-questions-error'));
      }
    }

    this.childObservations.forEach((subObservationComponent: ObservationComponent, childIndex) => {
      const subFormSchema = subObservationComponent.schema as SubFormSchema;
      subObservationComponent.save(validate, childIndex);
      const subObservation = subObservationComponent.observation as SubObservation;
      if (subObservationComponent.isEmpty()) {
        if (Array.isArray(this.observation[subFormSchema.name])) {
          const subObservations = this.observation[subFormSchema.name] as SubObservation[];
          const pos = subObservations.indexOf(subObservation);
          if (pos > -1) subObservations.splice(pos, 1);
        } else {
          delete this.observation[subFormSchema.name];
        }
      } else {
        if (this.auditForm.config.form_layout === LayoutType.Accordion && subFormSchema.many) {
          if (Array.isArray(this.observation[subFormSchema.name])) {
            const subObservations = this.observation[subFormSchema.name] as SubObservation[];
            // Don't add subobservation to the list if already added (editing observation)
            if (!subObservations.includes(subObservation)) subObservations.push(subObservation);
          } else {
            this.observation[subFormSchema.name] = [subObservation];
          }
        } else if (!this.observation[subFormSchema.name]) {
          this.observation[subFormSchema.name] = subObservation;
        }
      }
    });
    this.validated = true;
  }

  get numChoiceFields(): number {
    return this.schema.fields.filter(field => field.choices.length > 0).length;
  }

  public get showYesToAllButton(): boolean {
    const config = this.auditForm.config;
    if (config === null) return false;
    const minFields: number | null = config.show_yes_to_all;
    return minFields !== null && this.numChoiceFields >= minFields;
  }

  public get showNaToAllButton(): boolean {
    const config = this.auditForm.config;
    if (config === null) return false;
    const minFields: number | null = config.show_na_to_all;
    return minFields !== null && this.numChoiceFields >= minFields;
  }

  public get showQip(): boolean {
    if (this.schema instanceof AuditFormSchema && this.schema.sub_forms.length > 0) return false;
    const config = this.auditForm.config;
    if (config === null) return false;
    return config.enable_qip;
  }

  /**
   * Applies first compliant answer to all questions
   */
  public answerYesToAll() {
    this.schema.fields.filter((field) => field.compliant_value).forEach((field: Field) => {
      const patch: {[name: string]: string} = {};
      patch[field.field_name] = field.compliant_value as string;
      this.form.patchValue(patch);
    });
  }

  /**
   * Answers "N/A" to all questions
   */
  public answerNaToAll() {
    this.schema.fields.filter((field) => field.ignored_value).forEach((field: Field) => {
      const patch: {[name: string]: string} = {};
      patch[field.field_name] = field.ignored_value as string;
      this.form.patchValue(patch);
    });
  }

  public get subforms(): SubFormSchema[] {
    return (<AuditFormSchema>this.schema).sub_forms || [];
  }

  public getSubObservation(subform: SubFormSchema): SubObservation {
    let subObservation: SubObservation = this.observation[subform.name] as SubObservation;
    if (!subObservation) {
      subObservation = this.auditService.createSubObservation();
    }
    return subObservation;
  }

  private clearFormFields() {
    const formFields: FormFieldComponent[] = this.wrappedFormFieldComponents.reduce(
      (arr: FormFieldComponent[], f) => ([...arr, ...f.formFieldComponents.toArray()]
    ), []);

    formFields.map((formField) => formField.clearField());
  }

  public clearForm() {
    this.form.reset();
    this.form.markAsPristine();
    this.clearFormFields();
    this.childObservations.forEach((component) => component.clearForm());
  }

  public clearSubform(observation: SubObservation) {
    this.childObservations
      .filter((component) => component.observation === observation)
      .forEach((component) => {
        const subform = component.schema as SubFormSchema;
        if (confirm(`${this.translateService.instant('clear-prompt')} "${subform.display_name}"?`)) {
          component.clearForm();
        }
      });
  }

  public addSubObservation(subform: SubFormSchema) {
    if (!subform.many) throw new Error(`Cannot add new subform "${subform.name}" because only one is allowed`);
    const subObs = this.auditService.createSubObservation();
    let position = 0;
    this.accordionItems.forEach((item, index) => {
      if (item.subform === subform) position = index + 1;
    });
    const accordionItem = new AccordionItem(subform, subObs);
    this.accordionItems.splice(position, 0, accordionItem);
  }

  public deleteSubObservation(accordionItem: AccordionItem, formIndex: number) {
    if (!accordionItem.subform.many) throw new Error(`Cannot delete subform "${accordionItem.subform.name}"`);
    if (confirm(`${this.translateService.instant('delete-prompt')} "${accordionItem.subform.display_name}"?`)) {
      this.audit.auditSession.observations.forEach((observation: Observation) => {
        const subObservation = observation[accordionItem.subform.name];
        if (subObservation !== undefined && subObservation instanceof Array) {
          observation[accordionItem.subform.name] = (subObservation as Array<SubObservation>).filter(
            (subObservationValue: SubObservation) => subObservationValue !== accordionItem.subObservation);
        }
        return observation;
      });
      this.accordionItems.splice(formIndex, 1);
    }
  }

  public isFormDirty(): boolean {
    return this.form.dirty || this.childObservations
      .map((child) => child.isFormDirty())
      .reduce((child1, child2) => child1 || child2, false);
  }

  public isFormValid(): boolean {
    return this.form.valid || this.childObservations
      .map((child) => child.isFormValid())
      .reduce((child1, child2) => child1 || child2, false);
  }

  /**
   * Retrieves errors on the sub-observation level.
   * Ignores question-level errors.
   * @param accordionItem
   * @returns list of subform error messages. Empty list is returned if subform has errors bu they're on a higher (question) level.
   */
  public getSubObservationErrorMessages(accordionItem: AccordionItem, index: number): string[] | null {
    let errors: Errors | undefined | null = this.audit.errors;
    if (!errors) return null;
    // errors.observations will exist if the errors are returned from the server
    // else locally added errors will exist on errors
    if (!isNullOrUndefined(errors.observations)) {
      errors = (errors.observations as Errors[])
        .find( (observationErrors: Errors) => observationErrors[accordionItem.subform.name] !== undefined);
    }

    const schemaKey = `${accordionItem.subform.name}-${index}`;
    if (errors === null || errors === undefined) return null;
    if (!isNullOrUndefined(this.childObservations)) {
      // Checks if the current sub-observation is dirty before continuing.
      // This fixes the issue where two observations can have the subform but only one has errors
      const isFormValid =  this.childObservations
        .filter((component) => component.observation === accordionItem.subObservation)
        .map((child) => child.isFormValid())
        .reduce((child1, child2) => child1 || child2, false);
      if (!isFormValid && !errors[schemaKey]) {
        return null;
      }
    }

    const subformErrors: Errors | Errors[] | string[] | undefined = errors[schemaKey];
    if (isArrayOfType(subformErrors as Array<object>, 'string')) {
      // The subform has subform-level errors
      return subformErrors as string[];
    } else if (isNullOrUndefined(subformErrors)) {
      // The subform does not have any errors at all
      return null;
    } else {
      // Subform has errors, but on on the top level.
      // Return empty list if errors to show that there are errors, but no messages to be displayed in accordion
      return [];
    }
  }

  /**
   * Checks to see if there exists another subobservation the same name
   * @param accordionItem: the item to check
   * @return true if the number of forms with the same name is greater than 1
   */
  public hasMultipleForms(accordionItem: AccordionItem): boolean {
    return this.accordionItems.filter((accordionItemVal: AccordionItem) =>
      accordionItemVal.subform.name === accordionItem.subform.name).length > 1;
  }

  public onClearClicked() {
    this.clearClicked.emit();
  }

  public onDeleteClicked() {
    this.deleteClicked.emit();
  }

  public onProgressSave() {
    this.progressSaved.emit();
  }

  public onNextClicked() {
    this.nextClicked.emit();
  }

  public onBackClicked() {
    this.backClicked.emit();
  }
}
