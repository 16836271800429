import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MatSnackBar, MatSnackBarDismiss} from '@angular/material';
import {AuditSession} from '../api/models/audit-session';
import {NGXLogger} from 'ngx-logger';
import {ApiService} from '../api/api.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Audit} from '../audit-form/audit';
import {AuditService} from '../audit-form/audit.service';
import {AccountService} from '../accounts.service';
import {EMPTY, forkJoin, Observable, Subscription} from 'rxjs';
import {AuditForm} from '../api/models/audit-form';
import {AuditBackup} from '../api/models/backup';
import {TranslateService} from '@ngx-translate/core';
import {BackupService} from '../backup.service';
import {InstitutionService} from '../institution.service';
import { Ward } from '../api/models/ward';
import {AuditFormSchema} from '../api/models/audit-form-schema';
import {DownloadService} from '../loading/download.service';

interface PickerOption {
  auditSession: AuditSession;
  backup?: AuditBackup;
  isMultiStage: boolean;
  formLabel: string;
  objectLabel: string;
  wardLabel: string;
}

@Component({
  selector: 'meg-submission-picker',
  templateUrl: './submission-picker.component.html',
  styleUrls: ['./submission-picker.component.css'],

})
export class SubmissionPickerComponent implements OnInit, OnDestroy {
  public loading = true;
  private subscriptions: Subscription[] = [];
  public options: PickerOption[] = [];

  constructor(private dialogRef: MatDialogRef<SubmissionPickerComponent>, protected logger: NGXLogger, private api: ApiService,
              private auditService: AuditService, private changeDetectorRef: ChangeDetectorRef, private accounts: AccountService,
              private institutionService: InstitutionService, private backupService: BackupService,
              private trans: TranslateService, private snackbar: MatSnackBar, private downloadService: DownloadService) {
  }

  ngOnInit() {
    const subscription: Subscription = forkJoin([
      this.api.fetchSubmissionsList(),
      this.api.getBackupList()
    ]).pipe(
      mergeMap(([submissions, backups]) => {
        if (submissions.length === 0 && backups.length === 0) {
          // Handle the case when both submissions and backups are empty
          setTimeout(() => {
            this.setLoading(false);
          }, 2000);
          return EMPTY;
        }
        const wardIds: number[] = [
          ...submissions.filter(x => !!x.ward).map(x => x.ward) as number[],
          ...backups.map(x => x.data.wardId)
        ];

        const formIds: number[] = [
          ...submissions.filter(x => !!x.audit_form).map(x => x.audit_form) as number[],
          ...backups.map(x => x.data.auditFormId)
        ];

        return forkJoin([this.downloadService.getAuditFormsWithSchema(formIds, true), this.institutionService.getWards(wardIds)]).pipe(
          map(([forms, wards]): PickerOption[] => {
            const formMap: { [key: string]: AuditForm } = forms.reduce((m, [form]) => ({
              ...m,
              [form.id]: form
            }), {});
            const schemaMap: { [key: string]: AuditFormSchema } = forms.reduce((m, [form, schema = null]) => ({
              ...m,
              [form.id]: schema
            }), {});
            const wardMap: { [key: string]: Ward } = wards.reduce((m, obj) => ({
              ...m,
              [obj.id]: obj
            }), {});

            return [
              ...backups.filter(s => formIds.indexOf(<number>s.data.auditFormId) > -1).map((backup) => {
                const audit: Audit = backup.data;
                const session: AuditSession = backup.data.auditSession;
                const auditForm = formMap[audit.auditFormId] || undefined;
                const schema = schemaMap[audit.auditFormId] || undefined;
                const ward = wardMap[audit.wardId] || undefined;
                return {
                  auditSession: session,
                  backup: backup,
                  isMultiStage: false,
                  formLabel: !auditForm ? '' : auditForm.name,
                  objectLabel: this.auditService.getObservationRepr(auditForm, session, schema),
                  wardLabel: !ward ? '' : ward.name
                } as PickerOption;
              }), ...submissions.filter(s => formIds.indexOf(<number>s.audit_form) > -1).map((session) => {
                const auditForm = formMap[session.audit_form as number] || undefined;
                const schema = schemaMap[session.audit_form as number] || undefined;
                const ward = wardMap[session.ward as number] || undefined;

                return {
                  auditSession: session,
                  isMultiStage: auditForm && auditForm.config.stages ? auditForm.config.stages > 1 : false,
                  formLabel: !auditForm ? '' : auditForm.name,
                  objectLabel: this.auditService.getObservationRepr(auditForm, session, schema),
                  wardLabel: !ward ? '' : ward.name
                } as PickerOption;
              })
            ];
          }),
        );
      }),
    ).subscribe((options) => {
      this.changeDetectorRef.detectChanges();
      this.options = options;
      this.setLoading(false);
    }, () => this.setLoading(false));
    this.subscriptions.push(subscription);
  }

  private setLoading(value: boolean) {
    this.loading = value;
    this.changeDetectorRef.detectChanges();
  }

  public selectSubmission(submission: AuditSession) {
    if (!submission.id) return this.dialogRef.close();

    this.setLoading(true);
    const subscription = this.api.downloadAuditData(submission.id)
      .pipe(
        mergeMap((s) => this.accounts.getUser().pipe(
          map(user => user.id),
          map(userId => this.auditService.createPrepopulatedAudit(s, userId)),
        )),
      )
      .subscribe((audit: Audit) => this.dialogRef.close(audit), () => this.setLoading(false));
    this.subscriptions.push(subscription);
  }

  public selectBackup(backup: AuditBackup) {
    if (!backup.id) return this.dialogRef.close();

    this.setLoading(true);
    const sub = this.auditService.restoreBackupAudit(backup).pipe(
      mergeMap((audit) => this.backupService.restoreAuditMedia(backup)),
      catchError(() => this.showMessage(this.trans.get('audit-list.backup-download-error')).pipe(
        map(() => false),
      )),
    ).subscribe((success: boolean) => {
      if (success) this.dialogRef.close(backup.data);
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    }, () => this.setLoading(false));
    this.subscriptions.push(sub);
  }

  public selectItem(item: PickerOption) {
    if (item.backup) {
      this.selectBackup(item.backup);
    } else {
      this.selectSubmission(item.auditSession);
    }
  }

  private showMessage(message: Observable<string>): Observable<MatSnackBarDismiss> {
    return forkJoin([message, this.trans.get('ok')]).pipe(
      mergeMap(msg => this.snackbar.open(msg[0], msg[1], {duration: 5000}).afterDismissed()),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }
}
