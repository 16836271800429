import {
  AccordionFieldAnswer,
  AuditFormSchema,
  Choice,
  Condition,
  ConditionSearch,
  Field,
  SubFormSchema
} from '../api/models/audit-form-schema';
import {FormGroup} from '@angular/forms';
import {isNullOrUndefined} from './misc';
import {Audit} from '../audit-form/audit';
import {Answer, Observation, SubObservation} from '../api/models/observation';


export function getSubObservationValuesFromAudit(audit: Audit, auditSchema: AuditFormSchema, fieldName: string): Answer[] {
  let answers: Answer[] = [];
  if (auditSchema.sub_forms.length === 0) return answers;
  audit.auditSession.observations.forEach((observation: Observation) => {
    auditSchema.sub_forms.forEach((subformSchema: SubFormSchema) => {
      const subObservation: SubObservation = observation[subformSchema.name] as SubObservation;
      if (subObservation) {
        const answer: Answer = subObservation[fieldName] as Answer;
        if (answer) {
          answers = answers.concat(Array.isArray(answer) ? answer : [answer]);
        }
      }
    });
  });
  return answers;
}

/**
 * Get field value from form by field_name
 */
function getFieldValue(
  field_name: any,
  form: FormGroup,
  audit: Audit,
  auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [],
  hardcodedData: any = {},
  isAccordionSubform: boolean = false,
): Answer[] {
  if (hardcodedData && hardcodedData.hasOwnProperty(field_name) && !!hardcodedData[field_name]) {
      return hardcodedData[field_name];
  }
  const value: Answer[] = [];
  if (form.value[field_name] === undefined) {
    // if this subform does not contain this field, look into other subforms within this observation
    if (isAccordionSubform) {
      fieldAnswers.filter(fieldAnswer => fieldAnswer.field_name === field_name)
        .forEach((fieldAnswer: AccordionFieldAnswer) => {
          if (Array.isArray(fieldAnswer.answer)) {
            value.push(...fieldAnswer.answer as Answer[]);
          } else {
            value.push(fieldAnswer.answer);
          }
        });
    } else {
      const observationValues: Answer[] = getSubObservationValuesFromAudit(audit, auditSchema, field_name);
      value.push(...observationValues);
    }
  } else {
    // Use value from the same subobservations
    const v = form.value[field_name];
    if (Array.isArray(v)) value.push(...v);
    else value.push(v);
  }
  return value;
}

/**
 * Evaluates whether condition is true in the given form context
 */
function evaluateCondition(
  condition: any,
  form: FormGroup,
  audit: Audit,
  auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [],
  hardcodedData: any = {},
  isAccordionSubform: boolean = false
): boolean {
  const isNested = condition.hasOwnProperty('operator');
  if (!isNested && typeof condition.field_name === 'string') {
      const value = getFieldValue(condition.field_name, form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform);
      return evaluateConditionHasValue(condition, value);
  } else {
    if (isNested) {
      return evaluateNestedCondition(condition, form, audit, auditSchema, fieldAnswers, isAccordionSubform);
    } else if (condition.hasOwnProperty('field_name')) {
      return evaluateNestedCondition(condition.field_name, form, audit, auditSchema, fieldAnswers, isAccordionSubform);
    }
    return false;
  }
}

/**
 * Filter out conditions that evaluate false
 * @param conditions a list of conditions to consider
 * @param form context form to get compare values with
 * @param audit audit data containing all observation answers
 * @param auditSchema the audit form schema
 * @param fieldAnswers manually added observation answer data
 * @param hardcodedData hardcoded fields data
 * @param isAccordionSubform whether the current form contains accordion subforms
 * @return a subset of conditions that evaluate true
 */
function evaluateConditions(
  conditions: Condition[],
  form: FormGroup,
  audit: Audit,
  auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [],
  hardcodedData: any = {},
  isAccordionSubform: boolean = false
): Condition[] {
  return conditions.filter(condition => evaluateCondition(
    condition, form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform
  ));
}

/**
 * Tells whether field should be shown
 *
 * All fields are shown by default, but some fields may be conditional
 * and depend on another field to have a certain value
 */
export function isFieldVisible(
  field: Field,
  form: FormGroup,
  audit: Audit,
  auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [],
  hardcodedData: any = {},
  isAccordionSubform: boolean = false
): boolean {
  const showConditions = field.conditions.filter((condition: Condition) => condition.show);
  // Special case, no "show" conditions - return true to show field by default
  if (showConditions.length === 0) return true;
  // Return true if any of the conditions is true
  return evaluateConditions(showConditions, form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform).length > 0;
}

export function getFieldImage(
  field: Field,
  form: FormGroup,
  audit: Audit,
  auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [],
  hardcodedData: any = {},
  isAccordionSubform: boolean = false
): string | null {
  let imageConditions = field.conditions.filter((condition: Condition) => !isNullOrUndefined(condition.set_image));
  imageConditions = evaluateConditions(imageConditions, form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform);
  // no conditions evaluate true, return field's default image
  if (imageConditions.length === 0) return field.image_base64;
  // return first image matching conditions
  else return imageConditions[0].set_image;
}

/**
 * Tells whether field should be required based on field property and conditions
 * @param {Field} field
 * @param {FormGroup} form
 * @param audit audit data containing all observation answers
 * @param auditSchema the audit form schema
 * @param fieldAnswers manually added observation answer data
 * @param hardcodedData data for hardcoded fields
 * @param isAccordionSubform whether the current form contains accordion subforms
 * @return {boolean}
 */
export function isFieldRequired(
  field: Field,
  form: FormGroup,
  audit: Audit,
  auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [],
  hardcodedData: any = {},
  isAccordionSubform: boolean = false
): boolean {
  const conditions = field.conditions.filter((condition: Condition) => condition.make_required);
  // Special case: If there are no "make_required" conditions, return whatever default value is for this field
  if (conditions.length === 0) return field.required;

  /** Returns true if any of the passed values is true */
  return evaluateConditions(conditions, form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform).length > 0;
}

export function getSetValue(value: string | null, setValuePlaceholders: any = null): Answer | undefined | null {
  let calculatedValue: any = null;
  try {
    if (value && Object.keys(setValuePlaceholders).includes(value)) {
      calculatedValue = setValuePlaceholders[value];
    }
  } catch (e) {
    console.error(e);
  }
  if (calculatedValue != null) return calculatedValue;
  return value;
}

/**
 * Evaluates first matching set_value condition based on given list of conditions and form state.
 * Returns undefined if there is no matching condition.
 * Otherwise, returns a value which should be non-null
 */
export function getConditionalValue(
  field: Field,
  form: FormGroup,
  audit: Audit,
  auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [],
  hardcodedData: any = {},
  isAccordionSubform: boolean = false,
  setValuePlaceholders: any = null
): Answer | undefined | null {
  let conditions: Condition[] = field.conditions.filter((condition: Condition) => condition.set_value);
  conditions = evaluateConditions(conditions, form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform);
  if (conditions.length === 0) return undefined;
  return getSetValue(conditions[0].set_value, setValuePlaceholders);
}

/**
 * Returns last matching set_value default condition based on given list of conditions and form state.
 * Returns undefined if there is no matching default condition.
 * Otherwise, returns a value which should be non-null
 */
export function getDefaultConditionalValue(
  field: Field,
  form: FormGroup,
  audit: Audit,
  auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [],
  hardcodedData: any = {},
  isAccordionSubform: boolean = false,
  setValuePlaceholders: any = null
): Answer | undefined | null {
  const setValues = field.conditions.filter((condition: any) => {
    const value = getFieldValue(field.field_name, form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform);

    return (
      condition.set_value && (!condition.has_value || value.length === 0 || evaluateConditionHasValue(condition, value))
    );
  }).map((condition) => getSetValue(condition.set_value, setValuePlaceholders));

  return setValues.length > 0 ? setValues[0] : undefined;
}


export function getFieldChoices(
  field: Field, form: FormGroup, audit: Audit, auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [], hardcodedData: any = {}, isAccordionSubform: boolean = false
): Choice[] {
  const choiceConditions: Condition[] = field.conditions.filter(condition => {
    return !isNullOrUndefined(condition.limit_choices) && evaluateCondition(
      condition, form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform);
  });
  // Special case: return field's default set of choices if it doesn't have a any conditions,
  // or none of the limit_choices conditions evaluate true
  if (choiceConditions.length === 0) return field.choices;
  // @ts-ignore
  const allowedChoices: string[] = choiceConditions.map(condition => condition.limit_choices)
    .reduce((prev: string[], current: string[]): string[] => prev.concat(current));
  return field.choices.filter(choice => allowedChoices.indexOf(choice.value) !== -1);
}

/**
 * Handles nested field condition. Example:
 * {"operator": "AND", "fields": [{"field_name": "question_1", "answer": "yes"}, {"field_name": "question_2", "answer": "no"}]}
 */
export function evaluateNestedCondition(
  condition: any,
  form: FormGroup,
  audit: Audit,
  auditSchema: AuditFormSchema,
  fieldAnswers: AccordionFieldAnswer[] = [],
  hardcodedData: any = {},
  isAccordionSubform: boolean = false
) {
  const isNested = condition.hasOwnProperty('operator');
  if (!isNested) {
    return evaluateCondition(condition, form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform);
  }
  const operator = condition.operator;
  for (let i = 0; i < condition.fields.length; i++) {
    const fieldConditionValid = evaluateCondition(
      condition.fields[i], form, audit, auditSchema, fieldAnswers, hardcodedData, isAccordionSubform
    );
    if (operator === 'OR' && fieldConditionValid) {
      return true;
    }
    if (operator === 'AND' && !fieldConditionValid) {
      return false;
    }
  }
  return operator !== 'OR';
}

export function evaluateConditionHasValue(condition: Condition | any, value: any | any[]): boolean {
  const expectedValue = condition.hasOwnProperty('has_value') ? condition.has_value : condition.answer;
  const searchType = condition.search || ConditionSearch.EXACT;

  const normalizeValue = (v: Answer) => {
    return v.toString().toLowerCase();
  };
  const isContained = (x: Answer, y: Answer) => {
    return (x !== null && y !== null && normalizeValue(x).indexOf(normalizeValue(y)) !== -1);
  };
  if (!value === true && !expectedValue === true) return true;
  else if (expectedValue === ConditionSearch.ANY) return true;
  else if (searchType === ConditionSearch.CONTAINS) {
    if (Array.isArray(value) && !Array.isArray(expectedValue)) {
      return value.filter(function (x) {
        return isContained(x, expectedValue);
      }).length > 0;
    }

    if (Array.isArray(value) && Array.isArray(expectedValue)) {
      return value.filter(function (x) {
        return expectedValue.filter(function (y) {
          return isContained(x, y);
        }).length > 0;
      }).length > 0;
    }

    if (!Array.isArray(value) && Array.isArray(expectedValue)) {
      return expectedValue.filter(function (y) {
        return isContained(value, y);
      }).length > 0;
    }
    return isContained(value, expectedValue);
  } else if (Array.isArray(value) && !Array.isArray(expectedValue)) {
    return value.includes(expectedValue);
  }
  return value === expectedValue;
}
