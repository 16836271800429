import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseComponent} from '../../../base.component';
import {IssueService, IssueStatus} from '../issue.service';
import {Issue, IssueHandler} from '../../../api/models/issue';
import {ActivatedRoute, Params} from '@angular/router';
import {catchError, map, mergeMap, retry} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {Location} from '@angular/common';
import {Observable} from 'rxjs/internal/Observable';
import {AccountService} from '../../../accounts.service';
import {QipService} from '../../qip.service';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'meg-issue-detail',
  templateUrl: './issue-detail.component.html',
  styleUrls: [
    './issue-detail.component.css',
    '../issue.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class IssueDetailComponent extends BaseComponent implements OnInit {
  public issue: Issue;
  public status: IssueStatus | null = null;
  public location$: Observable<string>;
  public issueHandlerName$: Observable<string>;
  public editable = false;
  public statusChoices: IssueStatus[] = [];
  public issueHandlers: IssueHandler[] = [];
  public id: number;

  constructor(injector: Injector, private issueService: IssueService, private route: ActivatedRoute, private location: Location,
              private accountService: AccountService, private qipService: QipService) {
    super(injector);
  }

  ngOnInit() {
    this.subscriptions.push(this.route.params.pipe(
      map((params: Params): number => {
        this.id = +params.issue_id;
        if (isNaN(this.id)) throw Error(`Invalid id ${params.issue_id}`);
        return this.id;
      }),
      mergeMap((id: number) => this.issueService.getIssue(id)),
      tap((issue: Issue) => this.issue = issue),
      tap((issue: Issue) => this.status = this.issueService.getIssueStatusData(issue)),
      tap((issue) => this.location$ = this.issueService.getLocationLabel(issue.room)),
      tap((issue) => this.issueHandlerName$ = this.issueService.getIssueHandlerName(issue.handler, issue.audit_form || null)),
      mergeMap((issue: Issue): Observable<IssueHandler[]> => this.qipService.getIssueHandlers(
        issue.institution || null,
        issue.audit_form || null),
      ),
      tap((issueHandlers: IssueHandler[]) => this.issueHandlers = issueHandlers),
      retry(3),
      catchError((error) => {
        this.logger.error('Error loading data', error);
        return this.translateService.get('qip.issue-detail.error').pipe(
          tap((message: string) => alert(message)),
          tap(() => this.goBack()),
        );
      }),
    ).subscribe());

    this.subscriptions.push(this.accountService.hasPermission('qip.change_issue').pipe(
      tap((hasEditPermission: boolean) => this.editable = hasEditPermission),
    ).subscribe());

    if (this.id > 0) {
      this.subscriptions.push(this.issueService.getStatuses(this.id).pipe(
        tap((statuses: IssueStatus[]) => this.statusChoices = statuses),
      ).subscribe());
    }
  }

  compareStatuses(status1: IssueStatus, status2: IssueStatus): boolean {
    return status1.slug === status2.slug;
  }

  public goBack() {
    this.location.back();
  }

  public saveClicked() {
    this.issue.qipstatus = this.issue.status.slug;
    this.subscriptions.push(this.issueService.saveIssue(this.issue).pipe(
      tap(() => this.snackbar.open('Issue Saved', undefined, {
        duration: 2000,
      })),
      tap(() => this.location.back()),
      catchError((error) => {
        this.logger.error(error);
        return of(false);
      }),
    ).subscribe());
  }
}
