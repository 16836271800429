import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AccordionFieldAnswer,
  AuditFormSchema,
  Choice,
  Condition,
  Field,
  FIELD_WIDGET_AUDIO,
  FIELD_WIDGET_CHECKBOXINPUT,
  FIELD_WIDGET_DATEINPUT,
  FIELD_WIDGET_DATETIMEINPUT,
  FIELD_WIDGET_DOBINPUT,
  FIELD_WIDGET_FILEINPUT,
  FIELD_WIDGET_FLOATINPUT,
  FIELD_WIDGET_MODEL_HEADING,
  FIELD_WIDGET_MODEL_SELECT,
  FIELD_WIDGET_MODEL_SELECT_MULTIPLE,
  FIELD_WIDGET_MULTIFILEINPUT,
  FIELD_WIDGET_NUMBERINPUT,
  FIELD_WIDGET_RADIOSELECT,
  FIELD_WIDGET_REMOTE_FORM_SELECT,
  FIELD_WIDGET_SELECT,
  FIELD_WIDGET_SELECTMULTIPLE,
  FIELD_WIDGET_SHORTDATEINPUT,
  FIELD_WIDGET_STOPWATCH,
  FIELD_WIDGET_TEXTAREA,
  FIELD_WIDGET_TEXTINPUT,
  FIELD_WIDGET_TIMEINPUT,
  INSTITUTION,
  ROOM,
  SubFormSchema,
} from '../../api/models/audit-form-schema';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {merge, Observable, of, Subscription} from 'rxjs';
import {
  evaluateConditionHasValue,
  getConditionalValue,
  getDefaultConditionalValue,
  getFieldChoices,
  getFieldImage,
  getSetValue,
  getSubObservationValuesFromAudit,
  isFieldRequired,
  isFieldVisible
} from '../../utils/conditionals';
import {AuditForm, CUSTOM_OBSERVATION_NAME, LayoutType} from '../../api/models/audit-form';
import {Department} from '../../api/models/department';
import {Institution} from '../../api/models/institution';
import {Ward} from '../../api/models/ward';
import {isHandHygieneAudit} from '../../utils/misc';
import {COMPLIANCE_INCOMPLIANT, ComplianceCalculator, Incompliant} from '../../compliance/calculator';
import {AuditFormService} from '../../audit-form.service';
import {MatDialog, MatRadioChange} from '@angular/material';
import {Answer, Observation, SubObservation} from '../../api/models/observation';
import {Audit} from '../audit';
import {Settings} from '../../settings/settings';
import {SettingsService} from '../../settings/settings.service';
import {QipService} from '../../qip/qip.service';
import {CommonIssue} from '../../api/models/common-issue';
import {IssueDialogArguments} from '../../qip/issues-widget/issue-dialog-arguments';
import {CreateIssueDialogComponent} from '../../qip/issues-widget/create-issue-dialog/create-issue-dialog.component';
import {Issue, Room} from '../../api/models/issue';
import {DomSanitizer, SafeValue} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {
  choiceValidator,
  createErrorMessages,
  createFormFieldValidators,
  integerValidator
} from '../observation/validators';
import {AuditService} from '../audit.service';
import {InstitutionService} from '../../institution.service';
import {debounceTime, map, mergeMap, startWith, tap} from 'rxjs/operators';
import {ApiService} from '../../api/api.service';
import {IssuesDialogComponent} from '../../qip/issues-widget/issues-dialog/issues-dialog.component';
import {MatAutocompleteSelectedEvent} from '@angular/material/typings/autocomplete';

/**
 * Component representing a Question in Audit form.
 * Wraps specialized widget field depending on field type
 */
@Component({
  selector: 'meg-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldComponent implements OnInit, OnDestroy {
  @Input() field: Field;
  @Input() form: FormGroup;
  @Input() errors: string[];
  @Input() observation: Observation | SubObservation;
  @Input() audit: Audit;
  @Input() public auditForm: AuditForm;
  @Input() public auditFormSchema: AuditFormSchema;
  @Input() public subForm: SubFormSchema | null = null;
  @Input() accordionIndex: number;
  @Input() editing = false;
  @Input() public setValuePlaceholders: {[key: string]: string} = {};
  @Input() public hardcodedData: {[key: string]: any} = {};
  private complianceCalculator: ComplianceCalculator | null = null;
  private subscriptions: Subscription[] = [];
  private settings: Settings;
  public maxDate = new Date();
  public startDOBDate = new Date(1970, 0, 1);
  // supported widget types:
  public widgetTypes = {
    SELECT: FIELD_WIDGET_SELECT,
    SELECT_RADIO: FIELD_WIDGET_RADIOSELECT,
    SELECT_MULTIPLE: FIELD_WIDGET_SELECTMULTIPLE,
    TEXT_INPUT: FIELD_WIDGET_TEXTINPUT,
    TEXT_AREA: FIELD_WIDGET_TEXTAREA,
    NUMBER_INPUT: FIELD_WIDGET_NUMBERINPUT,
    STOPWATCH_INPUT: FIELD_WIDGET_STOPWATCH,
    CHECKBOX_INPUT: FIELD_WIDGET_CHECKBOXINPUT,
    DATE_INPUT: FIELD_WIDGET_DATEINPUT,
    TIME_INPUT: FIELD_WIDGET_TIMEINPUT,
    DATE_TIMEINPUT: FIELD_WIDGET_DATETIMEINPUT,
    FLOAT_INPUT: FIELD_WIDGET_FLOATINPUT,
    SHORT_DATE_INPUT: FIELD_WIDGET_SHORTDATEINPUT,
    DOB_INPUT: FIELD_WIDGET_DOBINPUT,
    FILE_INPUT: FIELD_WIDGET_FILEINPUT,
    MULTIFILE_INPUT: FIELD_WIDGET_MULTIFILEINPUT,
    AUDIO_INPUT: FIELD_WIDGET_AUDIO,
    MODEL_SELECT: FIELD_WIDGET_MODEL_SELECT,
    REMOTE_FORM_SELECT: FIELD_WIDGET_REMOTE_FORM_SELECT,
    MODEL_SELECT_MULTIPLE: FIELD_WIDGET_MODEL_SELECT_MULTIPLE,
    HEADING: FIELD_WIDGET_MODEL_HEADING,
  };
  filteredChoices: Observable<Choice[]> = of([]);
  filteredRemoteFormChoices: Observable<Choice[]> = of([]);
  filteredMultiSelectChoices: Observable<Choice[]> = of([]);

  multiChoiceFormControl = new FormControl();
  @ViewChild('choiceInput') choiceInput: ElementRef<HTMLInputElement>;
  public remoteFormChoices: Choice[] = [];
  public showCommmentBox = false;
  public issue_label: Observable<string>;
  public issue_label_plural: Observable<string>;
  private isAccordionSubform: boolean;
  private accordionAnswers: AccordionFieldAnswer[] = [];
  public fieldImage: SafeValue | string;
  public questionImageSize = false;

  constructor(private auditFormService: AuditFormService, private dialog: MatDialog, private settingsService: SettingsService,
              private qipService: QipService, public domSanitizer: DomSanitizer, private translateService: TranslateService,
              private logger: NGXLogger, private auditService: AuditService, private institutionService: InstitutionService,
              private apiService: ApiService, private cdr: ChangeDetectorRef) {
  }

  /**
   * Tells whether timer component should be shown
   */
  public get showTimer(): boolean {
    return this.field.field_name === 'duration' && isHandHygieneAudit(this.auditForm);
  }

  /**
   * Compliance based on currently entered answer
   * or null of not answered, or field is not used for compliance.
   * @return {number | null}
   */
  public get compliance(): number | null {
    if (this.complianceCalculator === null || this.formField.value === undefined || this.formField.value === null) {
      return null;
    } else {
      try {
        return this.complianceCalculator.calculateFieldCompliance(this.field, this.formField.value);
      } catch (e) {
        if (e instanceof Incompliant) return COMPLIANCE_INCOMPLIANT;
        else {
          this.logger.error(e);
          return null;
        }
      }
    }
  }

  /**
   * Tells whether field should be shown
   *
   * All fields are shown by default, but some fields may be conditional
   * and depend on another field to have a certain value
   */
  public get isVisible(): boolean {
    const config = this.auditForm.config;
    if (!this.isRequired && config && this.settings && !config.show_non_required_fields && !this.settings.showOptionalFields) return false;
    const visible = isFieldVisible(
      this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform
    );
    // if the field was hidden but still has value, clear the value
    if (!visible) {
      if (this.formField.value && !this.canAutoCycle) {
        this.resetField();
      }
      // use conditional default value if exists
      this.setDefaultValue();
    }
    return visible;
  }

  public get isRequired(): boolean {
    return isFieldRequired(
      this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform
    );
  }

  /**
   * Returns choices that haven't been selected yet in previous observation inside session
   */
  private get autoCycleChoices(): Choice[] {
    const choices = getFieldChoices(
      this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform
    );

    if (this.formField.value) {
      // Once selected don't allow user to update the value
      return choices.filter(({value}) => value === this.formField.value);
    }

    const existingFieldValues = this.audit.auditSession.observations.map((obs, index) => {
      let value = this.auditService.getFieldValue(
        this.field.field_name, this.audit.auditSession.observations[index], this.auditFormSchema
      );
      if (value instanceof Array) value = value[value.length - 1] as Answer;
      return value;
    });

    return choices.filter(({value}) => !existingFieldValues.includes(value));
  }

  public get choices(): Choice[] {
    if (this.remoteFormChoices.length > 0) {
      return this.remoteFormChoices;
    } else if (this.canAutoCycle) {
      return this.autoCycleChoices;
    }
    return getFieldChoices(
      this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers,
      this.hardcodedData, this.isAccordionSubform
    );
  }

  public get fieldClass(): string {
    if (this.field.widget === this.widgetTypes.HEADING) return 'none';
    else return isFieldRequired(
      this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform
    ) ? 'required' : 'optional';
  }

  public get showClearButton(): boolean {
    if (this.field.widget === this.widgetTypes.HEADING) return false;
    else return !this.field.required && this.form.controls[this.field.field_name].value !== null;
  }

  get formField(): FormControl {
    return <FormControl>this.form.get(this.field.field_name);
  }

  /**
   * Whether an autocomplete text field should be used for this select field.
   * Decides whether widget for this field should be overridden with an autocomplete widget
   * based on how many choices it has and whether its a multiselect.
   */
  public get useAutocompleteWidget(): boolean {
    if (this.choices.length < 38) return false;
    return this.field.widget === FIELD_WIDGET_SELECT || this.field.widget === FIELD_WIDGET_MODEL_SELECT;
  }
  public get useAutocompleteMultiSelectWidget(): boolean {
    if (this.choices.length < 38) return false;
    return this.field.widget === FIELD_WIDGET_SELECTMULTIPLE || this.field.widget === FIELD_WIDGET_MODEL_SELECT_MULTIPLE;
  }
  public get useIntegerWidget(): boolean {
    return this.field.widget === this.widgetTypes.NUMBER_INPUT;
  }
  /**
   * Updated "Required" property of the field
   * and re-runs validation
   */
  private updateValidators() {
    // redundant call to updateValueAndValidity to address issue #22632
    this.formField.updateValueAndValidity({emitEvent: false});
    const validators: any[] = createFormFieldValidators(this.field.validators || [], false, this.apiService);
    if (this.isRequired) validators.push(Validators.required);
    if (this.useIntegerWidget) validators.push(integerValidator());
    if (this.useAutocompleteWidget) validators.push(choiceValidator(this.choices));
    this.formField.setValidators(validators);
    // @ts-ignore
    this.formField.setAsyncValidators(createFormFieldValidators(this.field.validators || [], true, this.apiService));
    this.formField.updateValueAndValidity({emitEvent: false});
  }

  public addIssue() {
    const subscription = this.qipService.getCommonIssues(this.auditForm, this.subForm).pipe(
      mergeMap((commonIssues: CommonIssue[]): Observable<any> => {
        if (commonIssues.length === 0) {
          // No common issues, display Add Issue Form
          return this.dialog.open(CreateIssueDialogComponent, {
            height: '90%',
            data: new IssueDialogArguments(this.observation.issues, this.audit, undefined, null,
              undefined, true, 'qip.issues', false, this.issue_label, this.issue_label_plural),
          }).afterClosed().pipe(
            tap((issue: Issue) => {
              if (issue instanceof Issue) {
                issue.field_name = this.field.field_name;
                this.observation.issues.push(issue);
              } else throw new Error(`issue is not of type Issue. ${issue}`);
            }),
          );
        } else {
          // Show list of common issues
          return this.dialog.open(IssuesDialogComponent, {
            height: '90%',
            data: new IssueDialogArguments(this.observation.issues, this.audit, commonIssues, this.field.field_name,
              undefined, true, 'qip.issues', false, this.issue_label, this.issue_label_plural),
          }).afterClosed();
        }
      }),
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  private get isRemoteDefaultValueEnabled () {
    return this.field.config && this.field.config.default_value && this.field.config.default_value.enabled;
  }

  private get remoteDefaultValueMatchFields (): string[] {
    if (!this.isRemoteDefaultValueEnabled || !this.field.config || !this.field.config.default_value) return [];
    return this.field.config.default_value.match_fields || [];
  }

  private getMatchFieldValue(field_name: any): Answer[] {
    const value: Answer[] = [];
    if (this.form.controls[field_name].value === undefined) {
      // if this subform does not contain this field, look into other subforms within this observation
      if (this.isAccordionSubform) {
        this.accordionAnswers.filter(fieldAnswer => fieldAnswer.field_name === field_name)
          .forEach((fieldAnswer: AccordionFieldAnswer) => {
            if (Array.isArray(fieldAnswer.answer)) {
              value.push(...fieldAnswer.answer as Answer[]);
            } else {
              value.push(fieldAnswer.answer);
            }
        });
      } else {
        const observationValues: Answer[] = getSubObservationValuesFromAudit(this.audit, this.auditFormSchema, field_name);
        value.push(...observationValues);
      }
    } else {
      // Use value from the same subobservations
      const v = this.form.controls[field_name].value;
      if (Array.isArray(v)) value.push(...v);
      else value.push(v);
    }
    return value;
  }

  private get remoteDefaultValueMatchFieldsData (): any {
    const matchFieldsData: any = {};
    this.remoteDefaultValueMatchFields.map((field_name: string) => {
      const matchFieldValue = this.getMatchFieldValue(field_name);
      if (!matchFieldValue || (Array.isArray(matchFieldValue) && matchFieldValue.filter(x => x !== null).length === 0)) return;
      matchFieldsData[field_name] = matchFieldValue;
    });
    return matchFieldsData;
  }

  private updateRemoteDefaultValue (resetDefaultOnChange: boolean = false) {
    if (!this.isRemoteDefaultValueEnabled) return;
    if (!this.form.controls[this.field.field_name].pristine) return;

    const matchFieldsData = this.remoteDefaultValueMatchFieldsData;
    // If any match field value is null or empty don't fetch default
    if (Object.keys(this.remoteDefaultValueMatchFieldsData).length !== this.remoteDefaultValueMatchFields.length) {
      this.formField.setValue(null);
      return;
    }

    const subscription = this.apiService.fetchFieldDefaultValues(this.field.id, matchFieldsData).pipe(
      debounceTime(250),
      tap((values: Answer[]) => {
        const currentValue = this.getMatchFieldValue(this.field.field_name);
        if (values.length > 0 && values[0] !== currentValue) {
          this.formField.setValue(values[0], {emitEvent: false});
        } else if (resetDefaultOnChange) {
          this.formField.setValue(null);
        }
        return values;
      }),
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  private updateDefaultValue() {
    if (this.remoteDefaultValueMatchFields.length === 0) return;
    this.updateRemoteDefaultValue(true);
  }

  private updateConditionalValue() {
    const conditionalValue = getConditionalValue(this.field, this.form, this.audit, this.auditFormSchema,
      this.accordionAnswers, this.hardcodedData, this.isAccordionSubform, this.setValuePlaceholders);
    if (conditionalValue !== undefined) {
      setTimeout(() => {
        this.formField.setValue(conditionalValue, {emitEvent: false});
      });
    }
  }

  private setDefaultValue () {
    const conditionalDefaultValue = getDefaultConditionalValue(this.field, this.form, this.audit, this.auditFormSchema,
      this.accordionAnswers, this.hardcodedData, this.isAccordionSubform, this.setValuePlaceholders);
    if (conditionalDefaultValue !== undefined) {
      this.formField.setValue(conditionalDefaultValue);
    } else {
      this.updateRemoteDefaultValue();
    }
  }

  public clearField() {
    this.setDefaultValue();
    this.updateValidators();
  }

  private resolveFieldImage(image: string | null): Observable<string> {
    if (image !== null && image !== undefined) {
      if (image[0] === '/') return this.apiService.buildUrl(image);
      else return of(this.domSanitizer.bypassSecurityTrustResourceUrl(image) as string);
    } else return of('');
  }

  async init () {
    this.complianceCalculator = new ComplianceCalculator(this.auditFormSchema);
    this.isAccordionSubform = this.auditForm.config.form_layout === LayoutType.Accordion;
    if (this.isAccordionSubform) {
      const accordionAnswersSubscription = this.auditService.accordionAnswers$.subscribe((fieldAnswers: AccordionFieldAnswer[]) => {
        fieldAnswers.forEach((fieldAnswer: AccordionFieldAnswer) => {
          this.field.conditions.forEach((condition: Condition) => {
            if (condition.field_name !== fieldAnswer.field_name) return;
            if (
              condition.set_value &&
              evaluateConditionHasValue(condition, fieldAnswer.answer) &&
              this.form.controls[this.field.field_name].pristine
            ) {
              this.form.controls[this.field.field_name].setValue(getSetValue(condition.set_value, this.setValuePlaceholders));
            }
          });
        });
        this.accordionAnswers = fieldAnswers;
        this.cdr.detectChanges();
      });
      this.subscriptions.push(accordionAnswersSubscription);
      this.accordionAnswers = this.auditService.getCachedAccordionAnswers();
      this.updateAccordionAnswersSubject(this.formField.value);
    }

    // initialize field image
    const fieldImage = this.resolveFieldImage(
      getFieldImage(
        this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform
      )
    ).pipe(
      tap((value) => {
        this.fieldImage = value || this.field.image_base64 || '';
      })
    ).subscribe();
    this.subscriptions.push(fieldImage);

    /*
    * Set default value on component load when a condition with has_value as None exists
    * */
    if (this.form.pristine) this.clearField();

    const settingsSubscription = this.settingsService.readSettings().subscribe(settings => {
      this.settings = settings;
      if (this.canAutoCycle) {
        const choice = this.autoCycleChoices[0];
        const fieldValue = choice !== null ? choice.value : null;
        this.form.controls[this.field.field_name].setValue(fieldValue);
      } else if (this.canAutoSelect(settings)) {
        const numObservations: number = this.audit.auditSession.observations.length;
        if (numObservations > 0) {
          const lastObservation = this.audit.auditSession.observations[numObservations - 1];
          let fieldValue = this.auditService.getFieldValue(this.field.field_name, lastObservation, this.auditFormSchema);
          if (fieldValue !== null) {
            if (fieldValue instanceof Array) fieldValue = fieldValue[fieldValue.length - 1] as Answer;
            this.form.controls[this.field.field_name].setValue(fieldValue);
          }
        }
      }
    });
    this.subscriptions.push(settingsSubscription);

    this.filteredChoices = this.formField.valueChanges.pipe(
      startWith(''),
      map((value: string | number): string => typeof value === 'number' ? value.toString() : value),
      tap((value: string) => {
        // Update form field value by label when choice is entered manually
        const choice = this.choices.find((c) => c.label === value);
        if (choice) {
          this.formField.setValue(choice.label, {
            emitEvent: false,
          });
        }
      }),
      map((value: string) => this.filterChoices(value)),
    );

    this.filteredRemoteFormChoices = this.formField.valueChanges.pipe(
      debounceTime(500),
      startWith(this.formField.value || ''),
      mergeMap((value: string) => {
        if (!this.field || !value) return of([]);
        return this.apiService.fetchRemoteFormModelFieldChoices(this.field.id, value).pipe(
          tap((choices) => {
            const currentValue = this.formField.value;
            this.remoteFormChoices = choices;
            // Refresh field value to fetch choice label
            if (currentValue && choices.map(x => x.label).includes(currentValue)) {
              this.formField.setValue(this.formField.value, {
                emitEvent: false,
              });
            }
            return choices;
          })
        );
      })
    );
    this.filteredMultiSelectChoices = this.multiChoiceFormControl.valueChanges.pipe(
      startWith(''),
      map((value: string | Choice) => {
        return this.filterChoices(typeof value === 'string' ? value : '');
      }),
    );

    this.limitModelFieldChoices();
    this.preselectModelFieldChoice();
    this.updateConditionalValue();
    this.updateDefaultValue();

    if (this.form.controls['comment-' + this.field.field_name].value) {
      this.showCommmentBox = true;
    }
    /**
     * Prevents autocomplete search term from saving an empty string as the value
     */
    const autocompleteSubscription = this.formField.valueChanges.subscribe((value: string) => {
      if (this.useAutocompleteWidget && value === '') {
        this.resetField();
      }
      this.updateAccordionAnswersSubject(value);
    });

    const formChanges = this.form.valueChanges.pipe(
      map(() => getFieldImage(
        this.field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.hardcodedData, this.isAccordionSubform
      )),
      mergeMap((image: string | null) => this.resolveFieldImage(image)),
      tap(image => {
        this.fieldImage = image;
        this.cdr.detectChanges();
      }),
      tap(() => this.updateConditionalValue()),
      tap(() => this.updateValidators()),
    ).subscribe();
    this.subscriptions.push(formChanges);

    if (this.remoteDefaultValueMatchFields.length > 0) {
      const formChangesUpdateDefaultValue = (
        merge(
          ...this.remoteDefaultValueMatchFields.map((f) => (this.form.get(f) as FormControl).valueChanges)
        ).pipe(
          debounceTime(250),
          tap(() => this.updateDefaultValue())
        ).subscribe());

      this.subscriptions.push(formChangesUpdateDefaultValue);
    }

    this.subscriptions.push(autocompleteSubscription);
    this.issue_label = this.auditService.getIssueLabel(this.auditForm);
    this.issue_label_plural = this.auditService.getIssueLabel(this.auditForm, true);
    if (this.editing) this.formField.markAsTouched();
  }

  async ngOnInit () {
    try {
      await this.init();
    } catch (error) {
      console.error(this.field.field_name, error);
    }
  }

  public radioChangeHandler($event: MatRadioChange) {
    this.updateAccordionAnswersSubject($event.value);
  }

  private updateAccordionAnswersSubject(value: string) {
      if (this.isAccordionSubform) {
        this.auditService.addAccordionAnswer({
          field_name: this.field.field_name,
          accordion_index: this.accordionIndex,
          answer: value,
        });
      }
  }

  private canAutoSelect(settings: Settings): boolean {
    if (this.auditForm.observation_model === CUSTOM_OBSERVATION_NAME) return this.field.autoselect;
    return this.auditForm.config.autoselect && this.field.widget === this.widgetTypes.SELECT;
  }

  public get canAutoCycle(): boolean {
    return (
      this.auditForm.observation_model === CUSTOM_OBSERVATION_NAME &&
      this.auditForm.config.auto_cycle_field === this.field.field_name
    );
  }

  private filterChoices(value: string): Choice[] {
    if (!this.field) return [];
    const choices = this.choices;
    if (value == null || value.length === 0) return choices;
    else {
      value = value.toLowerCase();
      return choices.filter((choice: Choice) => choice.label.toLowerCase().indexOf(value) !== -1);
    }
  }

  private limitModelFieldChoices() {
    if (!this.field) return;
    const choices = this.field.choices;
    if (this.field.widget === this.widgetTypes.MODEL_SELECT && this.field.model) {
      if (this.field.model.model === INSTITUTION) {
        this.field.choices = choices.filter((choice: Choice) => this.institutionService.getInstitutionOrNull(parseInt(choice.value, 10)));
      } else if (this.field.model.model === ROOM && this.audit.wardId) {
        this.institutionService.getWard(this.audit.wardId).subscribe((ward: Ward) => {
          const roomIds: number[] = ward.rooms.filter((room: Room) => room.hasOwnProperty('id') && Boolean(room.id))
            .map(room => room.id) as number[];
          this.field.choices = choices.filter((choice: Choice) => roomIds.indexOf(parseInt(choice.value, 10)) >= 0);
        });
      }
    }
    this.field.choices = choices;
  }

  private preselectModelFieldChoice(): void {
    if (!this.field) return;
    if (this.field.widget === this.widgetTypes.MODEL_SELECT && this.field.model) {
      if (this.field.model.model === INSTITUTION) {
        if (!this.form.controls[this.field.field_name].value) {
          this.institutionService.getWardData(this.audit.wardId).subscribe((wardData: [Institution, Department, Ward]) => {
            this.form.controls[this.field.field_name].setValue(wardData[0].id);
          });
        }
      }
    }
  }

  public displayChoiceGetter() {
    return (selectedValue: string) => this.labelFromValue(selectedValue);
  }

  public labelFromValue(value: string | null): string {
    if (value === null) return '';
    const result = this.choices.find((c) => c.value === value);
    if (result) return result.label;
    else return '';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public get errorMessages(): string[] {
    const fieldErrors = this.formField.errors || {};
    return createErrorMessages(this.field.validators || [], fieldErrors, this.translateService);
  }

  public resetField() {
    this.form.controls[this.field.field_name].reset();
  }

  public toggleCommentBox() {
    if (this.showCommmentBox) {
      this.form.controls['comment-' + this.field.field_name].reset();
    }
    this.showCommmentBox = !this.showCommmentBox;
  }

  multiChoicesSelected(): string[] {
    return this.form.controls[this.field.field_name].value;
  }

  multiChoiceSelected(event: MatAutocompleteSelectedEvent): void {
    const value = this.labelToValue(event.option.viewValue);
    let selected: string[] | null = this.form.controls[this.field.field_name].value;
    selected = selected ? selected : [];
    const index = selected.indexOf(value);
    if (index >= 0) {
      this.form.controls[this.field.field_name].value.splice(index, 1);
    } else {
      this.form.controls[this.field.field_name].setValue(
        selected.concat([value])
      );
      this.choiceInput.nativeElement.value = '';
      this.multiChoiceFormControl.setValue(null);
    }
  }

  multiChoiceIsSelected(value: string): boolean {
    const selected = this.form.controls[this.field.field_name].value;
    if (selected) {
      const index = this.form.controls[this.field.field_name].value.indexOf(value);
      return index >= 0;
    }
    return false;
  }

  removeMultiChoice(value: string): void {
    const index = this.form.controls[this.field.field_name].value.indexOf(value);
    if (index >= 0) {
      this.form.controls[this.field.field_name].value.splice(index, 1);
    }
  }

  private labelToValue(label: string): string {
    const choice = this.choices.find((c) => c.label === label);
    return choice ? choice.value : '';
  }

  public toggleQuestionImage() {
    this.questionImageSize = !this.questionImageSize;
  }
}
