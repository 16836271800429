var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AuditForm, LayoutType } from '../api/models/audit-form';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseLoginRequiredComponent } from '../base';
import { AccountService } from '../accounts.service';
import { AuditFormService } from '../audit-form.service';
import { Location } from '@angular/common';
import { AuditService } from './audit.service';
import { MatDialog, MatSidenav, MatSnackBar } from '@angular/material';
import { Observation } from '../api/models/observation';
import { ObservationComponent } from './observation/observation.component';
import { AnalyticsService } from '../analytics.service';
import { InstitutionService } from '../institution.service';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { SnackbarData } from '../custom-snackbar/snackbar-data';
import { TranslateService } from '@ngx-translate/core';
import { fileTypeImage, isNullOrUndefined, openUrl } from '../utils/misc';
import { NGXLogger } from 'ngx-logger';
import { MenuItem } from '../menu/menu-item';
import { InformationService } from '../information.service';
import { of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ModalAlertData } from '../alert/modal-alert-data';
import { ModalService } from '../alert/service/modal.service';
import { AlertComponent, AlertType } from '../alert/alert.component';
import { BackupService } from '../backup.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { ModelNameType } from '../api/models/analytics';
import { ApiService } from '../api/api.service';
var DocumentMenuItem = /** @class */ (function (_super) {
    __extends(DocumentMenuItem, _super);
    function DocumentMenuItem(document, action, visible, icon, badge) {
        if (visible === void 0) { visible = true; }
        if (icon === void 0) { icon = null; }
        if (badge === void 0) { badge = of(null); }
        var _this = _super.call(this, document.name, action, visible, icon, badge) || this;
        _this.badge = badge;
        _this.icon = fileTypeImage(document.file_type);
        _this.document = document;
        return _this;
    }
    return DocumentMenuItem;
}(MenuItem));
var AuditFormComponent = /** @class */ (function (_super) {
    __extends(AuditFormComponent, _super);
    function AuditFormComponent(router, accountService, translateService, location, route, auditFormService, auditService, analytics, snackBar, institutionService, informationService, dialog, logger, apiService, domSanitizer, matIconRegistry, modalService, backup) {
        var _this = _super.call(this, router, route, accountService, logger) || this;
        _this.translateService = translateService;
        _this.location = location;
        _this.route = route;
        _this.auditFormService = auditFormService;
        _this.auditService = auditService;
        _this.analytics = analytics;
        _this.snackBar = snackBar;
        _this.institutionService = institutionService;
        _this.informationService = informationService;
        _this.dialog = dialog;
        _this.apiService = apiService;
        _this.domSanitizer = domSanitizer;
        _this.matIconRegistry = matIconRegistry;
        _this.modalService = modalService;
        _this.backup = backup;
        /**
         * Subform (if selected) or null if audit has no subforms
         */
        _this.subform = null;
        _this.observationId = null;
        _this.fieldErrors = null;
        _this.infoItems = [];
        _this.documentItems = [];
        // remembers that user clicked save so it can safely navigate away
        _this.saved = false;
        // saves "next button" loading value, when true a loading spinner will appear inside button
        _this.isNextLoading = false;
        return _this;
    }
    AuditFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.matIconRegistry.addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/info.svg'));
        if (this.accountService.isAuthenticated()) {
            this.route.params.subscribe(function (params) {
                var observationId = params['observation_id'] || null;
                _this.loadForm(+params['audit_id'], params['subform_name'] || null, observationId == null ? null : +observationId);
            }, function (error) {
                _this.logger.error(error);
            });
            this.auditFormIdSubscription = this.auditService.auditFormId$.subscribe(function (auditId) {
                if (auditId === null) {
                    _this.infoItems = [];
                }
                else {
                    _this.loadInformationItems(auditId);
                }
            });
        }
    };
    AuditFormComponent.prototype.getErrors = function () {
        if (isNullOrUndefined(this.fieldErrors))
            return null;
        if (this.subform) {
            // @ts-ignore
            return this.fieldErrors[this.subform.name] || null;
        }
        else {
            return this.fieldErrors;
        }
    };
    AuditFormComponent.prototype.isAccordionLayout = function () {
        return this.auditFormService.getFormLayoutType(this.auditForm) === LayoutType.Accordion;
    };
    Object.defineProperty(AuditFormComponent.prototype, "isAutoCycleComplete", {
        get: function () {
            return this.auditService.getAutoCycleComplete(this.audit, this.auditForm, this.schema);
        },
        enumerable: true,
        configurable: true
    });
    AuditFormComponent.prototype.loadInformationItems = function (auditFormId) {
        var _this = this;
        this.informationService.getInformations(auditFormId).subscribe(function (informations) {
            _this.infoItems = informations.map(function (information) { return new MenuItem(information.title, function () {
                _this.analytics.trackButtonClick('Information Item');
                _this.analytics.trackAction('View Information Item');
                _this.analytics.trackModelAction(ModelNameType.Information, information.id);
                var doc = new DOMParser().parseFromString(information.content, 'text/html');
                if (doc.documentElement.textContent) {
                    // tslint:disable-next-line:max-line-length
                    _this.dialog.open(AlertComponent, { data: new ModalAlertData(of(information.title), of(_this.domSanitizer.bypassSecurityTrustHtml(information.content)), AlertType.INFO, _this.modalService.btnOK()) }).afterClosed();
                }
            }); });
        });
    };
    AuditFormComponent.prototype.loadDocumentItems = function () {
        var _this = this;
        var documents = this.schema.documents || [];
        this.documentItems = documents.map(function (document) { return new DocumentMenuItem(document, function () {
            if (_this.apiService.networkStatus) {
                openUrl(document.url);
            }
            else {
                _this.dialog.open(AlertComponent, {
                    data: new ModalAlertData(_this.translateService.get('audit-form.documents.no-internet-error-title'), _this.translateService.get('audit-form.documents.no-internet-error-text'), AlertType.WARNING, _this.modalService.btnOK())
                }).afterClosed();
            }
        }); });
    };
    AuditFormComponent.prototype.loadForm = function (auditId, subformName, observationId) {
        var _this = this;
        this.observationId = observationId;
        this.auditService.setAuditId(auditId);
        this.auditService.getAuditWithFormSchema(auditId).subscribe(function (result) {
            _this.audit = result[0], _this.auditForm = result[1], _this.schema = result[2];
            _this.loadDocumentItems();
            if (subformName == null) {
                if (_this.auditFormService.hasSubforms(_this.schema) && !_this.isAccordionLayout()) {
                    // this form has subform but no subform is specified
                    // navigate to subform selection
                    _this.router.navigate(['select-form', auditId]);
                    return;
                }
            }
            else {
                _this.subform = _this.schema.sub_forms.find(function (subform) { return subform.name === subformName; }) || null;
                if (_this.subform === null)
                    throw Error("Cannot find subform " + subformName);
            }
            if (observationId === null) {
                _this.observation = _this.auditService.createObservation(_this.audit, _this.schema);
            }
            else {
                _this.observation = _this.audit.auditSession.observations[observationId];
            }
            if (_this.observationId !== null && _this.audit.errors !== null && _this.audit.errors.observations) {
                _this.fieldErrors = _this.audit.errors.observations[_this.observationId];
            }
            _this.institutionService.getWard(_this.audit.wardId).subscribe(function (ward) { return _this.ward = ward; });
        }, function (error) {
            _this.logger.error(error);
            _this.snackBar.open(_this.translateService.instant('audit-form.audit-load-fail'), undefined, {
                duration: 3000,
            });
            _this.router.navigate(['draft-audits']);
        });
    };
    /**
     * Returns sub-observation if the subForm was passed in, else returns observation
     * */
    AuditFormComponent.prototype.getSubObservation = function () {
        if (this.subform == null) {
            return this.observation;
        }
        else if (this.observation[this.subform.name] === undefined) {
            return this.observation[this.subform.name] = this.auditService.createSubObservation();
        }
        else {
            return this.observation[this.subform.name];
        }
    };
    AuditFormComponent.prototype.onClearClicked = function () {
        this.analytics.trackButtonClick('Clear');
        this.analytics.trackAction('Audit form cleared');
        if (confirm(this.translateService.instant('audit-form.clear-data-prompt'))) {
            this.resetForm();
            this.observation = this.auditService.createObservation(this.audit, this.schema);
            this.logger.debug('Data cleared');
        }
    };
    AuditFormComponent.prototype.resetForm = function () {
        this.observationComponent.clearForm();
    };
    Object.defineProperty(AuditFormComponent.prototype, "editing", {
        /**
         * whether observation is being edited
         * (as opposed to creating new observation)
         */
        get: function () {
            return this.audit.auditSession.observations.includes(this.observation);
        },
        enumerable: true,
        configurable: true
    });
    AuditFormComponent.prototype.onBackClicked = function () {
        var _this = this;
        this.analytics.trackButtonClick('Back');
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(function () {
            if (_this.observationNumber <= 0 && _this.subobservationNumber < 0) {
                // go to start page if (first subform of) first observation
                _this.router.navigateByUrl('/');
            }
            else if (_this.subobservationNumber >= 0) {
                // subform selection page if subform
                _this.router.navigate(['select-form', _this.audit.id]);
            }
            else {
                // Previous observation
                _this.router.navigate(['audit-form', _this.audit.id, 'edit', _this.observationNumber - 1]);
            }
        });
    };
    Object.defineProperty(AuditFormComponent.prototype, "observationNumber", {
        get: function () {
            var number = this.audit.auditSession.observations.indexOf(this.observation);
            return number >= 0 ? number : -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuditFormComponent.prototype, "subobservationNumber", {
        get: function () {
            if (this.subform !== null) {
                var number = this.schema.sub_forms.indexOf(this.subform);
                return number >= 0 ? number : -1;
            }
            return -1;
        },
        enumerable: true,
        configurable: true
    });
    AuditFormComponent.prototype.doSave = function (validateForm) {
        var _this = this;
        this.isNextLoading = true;
        return this.observationComponent.onSave(validateForm, undefined).pipe(mergeMap(function () {
            _this.isNextLoading = false;
            _this.save(validateForm);
            return of(true);
        }), catchError(function (e) {
            _this.isNextLoading = false;
            _this.logger.error(e);
            _this.snackBar.openFromComponent(CustomSnackbarComponent, {
                duration: 3000,
                data: new SnackbarData(e, 'warning'),
            });
            return of(false);
        }));
    };
    AuditFormComponent.prototype.save = function (validateForm) {
        var _this = this;
        // collect data from forms to models
        var audit = this.audit;
        if (!this.editing) {
            // add observation to the audit if it was just newly created
            audit.auditSession.observations.push(this.observation);
        }
        this.auditService.saveAudit(audit).subscribe(function () {
            _this.saved = true;
            // update current audit to trigger counter update in toolbar
            _this.auditService.setAuditId(_this.audit.id);
            _this.snackBar.openFromComponent(CustomSnackbarComponent, {
                duration: 3000,
                data: new SnackbarData(_this.translateService.instant('audit-form.observation-saved'), 'check'),
            });
            if (validateForm) {
                _this.observation = _this.auditService.createObservation(audit, _this.schema);
                // noinspection PointlessBooleanExpressionJS
                if (_this.observationId !== null || _this.auditForm.config.save_next_observation === false || _this.isAutoCycleComplete) {
                    // observation was edited, go back to list of observations
                    _this.router.navigate(['audit-preview', _this.audit.id]);
                }
                else if (_this.subform === null) {
                    // audit has no subforms, redirect back to the same page to start new observation
                    _this.router.navigate(['select-form', _this.audit.id]);
                }
                else {
                    // audit has subforms, redirect to subform selection page
                    _this.router.navigate(['select-form', _this.audit.id]);
                }
            }
        });
    };
    AuditFormComponent.prototype.onProgressSave = function () {
        var _this = this;
        this.analytics.trackButtonClick('Save Progress');
        this.analytics.trackAction('Audit save draft');
        this.doSave(false).pipe(mergeMap(function () { return _this.cloudSave(); })).subscribe();
    };
    AuditFormComponent.prototype.cloudSave = function () {
        var _this = this;
        return this.backup.backup(this.audit).pipe(tap(function (saved) { return _this.logger.info('Audit progress saved to cloud', saved); }));
    };
    AuditFormComponent.prototype.onSave = function () {
        this.analytics.trackButtonClick('Save');
        this.analytics.trackAction('Audit save');
        this.doSave(true).subscribe();
    };
    /**
     * Checks whether user has input data
     * @return {boolean}
     */
    AuditFormComponent.prototype.isFormDirty = function () {
        return !this.saved && this.observationComponent !== undefined && this.observationComponent.isFormDirty();
    };
    AuditFormComponent.prototype.onDeleteClicked = function () {
        var _this = this;
        if (confirm(this.translateService.instant('audit-form.observation-delete-prompt'))) {
            this.audit.auditSession.observations = this.audit.auditSession.observations.filter(function (observation) { return observation !== _this.observation; });
            this.auditService.saveAudit(this.audit).subscribe(function () {
                _this.snackBar.openFromComponent(CustomSnackbarComponent, {
                    data: new SnackbarData(_this.translateService.instant('audit-form.observation-deleted'), 'check'),
                    duration: 3000,
                });
                _this.router.navigate(['audit-preview', _this.audit.id]);
            });
        }
    };
    return AuditFormComponent;
}(BaseLoginRequiredComponent));
export { AuditFormComponent };
