import {Injectable} from '@angular/core';
import {StorageService} from '../storage.service';
import {Observable, of} from 'rxjs';
import {CommonIssue} from '../api/models/common-issue';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {AuditForm, CUSTOM_OBSERVATION_NAME} from '../api/models/audit-form';
import {AuditFormSchema, SubFormSchema} from '../api/models/audit-form-schema';
import {Issue, IssueHandler, IssuePhoto} from '../api/models/issue';
import {Audit} from '../audit-form/audit';
import {Observation, SubObservation} from '../api/models/observation';
import {LastModifiedService} from '../last-modified.service';

export const MAX_IMAGE_SIZE = 5 * 1024 * 1024;

@Injectable()
export class QipService {

  constructor(private storageService: StorageService, private lastModifiedService: LastModifiedService) {
  }

  private createCommonIssuesKey(auditFormId: number): string {
    return `audit-form-${auditFormId}-common-issues`;
  }

  private createIssueHandlersKey(auditFormId: number): string {
    return `audit-form-${auditFormId}-issue-handlers`;
  }

  private createCommonIssuesLastModifiedKey(auditFormId: number): string {
    return this.lastModifiedService.getLastModifiedKey(`${auditFormId}-common-issues`);
  }

  public areCommonIssuesDownloaded(auditFormId: number): Observable<boolean> {
    return this.storageService.hasItem(this.createCommonIssuesKey(auditFormId));
  }

  public storeCommonIssues(auditFormId: number, commonIssues: CommonIssue[], lastModified: string | null): Observable<boolean> {
    return this.storageService.setItem(this.createCommonIssuesKey(auditFormId), commonIssues).pipe(
      tap(() => {
        if (lastModified === null) this.storageService.removeString(this.createCommonIssuesLastModifiedKey(auditFormId));
        else this.storageService.setString(this.createCommonIssuesLastModifiedKey(auditFormId), lastModified);
      }),
    );
  }

  public getCommonIssuesLastModifiedDate(auditFormId: number): Observable<string | null> {
    return this.areCommonIssuesDownloaded(auditFormId).pipe(
      map((exists: boolean) => {
        if (exists) return this.storageService.getString(this.createCommonIssuesLastModifiedKey(auditFormId));
        else return null;
      }),
    );
  }

  public getCommonIssues(auditForm: AuditForm, subForm: SubFormSchema | null): Observable<CommonIssue[]> {
    return this.storageService.getItem<CommonIssue[]>(this.createCommonIssuesKey(auditForm.id)).pipe(
      map((commonIssues) => commonIssues.filter((commonIssue) => {
        if (subForm !== null) {
          if (commonIssue.sub_observation !== null) {
            return commonIssue.sub_observation.name === subForm.name;
          }
          /**
           * if custom observation then check for content type id else check model name
           */
          if (auditForm.observation_model === CUSTOM_OBSERVATION_NAME) {
              return (commonIssue.content_type.model === auditForm.observation_content_type.model);
          } else {
            return (commonIssue.content_type.model === subForm.name ||
              commonIssue.content_type.model === auditForm.observation_content_type.model);
          }
        }
        return true;
      })),
    );
  }

  public getCommonIssueComments(auditForm: AuditForm, subForm: SubFormSchema | null): Observable<string[]> {
    return this.getCommonIssues(auditForm, subForm).pipe(
      map((commonIssues: CommonIssue[]) => commonIssues.map((value) => value.comment)),
    );
  }

  public checkIssueHandlersExists(auditFormId: number): Observable<boolean> {
    return this.storageService.getItem<IssueHandler[]>(this.createIssueHandlersKey(auditFormId)).pipe(
      mergeMap(() => of(true)),
      catchError((e) => {
        return of(false);
      })
    );
  }

  public saveIssueHandlers(issueHandlers: IssueHandler[], auditFormId: number): Observable<boolean> {
    return this.storageService.setItem(this.createIssueHandlersKey(auditFormId), issueHandlers);
  }

  public getIssueHandlers(institutionId: number | null, auditFormId: number | null): Observable<IssueHandler[]> {
    if (auditFormId == null) return of([]);
    return this.storageService.getItem<IssueHandler[]>(this.createIssueHandlersKey(auditFormId)).pipe(
      map((issueHandlers: IssueHandler[]): IssueHandler[] => {
        if (institutionId !== null) issueHandlers = issueHandlers.filter((handler: IssueHandler) => {
          if (handler.institutions) return handler.institutions.indexOf(institutionId) > -1;
          else return handler.institution === institutionId;
        });
        return issueHandlers;
      }),
      catchError((e) => {
        console.error(e);
        return of([]);
      })
    );
  }

  private getIssuePhotosKey(audit: Audit, issue: Issue): string {
    if (!issue.client_key) this.generateIssueKey(issue);
    return this.getAuditPhotoKeyPrefix(audit) + issue.client_key;
  }

  private generateIssueKey(issue: Issue) {
    issue.client_key = Math.random().toString(36).substring(2);
  }

  public getIssuePhotos(audit: Audit, issue: Issue): Observable<IssuePhoto[]> {
    const key = this.getIssuePhotosKey(audit, issue);
    return this.storageService.getItemOrDefault(key, []);
  }

  public getAuditPhotoKeyPrefix(audit: Audit): string {
    return `audit-${audit.id}-issue-photos-`;
  }

  /**
   * Emits list of keys for photos in audit
   */
  public getAuditPhotoKeys(audit: Audit): Observable<string[]> {
    const keyStartWith = this.getAuditPhotoKeyPrefix(audit);
    return this.storageService.getKeys().pipe(
      map((keys: string[]) => keys.filter((key: string) => key.startsWith(keyStartWith))),
    );
  }

  /**
   * Sets issue photos, but removes any existing photos if provided array is empty
   */
  public setIssuePhotos(audit: Audit, issue: Issue, photos: IssuePhoto[]): Observable<boolean> {
    if (!issue.client_key) this.generateIssueKey(issue);
    const key = this.getIssuePhotosKey(audit, issue);
    if (photos.length === 0) return this.removeIssuePhotos(audit, issue);
    return this.storageService.setItem(key, photos);
  }

  public removeIssuePhotos(audit: Audit, issue: Issue): Observable<boolean> {
    if (!issue.client_key) return of(true);
    const key = this.getIssuePhotosKey(audit, issue);
    return this.storageService.removeItem(key);
  }

  public addIssuePhoto(audit: Audit, issue: Issue, photo: IssuePhoto): Observable<boolean> {
    if (!issue.client_key) this.generateIssueKey(issue);
    return this.getIssuePhotos(audit, issue).pipe(
      tap(photos => photos.push(photo)),
      mergeMap((photos: IssuePhoto[]) => this.setIssuePhotos(audit, issue, photos)),
    );
  }

  public getAllIssuesForAudit(auditFormSchema: AuditFormSchema, observations: Observation[]): Issue[] {
    let allAuditIssues: Issue[] = [];

    /** Adds issues to a local flat list */
    const processIssues = (issues: Issue[]) => {
      allAuditIssues = allAuditIssues.concat(issues);
    };

    observations.forEach((observation) => {
      // add issues from current observation
      processIssues(observation.issues);
      // add issues from each subobservation
      auditFormSchema.sub_forms.forEach((subForm) => {
        if (!observation[subForm.name]) return;
        // add issues from each subobservation
        const subObservations: SubObservation[] = [];
        if (Array.isArray(observation[subForm.name])) subObservations.push(...<SubObservation[]>observation[subForm.name]);
        else subObservations.push(<SubObservation>observation[subForm.name]);

        subObservations
          .filter((subObservation) => subObservation.issues)
          .forEach(
            (subObservation: SubObservation) => processIssues(subObservation.issues),
          );
      });
    });
    return allAuditIssues;
  }
}
