<div class="container-body">
  <h1 class="title">{{'about.title' | translate}}</h1>

  <h2>{{'about.disclaimer-title' | translate}}</h2>
  {{ 'about.disclaimer-text' | translate }}
  <br>
  <br>
  {{'about.more-information' | translate}}
  <a href="https://megsupporttools.com" target="_blank">www.megsupporttools.com</a>
  <br>
  <br>
  {{'about.developed-by' | translate}}
  <br>
  <br>
  {{'about.contact-text' | translate}}
  <br>
  <a href="mailto:support@megit.com">support@megit.com</a>
  <br>
  <br>
  <h2>{{'about.application-name-title' | translate}}</h2>
  {{'app-name' | translate}}
  <br>
  <br>
  <h2>{{'about.privacy-policy-title' | translate}}</h2>
  <span style="unicode-bidi: plaintext;">
    {{ 'about.privacy-policy-text' | translate }}
    <a href="https://www.megsupporttools.com/privacy-policy" target="_blank">{{ 'about.privacy-policy-click-here' | translate }}</a>
  </span>
  <br>
  <br>
  <meg-app-version class="version-black"></meg-app-version>
</div>
