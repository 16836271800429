import {Injectable} from '@angular/core';
import {catchError, concatMap, map, mergeMap, tap} from 'rxjs/operators';
import {Institution} from '../api/models/institution';
import {forkJoin, Observable} from 'rxjs';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ApiService, HEADER_LAST_MODIFIED, STATUS_NOT_MODIFIED} from '../api/api.service';
import {AuditForm} from '../api/models/audit-form';
import {PaginatedResponse} from '../api/responses/paginated-response';
import {Information} from '../api/models/information';
import {InformationService} from '../information.service';
import {AuditFormService, DB_KEY_AUDIT_FORMS} from '../audit-form.service';
import {InstitutionService} from '../institution.service';
import {AuditFormSchema} from '../api/models/audit-form-schema';
import {CommonIssue} from '../api/models/common-issue';
import {QipService} from '../qip/qip.service';
import {Dashboard} from '../api/models/dashboard';
import {DashboardService} from '../charts/dashboard.service';
import {IssueHandler} from '../api/models/issue';
import {of} from 'rxjs';
import {LanguageService} from '../language.service';
import {Language} from '../api/models/language';
import {AuditFormUpdateResponse} from '../api/models/audit-form-update-response';
import {NGXLogger} from 'ngx-logger';
import {InsightsService} from '../insights/insights.service';
import {StorageService} from '../storage.service';

// defines how much of the overall progress each stage  is
// these must add up to 100.0 (%)
const PROGRESS_WEIGHT_DASHBOARDS = 1;
const PROGRESS_WEIGHT_LANGUAGES = 1;
const PROGRESS_WEIGHT_INSTITUTIONS = 2;
const PROGRESS_WEIGHT_INFORMATIONS = 2;
const PROGRESS_WEIGHT_ISSUE_HANDLERS = 4;
const PROGRESS_WEIGHT_AUDIT_COMMON_ISSUES = 10;
const PROGRESS_WEIGHT_AUDIT_FORMS = 20;
const PROGRESS_WEIGHT_AUDIT_SCHEMAS = 60;

/**
 * Service responsible for downloading data when user logs in
 */
@Injectable()
export class DownloadService {
  public progress = 0;
  // limit of how many audit forms user cna have access to and will automatically download when user logs in
  public schemaLimit = 30;

  constructor(private apiService: ApiService,
              private auditFormService: AuditFormService,
              private institutionService: InstitutionService,
              private qipService: QipService,
              private informationService: InformationService,
              private dashboardService: DashboardService,
              private languageService: LanguageService,
              private logger: NGXLogger,
              private insights: InsightsService,
              private storageService: StorageService) {
  }

  /**
   * Bundles all observables of each data loader together under a single observable
   * @returns {Observable<boolean[]>} observable returning when all data is finished loading
   */
  public loadData(): Observable<boolean[]> {
    this.progress = 0;
    this.insights.trackLongEvent('loading data');
    return forkJoin([
        this.loadInstitutions(),
        this.loadInformations(),
        this.loadDashboards(),
        this.loadLanguages(),
        this.loadAuditForms().pipe(
          concatMap((auditForms: AuditForm[]) => {
            let forms = of(auditForms);

            // if user has access to large number of forms, only download updates to forms already downloaded
            if (auditForms.length > this.schemaLimit) {
              this.logger.debug(`There are ${auditForms.length} audit forms. Only updating forms already downloaded`);
              forms = this.auditFormService.filterSavedSchemas(auditForms);
            }

            return forms.pipe(
              tap((downloadForms) => this.logger.debug(`Downloading ${downloadForms.length} form schemas`)),
              concatMap((downloadForms) => {
                return forkJoin(
                  [
                    this.downloadFormIssueHandlers(downloadForms.map(x => x.id)),
                    this.downloadFormSchemas(downloadForms),
                    this.downloadAllCommonIssues(downloadForms),
                  ]
                );
              }),
            );
          }),
          map((result) => true),
        ),
      ]
    ).pipe(
      tap(() => this.insights.trackLongEventStop('loading data')),
    );
  }

  /**
   * Downloads institutions and writes them to storage
   */
  private loadInstitutions(): Observable<boolean> {
    return this.apiService.fetchInstitutions().pipe(
      tap(() => this.progress += PROGRESS_WEIGHT_INSTITUTIONS),
      mergeMap((result: PaginatedResponse<Institution>) => this.institutionService.saveInstitutions(result.results)),
    );
  }

  /**
   * Downloads single audit form schema and writes its data to indexed db.
   * Also handles error and whether form is already at latest version
   * @param {AuditForm} form
   * @returns {Observable<boolean>}
   */
  private downloadFormSchema(form: AuditForm): Observable<boolean> {
    return this.auditFormService.getAuditFormSchemaLastModified(form.id).pipe(
      mergeMap((ifModifiedSince: string | null) => this.apiService.fetchAuditFormSchema(form.id, ifModifiedSince)
        .pipe(
          mergeMap((response: HttpResponse<AuditFormSchema>) => {
            // body is nullable. Unlikely to happen, but needs to be handled for type safety
            if (response.body === null) throw new Error(`Response body is null for audit form schema ${form.id}`);
            const lastModified: string | null = response.headers.get(HEADER_LAST_MODIFIED);
            return this.auditFormService.saveAuditFormSchema(response.body, lastModified);
          }, 2),
          catchError((downloadError: HttpErrorResponse) => {
            // 302 Not Modified will raise an error. Ignore this error if form is already downloaded
            // handle error by checking if form is already downloaded
            return this.auditFormService.isSchemaSaved(form.id).pipe(
              map((schemaSaved: boolean) => {
                if (schemaSaved) {
                  this.logger.info(`Form schema ${form.id} already up to date. Ignoring expected download error.`);
                  return true;
                } else throw downloadError;
              }),
            );
          }),
        )),
    );
  }

  private downloadFormSchemas(forms: AuditForm[]): Observable<boolean[]> {
    const numRequests = forms.length;
    if (numRequests === 0) return of([]);

    // create request for every audit form
    const requests: Observable<boolean>[] = forms.map(
      (form: AuditForm) => {
        return this.downloadFormSchema(form).pipe(
          tap(() => this.progress += PROGRESS_WEIGHT_AUDIT_SCHEMAS / numRequests),
        );
      }
    );
    return forkJoin(requests);
  }

  private downloadFormCommonIssues(form: AuditForm): Observable<boolean> {
    return this.qipService.getCommonIssuesLastModifiedDate(form.id).pipe(
      mergeMap((ifModifiedSince: string | null) => {
        return this.apiService.fetchCommonIssues(form.id, ifModifiedSince).pipe(
          mergeMap((response: HttpResponse<CommonIssue[]>) => {
            if (response.body === null) throw new Error(`Empty response returned for common issues of form ${form.id}`);
            const lastModified: string | null = response.headers.get(HEADER_LAST_MODIFIED);
            return this.qipService.storeCommonIssues(form.id, response.body, lastModified);
          }),
        );
      }),
      catchError((downloadError: HttpErrorResponse) => {
        // 302 Not Modified will raise an error. Ignore this error if common issues already downloaded
        return this.qipService.areCommonIssuesDownloaded(form.id).pipe(
          map((exist: boolean) => {
            if (exist) {
              this.logger.info(`Common issues ${form.id} already downloaded. Ignoring expected download error.`);
              return true;
            } else throw downloadError;
          }),
        );
      })
    );
  }

  private downloadAllCommonIssues(forms: AuditForm[]): Observable<[number, number]> {
    const qipForms = forms.filter((form: AuditForm) => form.config != null && form.config.enable_qip);
    if (qipForms.length === 0) return of(<[number, number]>[0, 0]);

    const requests: Observable<boolean>[] = qipForms.map((form: AuditForm) => this.downloadFormCommonIssues(form).pipe(
      tap(() => this.progress += PROGRESS_WEIGHT_AUDIT_COMMON_ISSUES / qipForms.length),
    ));

    return forkJoin(requests).pipe(
      map((results: boolean[]) => <[number, number]>[results.filter(result => result).length, qipForms.length]),
    );
  }

  /**
   * Loads audit forms, writes them to local storage and emits downloaded data
   */
  private loadAuditForms(): Observable<AuditForm[]> {
    return this.apiService.fetchAllAuditForms().pipe(
      mergeMap((forms: AuditForm[]) => this.auditFormService.saveAuditForms(forms).pipe(
        tap(() => this.progress += PROGRESS_WEIGHT_AUDIT_FORMS),
        map(() => forms),
      )),
    );
  }

  private loadInformations(): Observable<boolean> {
    return this.apiService.fetchInformations().pipe(
      tap(() => this.progress += PROGRESS_WEIGHT_INFORMATIONS),
      mergeMap((response: PaginatedResponse<Information>) => this.informationService.saveInformations(response.results)),
    );
  }

  private loadDashboards(): Observable<boolean> {
    return this.apiService.fetchDashboards().pipe(
      tap(() => this.progress += PROGRESS_WEIGHT_DASHBOARDS),
      mergeMap((response: PaginatedResponse<Dashboard>) => this.dashboardService.saveDashboards(response.results)),
    );
  }

  /**
   * Loads issue handlers, writes them to local storage and emits downloaded data
   */
  public downloadFormIssueHandlers(formIds: number[], newOnly: boolean = false): Observable<boolean> {
    const numRequests = formIds.length;
    if (formIds.length === 0) {
      this.progress += PROGRESS_WEIGHT_ISSUE_HANDLERS;
      return of(true);
    }

    // create request for every audit form
    const requests: Observable<IssueHandler[]>[] = formIds.map((formId: number) => {
      return this.qipService.checkIssueHandlersExists(formId).pipe(
        mergeMap((result) => {
          if (result && newOnly) return of([]);
          return this.apiService.fetchAuditFormIssueHandlers(formId).pipe(
            mergeMap((handlers: IssueHandler[]) => this.qipService.saveIssueHandlers(handlers, formId).pipe(
              map(() => handlers),
            )),
          );
        })
      );
    });

    return forkJoin(requests).pipe(
      map((results: IssueHandler[][]) => {
        this.progress += PROGRESS_WEIGHT_ISSUE_HANDLERS / numRequests;
        return true;
      }),
    );
  }

  private loadLanguages(): Observable<boolean> {
    return this.apiService.getLanguages().pipe(
      tap(() => this.progress += PROGRESS_WEIGHT_LANGUAGES),
      mergeMap((languages: Language[]) => this.languageService.saveLanguages(languages))
    );
  }

  public checkAuditFormUpdate(auditFormId: number): Observable<Boolean> {
    return this.auditFormService.getAuditFormSchemaLastModified(auditFormId).pipe(
      mergeMap((lastModifiedDate: string | null) => {
        return this.apiService.checkAuditFormUpdate(auditFormId, lastModifiedDate).pipe(
          map((response: HttpResponse<AuditFormUpdateResponse>) => response.status !== STATUS_NOT_MODIFIED),
          catchError((downloadError: HttpErrorResponse) => {
            return of(false);
          }),
        );
      })
    );
  }

  public downloadAuditForm(auditFormId: number, lastModifiedDate: string | null): Observable<boolean> {
    return this.apiService.fetchAuditForm(auditFormId, lastModifiedDate).pipe(
      mergeMap((auditForm: AuditForm) => forkJoin([
        this.auditFormService.updateAuditForm(auditForm),
        this.downloadFormIssueHandlers([auditForm.id]),
        this.downloadFormSchema(auditForm),
        this.downloadFormCommonIssues(auditForm),
      ]).pipe(
        map((results: boolean[]) => results.find(result => !result) === undefined ),
      ))
    );
  }

  /**
   * Gets specific audit forms with their schema from database.
   * Filters by institution if specified
   * @param {number[]} auditFormsIds ids to filter by, or all audit forms will be returned
   * @param {boolean} updateSchemas whether this will check for updates on form schema
   * @returns {Observable<[AuditForm, AuditFormSchema | null][]>}
   * */
  public getAuditFormsWithSchema(
    auditFormsIds: number[] = [], updateSchemas: boolean = false
  ): Observable<[AuditForm, AuditFormSchema | null][]> {
    let observable = this.storageService.getItem<AuditForm[]>(DB_KEY_AUDIT_FORMS);
    if (auditFormsIds !== []) {
      const idFilter = (form: AuditForm) => auditFormsIds.includes(form.id);
      observable = observable.pipe(
        map((value: AuditForm[]) => value.filter(idFilter))
      );
    }

    return observable.pipe(
      mergeMap(
        (forms: AuditForm[]) => {
          return forkJoin(
            ...forms.map((form) => this.auditFormService.getAuditFormWithSchema(form.id).pipe(
              catchError(() => {
                if (!updateSchemas) return [form, null];
                return this.downloadFormSchema(form).pipe(
                  mergeMap(() => this.auditFormService.getAuditFormWithSchema(form.id))
                );
              }),
            ))
          );
        }
      )
    );
  }
}
