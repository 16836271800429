import {Injectable} from '@angular/core';
import {ApiService} from '../../api/api.service';
import {Observable, of, from} from 'rxjs';
import {PaginatedResponse} from '../../api/responses/paginated-response';
import {
  Issue,
  IssueHandler,
  Room
} from '../../api/models/issue';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Institution} from '../../api/models/institution';
import {Department} from '../../api/models/department';
import {Ward} from '../../api/models/ward';
import {QipService} from '../qip.service';
import {InstitutionService} from '../../institution.service';
import {API_ISSUES} from '../../api/constants';
import {DB_KEY_QIP_FILTER_STATE, StorageService} from '../../storage.service';
import {IssueFilter} from './issue-filter/filter';
import {NGXLogger} from 'ngx-logger';
import {DownloadService} from '../../loading/download.service';

export interface IssueStatus {
  slug: string;
  label: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class IssueService {
  public pageSize = 100;

  constructor(private apiService: ApiService, private qipService: QipService, private institutionService: InstitutionService,
              private storageService: StorageService, private downloadService: DownloadService, private logger: NGXLogger) {
  }

  public getIssueHandlerName(handlerId: number | null, auditFormId: number | null, placeholder: string = '-'): Observable<string> {
    if (handlerId === null || auditFormId == null) return of(placeholder);
    else return this.qipService.getIssueHandlers(null, auditFormId).pipe(
      map((handlers: IssueHandler[]): IssueHandler | undefined => {
        return handlers.find((handler: IssueHandler): boolean => handler.id === handlerId);
      }),
      map((handler: IssueHandler | undefined): string => handler === undefined ? placeholder : handler.name)
    );
  }

  public getIssues(page: number = 0, issueHandlerId?: number, auditFormId?: number, wardId?: number,
                   statuses?: string[]): Observable<PaginatedResponse<Issue>> {
    return this.apiService.fetchIssues(page, this.pageSize, statuses, issueHandlerId, auditFormId, wardId).pipe(
      mergeMap((response) => {
        const formIds = Array.from(new Set(response.results
          .map((x) => x.audit_form)
          .filter((x): x is number => x !== undefined)
        ));
        // attempt to download form issue handlers if not already downloaded
        return from(this.downloadService.downloadFormIssueHandlers(formIds, true)).pipe(
          map(() => response)
        );
      })
    );
  }

  public getIssue(id: number): Observable<Issue> {
    return this.apiService.fetchIssueDetail(id);
  }

  public getIssueStatusData(issue: Issue): IssueStatus | null {
    return issue.status || null;
  }

  public getStatuses(issueId: number): Observable<IssueStatus[]> {
    return this.apiService.fetchStatuses(issueId);
  }
  /**
   * Creates a location string based off room.
   * @param forRoom input room, can be null
   * @param placeholder string returned if room is not found
   * @returns string representing location (e.g. "Room, ward"), or "-" if location isn't set or room isn't known
   */
  public getLocationLabel(forRoom: Room | null, placeholder: string = '-'): Observable<string> {
    const noLocation = of(placeholder);
    if (forRoom === null || forRoom.id === undefined) return noLocation;
    return this.institutionService.getRoomData(forRoom.id).pipe(
      map((location: [Institution, Department, Ward, Room]): string => {
        const [institution, department, ward, room] = location;
        return `${room.name}, ${ward.name}`;
      }),
      catchError((e) => {
        this.logger.warn('Cannot find room', forRoom);
        return noLocation;
      }),
    );
  }

  /**
   * Saves updated Issue object to the back-end
   * @param issue
   */
  public saveIssue(issue: Issue): Observable<Issue> {
    const url = `${API_ISSUES}${issue.id}/`;
    return this.apiService.patch(url, <Issue>{
      qipstatus: issue.qipstatus,
      handler: issue.handler,
      comment: issue.comment,
      actiontaken: issue.actiontaken,
    });
  }

  /**
   * Save filter options selected by user
   */
  public saveFilters(filter: IssueFilter): Observable<boolean> {
    return this.storageService.setItem(DB_KEY_QIP_FILTER_STATE, filter);
  }

  /**
   * Load last filter options selected by user
   */
  public loadFilters(): Observable<IssueFilter> {
    return this.storageService.getItemOrDefault(DB_KEY_QIP_FILTER_STATE, new IssueFilter());
  }
}
