import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {IssueService, IssueStatus} from './issue.service';
import {Observable} from 'rxjs';
import {Issue} from '../../api/models/issue';
import {PaginatedResponse} from '../../api/responses/paginated-response';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {IssueFilter} from './issue-filter/filter';
import {AccountService} from '../../accounts.service';
import {User} from '../../api/models/user';
import {BaseComponent} from '../../base.component';
import {of} from 'rxjs/internal/observable/of';
import {IssueFilterComponent} from './issue-filter/issue-filter.component';
import {validateSessionToken} from '../../api/utils';
import {ApiService} from '../../api/api.service';

interface ListItem {
  issue: Issue;
  location$: Observable<string>;
  handler$: Observable<string>;
  status: IssueStatus | null;
}

/**
 * A component that displays a list of issues from the back-end
 */
@Component({
  selector: 'meg-issue-list',
  templateUrl: './issue-list.component.html',
  styleUrls: [
    './issue-list.component.css',
    './issue.css',
  ]
})
export class IssueListComponent extends BaseComponent implements OnInit {
  public items: ListItem[];
  public error: any = null;
  @ViewChild(IssueFilterComponent)
  private filterComponent: IssueFilterComponent;

  constructor(private issueService: IssueService, private accountService: AccountService, injector: Injector, private api: ApiService) {
    super(injector);
  }

  ngOnInit() {
    validateSessionToken(this.api, this.translateService);
    this.subscriptions.push(this.filterComponent.filterChanges$.pipe(
      tap((filter: IssueFilter) => this.logger.debug('Filters update', filter)),
      tap(() => this.error = null),
      switchMap((filter: IssueFilter) => this.accountService.getUser().pipe(
        mergeMap((user: User) => {
          let issueHandlerId: number | undefined;
          let wardId: number | undefined;
          let auditFormId: number | undefined;
          if (filter) {
            if (filter.assigned_to_me && user.auditor) issueHandlerId = user.auditor.id;
            if (filter.audit_form) auditFormId = filter.audit_form.id;
            if (filter.ward) wardId = filter.ward.id;
          }
          return this.issueService.getIssues(0, issueHandlerId, auditFormId, wardId);
        }),
        map((result: PaginatedResponse<Issue>) => result.results),
      )),
      map((issues: Issue[]): ListItem[] => issues.map((issue: Issue): ListItem => {
        return {
          issue: issue,
          location$: this.issueService.getLocationLabel(issue.room),
          handler$: this.issueService.getIssueHandlerName(issue.handler, issue.audit_form || null),
          status: this.issueService.getIssueStatusData(issue),
        };
      })),
      catchError((error): Observable<ListItem[]> => {
        this.logger.error('Error while loading issues', error);
        this.error = error;
        return of([]);
      }),
      tap((result: ListItem[]) => this.items = result),
    ).subscribe());
  }
}
