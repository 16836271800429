import {Component, OnInit, ViewChild} from '@angular/core';
import {AuditForm, CUSTOM_OBSERVATION_NAME, LayoutType} from '../api/models/audit-form';
import {AuditFormSchema, SubFormSchema} from '../api/models/audit-form-schema';
import {Document} from '../api/models/docs';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseLoginRequiredComponent} from '../base';
import {AccountService} from '../accounts.service';
import {AuditFormService} from '../audit-form.service';
import {Location} from '@angular/common';
import {Audit} from './audit';
import {AuditService} from './audit.service';
import {MatDialog, MatSidenav, MatSnackBar} from '@angular/material';
import {Answer, Observation} from '../api/models/observation';
import {ObservationComponent} from './observation/observation.component';
import {AnalyticsService} from '../analytics.service';
import {Ward} from '../api/models/ward';
import {InstitutionService} from '../institution.service';
import {CustomSnackbarComponent} from '../custom-snackbar/custom-snackbar.component';
import {SnackbarData} from '../custom-snackbar/snackbar-data';
import {TranslateService} from '@ngx-translate/core';
import {Errors} from '../api/models/errors';
import {fileTypeImage, isNullOrUndefined, openUrl} from '../utils/misc';
import {NGXLogger} from 'ngx-logger';
import {Information} from '../api/models/information';
import {MenuItem} from '../menu/menu-item';
import {InformationService} from '../information.service';
import {Observable, of, Subscription} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {ModalAlertData} from '../alert/modal-alert-data';
import {ModalService} from '../alert/service/modal.service';
import {AlertComponent, AlertType} from '../alert/alert.component';
import {BackupService} from '../backup.service';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {ModelNameType} from '../api/models/analytics';
import {ApiService} from '../api/api.service';

class DocumentMenuItem extends MenuItem {
  document: Document;
  constructor(document: Document, action: () => void, visible = true, icon: string | null = null,
              public badge: Observable<string|null> = of(null)) {
    super(document.name, action, visible, icon, badge);
    this.icon = fileTypeImage(document.file_type);
    this.document = document;
  }
}

@Component({
  selector: 'meg-audit-form',
  templateUrl: './audit-form.component.html',
  styleUrls: ['./audit-form.component.css'],
  providers: [InformationService],
})
export class AuditFormComponent extends BaseLoginRequiredComponent implements OnInit {
  public auditForm: AuditForm;
  /**
   * Subform (if selected) or null if audit has no subforms
   */
  public subform: SubFormSchema | null = null;
  public schema: AuditFormSchema;
  public observation: Observation;
  public audit: Audit;
  public observationId: number | null = null;
  private fieldErrors: Errors | null = null;
  infoItems = [] as MenuItem[];
  documentItems = [] as DocumentMenuItem[];
  private auditFormIdSubscription: Subscription;
  @ViewChild('sideNav') sideNav: MatSidenav;
  @ViewChild(ObservationComponent) public observationComponent: ObservationComponent;
  public ward: Ward;
  // remembers that user clicked save so it can safely navigate away
  private saved = false;
  // saves "next button" loading value, when true a loading spinner will appear inside button
  public isNextLoading = false;

  constructor(router: Router, accountService: AccountService, private translateService: TranslateService, private location: Location,
              private route: ActivatedRoute, private auditFormService: AuditFormService, private auditService: AuditService,
              private analytics: AnalyticsService, private snackBar: MatSnackBar, private institutionService: InstitutionService,
              private informationService: InformationService, private dialog: MatDialog, logger: NGXLogger, private apiService: ApiService,
              private domSanitizer: DomSanitizer, private matIconRegistry: MatIconRegistry, private modalService: ModalService,
              private backup: BackupService) {
    super(router, route, accountService, logger);
  }

  ngOnInit() {
    super.ngOnInit();
    this.matIconRegistry.addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/info.svg'));
    if (this.accountService.isAuthenticated()) {
      this.route.params.subscribe(params => {
          const observationId: string | null = params['observation_id'] || null;
          this.loadForm(+params['audit_id'], params['subform_name'] || null, observationId == null ? null : +observationId);
        },
        error => {
          this.logger.error(error);
        }
      );
      this.auditFormIdSubscription = this.auditService.auditFormId$.subscribe(
        auditId => {
          if (auditId === null) {
            this.infoItems = [];
          } else {
            this.loadInformationItems(auditId);
          }
        },
      );
    }
  }

  public getErrors(): Errors | null {
    if (isNullOrUndefined(this.fieldErrors)) return null;
    if (this.subform) {
      // @ts-ignore
      return this.fieldErrors[this.subform.name] as Errors || null;
    } else {
      return this.fieldErrors;
    }
  }

  public isAccordionLayout(): boolean {
    return this.auditFormService.getFormLayoutType(this.auditForm) === LayoutType.Accordion;
  }

  private get isAutoCycleComplete(): boolean {
    return this.auditService.getAutoCycleComplete(this.audit, this.auditForm, this.schema);
  }

  private loadInformationItems(auditFormId: number) {
    this.informationService.getInformations(auditFormId).subscribe(
      (informations: Information[]) => {
        this.infoItems = informations.map(information => new MenuItem(information.title, () => {
          this.analytics.trackButtonClick('Information Item');
          this.analytics.trackAction('View Information Item');
          this.analytics.trackModelAction(ModelNameType.Information, information.id);
          const doc = new DOMParser().parseFromString(information.content, 'text/html');
          if (doc.documentElement.textContent) {
            // tslint:disable-next-line:max-line-length
            this.dialog.open(AlertComponent, {data: new ModalAlertData(of(information.title), of(this.domSanitizer.bypassSecurityTrustHtml(information.content)), AlertType.INFO, this.modalService.btnOK())}).afterClosed();
          }
        }));
      }
    );
  }

  private loadDocumentItems() {
    const documents = this.schema.documents || [];
    this.documentItems = documents.map((document) => new DocumentMenuItem(document, () => {
      if (this.apiService.networkStatus) {
        openUrl(document.url);
      } else {
        this.dialog.open(AlertComponent, {
          data: new ModalAlertData(
            this.translateService.get('audit-form.documents.no-internet-error-title'),
            this.translateService.get('audit-form.documents.no-internet-error-text'),
            AlertType.WARNING, this.modalService.btnOK())
        }).afterClosed();
      }
    }));
  }

  private loadForm(auditId: number, subformName: string | null, observationId: number | null): void {
    this.observationId = observationId;
    this.auditService.setAuditId(auditId);
    this.auditService.getAuditWithFormSchema(auditId).subscribe((result: [Audit, AuditForm, AuditFormSchema]) => {
        [this.audit, this.auditForm, this.schema] = result;
        this.loadDocumentItems();
        if (subformName == null) {
          if (this.auditFormService.hasSubforms(this.schema) && !this.isAccordionLayout()) {
            // this form has subform but no subform is specified
            // navigate to subform selection
            this.router.navigate(['select-form', auditId]);
            return;
          }
        } else {
          this.subform = this.schema.sub_forms.find(subform => subform.name === subformName) || null;
          if (this.subform === null) throw Error(`Cannot find subform ${subformName}`);
        }
        if (observationId === null) {
          this.observation = this.auditService.createObservation(this.audit, this.schema);
        } else {
          this.observation = this.audit.auditSession.observations[observationId];
        }

        if (this.observationId !== null && this.audit.errors !== null && this.audit.errors.observations) {
          this.fieldErrors = (this.audit.errors.observations as Errors[])[this.observationId];
        }

        this.institutionService.getWard(this.audit.wardId).subscribe((ward: Ward) => this.ward = ward);
      },
      error => {
        this.logger.error(error);
        this.snackBar.open(this.translateService.instant('audit-form.audit-load-fail'), undefined, {
          duration: 3000,
        });
        this.router.navigate(['draft-audits']);
      }
    );
  }

  /**
   * Returns sub-observation if the subForm was passed in, else returns observation
   * */
  public getSubObservation(): any {
    if (this.subform == null) {
      return this.observation;
    } else if (this.observation[this.subform.name] === undefined) {
      return this.observation[this.subform.name] = this.auditService.createSubObservation();
    } else {
      return this.observation[this.subform.name];
    }
  }

  public onClearClicked() {
    this.analytics.trackButtonClick('Clear');
    this.analytics.trackAction('Audit form cleared');
    if (confirm(this.translateService.instant('audit-form.clear-data-prompt'))) {
      this.resetForm();
      this.observation = this.auditService.createObservation(this.audit, this.schema);
      this.logger.debug('Data cleared');
    }
  }

  private resetForm() {
    this.observationComponent.clearForm();
  }

  /**
   * whether observation is being edited
   * (as opposed to creating new observation)
   */
  public get editing(): boolean {
    return this.audit.auditSession.observations.includes(this.observation);
  }

  public onBackClicked() {
    this.analytics.trackButtonClick('Back');
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      if (this.observationNumber <= 0 && this.subobservationNumber < 0) {
        // go to start page if (first subform of) first observation
        this.router.navigateByUrl('/');
      } else if (this.subobservationNumber >= 0) {
        // subform selection page if subform
        this.router.navigate(['select-form', this.audit.id]);
      } else {
        // Previous observation
        this.router.navigate(['audit-form', this.audit.id, 'edit', this.observationNumber - 1]);
      }
    });
  }

  private get observationNumber(): number {
    const number = this.audit.auditSession.observations.indexOf(this.observation);
    return number >= 0 ? number : -1;
  }

  private get subobservationNumber(): number {
    if (this.subform !== null) {
      const number = this.schema.sub_forms.indexOf(this.subform);
      return number >= 0 ? number : -1;
    }
    return -1;
  }

  private doSave(validateForm: boolean): Observable<boolean> {
    this.isNextLoading = true;
    return this.observationComponent.onSave(validateForm, undefined).pipe(
      mergeMap(() => {
        this.isNextLoading = false;
        this.save(validateForm);
        return of(true);
      }),
      catchError((e) => {
        this.isNextLoading = false;
        this.logger.error(e);
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          duration: 3000,
          data: new SnackbarData(e, 'warning'),
        });
        return of(false);
      }),
    );
  }

  private save(validateForm: boolean) {
    // collect data from forms to models
    const audit = this.audit;

    if (!this.editing) {
      // add observation to the audit if it was just newly created
      audit.auditSession.observations.push(this.observation);
    }
    this.auditService.saveAudit(audit).subscribe(() => {
      this.saved = true;
      // update current audit to trigger counter update in toolbar
      this.auditService.setAuditId(this.audit.id);
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        duration: 3000,
        data: new SnackbarData(this.translateService.instant('audit-form.observation-saved'), 'check'),
      });
      if (validateForm) {
        this.observation = this.auditService.createObservation(audit, this.schema);
        // noinspection PointlessBooleanExpressionJS
        if (this.observationId !== null || this.auditForm.config.save_next_observation === false || this.isAutoCycleComplete) {
          // observation was edited, go back to list of observations
          this.router.navigate(['audit-preview', this.audit.id]);
        } else if (this.subform === null) {
          // audit has no subforms, redirect back to the same page to start new observation
          this.router.navigate(['select-form', this.audit.id]);
        } else {
          // audit has subforms, redirect to subform selection page
          this.router.navigate(['select-form', this.audit.id]);
        }
      }
    });
  }

  public onProgressSave() {
    this.analytics.trackButtonClick('Save Progress');
    this.analytics.trackAction('Audit save draft');
    this.doSave(false).pipe(
      mergeMap(() =>  this.cloudSave())
    ).subscribe();
  }

  private cloudSave(): Observable<Audit> {
    return this.backup.backup(this.audit).pipe(
      tap(saved => this.logger.info('Audit progress saved to cloud', saved)),
    );
  }

  public onSave() {
    this.analytics.trackButtonClick('Save');
    this.analytics.trackAction('Audit save');
    this.doSave(true).subscribe();
  }

  /**
   * Checks whether user has input data
   * @return {boolean}
   */
  public isFormDirty() {
    return !this.saved && this.observationComponent !== undefined && this.observationComponent.isFormDirty();
  }

  public onDeleteClicked() {
    if (confirm(this.translateService.instant('audit-form.observation-delete-prompt'))) {
      this.audit.auditSession.observations = this.audit.auditSession.observations.filter((observation) => observation !== this.observation);
      this.auditService.saveAudit(this.audit).subscribe(() => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: new SnackbarData(this.translateService.instant('audit-form.observation-deleted'), 'check'),
          duration: 3000,
        });
        this.router.navigate(['audit-preview', this.audit.id]);
      });
    }
  }
}
