var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { IssueService } from './issue.service';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AccountService } from '../../accounts.service';
import { BaseComponent } from '../../base.component';
import { of } from 'rxjs/internal/observable/of';
import { IssueFilterComponent } from './issue-filter/issue-filter.component';
import { validateSessionToken } from '../../api/utils';
import { ApiService } from '../../api/api.service';
/**
 * A component that displays a list of issues from the back-end
 */
var IssueListComponent = /** @class */ (function (_super) {
    __extends(IssueListComponent, _super);
    function IssueListComponent(issueService, accountService, injector, api) {
        var _this = _super.call(this, injector) || this;
        _this.issueService = issueService;
        _this.accountService = accountService;
        _this.api = api;
        _this.error = null;
        return _this;
    }
    IssueListComponent.prototype.ngOnInit = function () {
        var _this = this;
        validateSessionToken(this.api, this.translateService);
        this.subscriptions.push(this.filterComponent.filterChanges$.pipe(tap(function (filter) { return _this.logger.debug('Filters update', filter); }), tap(function () { return _this.error = null; }), switchMap(function (filter) { return _this.accountService.getUser().pipe(mergeMap(function (user) {
            var issueHandlerId;
            var wardId;
            var auditFormId;
            if (filter) {
                if (filter.assigned_to_me && user.auditor)
                    issueHandlerId = user.auditor.id;
                if (filter.audit_form)
                    auditFormId = filter.audit_form.id;
                if (filter.ward)
                    wardId = filter.ward.id;
            }
            return _this.issueService.getIssues(0, issueHandlerId, auditFormId, wardId);
        }), map(function (result) { return result.results; })); }), map(function (issues) { return issues.map(function (issue) {
            return {
                issue: issue,
                location$: _this.issueService.getLocationLabel(issue.room),
                handler$: _this.issueService.getIssueHandlerName(issue.handler, issue.audit_form || null),
                status: _this.issueService.getIssueStatusData(issue),
            };
        }); }), catchError(function (error) {
            _this.logger.error('Error while loading issues', error);
            _this.error = error;
            return of([]);
        }), tap(function (result) { return _this.items = result; })).subscribe());
    };
    return IssueListComponent;
}(BaseComponent));
export { IssueListComponent };
