import {Component, Injector, NgZone, OnInit} from '@angular/core';
import {LoginCredentials} from './login_credentials';
import {AccountService} from '../accounts.service';
import {InstitutionService} from '../institution.service';
import {ApiService, STATUS_REQUEST_ERROR, STATUS_SERVER_ERROR, STATUS_UNAUTHORISED} from '../api/api.service';
import {LoginResponse} from '../api/responses/login-response';
import {HttpErrorResponse} from '@angular/common/http';
import {LoginErrorResponse} from '../api/responses/login-error-response';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AnalyticsService} from '../analytics.service';
import {WalkthroughService} from '../walkthrough/walkthrough.service';
import {FeedbackDialogComponent} from '../feedback-dialog/feedback-dialog.component';
import {ForgotPasswordDialogComponent} from '../forgot-password-dialog/forgot-password-dialog.component';
import {BaseComponent} from '../base.component';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {UserAuth} from '../api/responses/user-auth';
import {parseBase64Object} from '../utils/misc';
import {User} from '../api/models/user';
import {Region} from '../api/models/region';
import {DeepLinkEvent} from './deep-link-event';

export const contactUs = 'login.contact-us';
export const requestTrial = 'login.free-trial';

@Component({
  selector: 'meg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {
  public credentials = new LoginCredentials('', '');
  public sendingRequest = false;
  public errors: LoginErrorResponse | null = null;
  public requestTrialText = requestTrial;
  private nextUrl: string | null = null;
  public ssoLoginUrl?: string;

  constructor(private accountService: AccountService,
              private institutionService: InstitutionService,
              private apiService: ApiService,
              private router: Router,
              private analytics: AnalyticsService,
              private snackBar: MatSnackBar,
              private walkthroughService: WalkthroughService,
              private dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              private injector: Injector,
              private zone: NgZone,
            ) {
    super(injector);
  }

  private handleDeepLink(eventData: DeepLinkEvent) {
    this.logger.debug('Received deep link event', eventData);
    const params = eventData.params;
    if (params.next !== undefined) this.nextUrl = params.next;
    let auth: UserAuth | null = null;
    if (params.auth !== undefined) auth = parseBase64Object(params.auth);
    else if (params.auth_token) auth = new UserAuth(params.auth_token);
    if (auth === null || auth.token === null) {
      this.sendingRequest = false;
    } else {
      this.sendingRequest = true;
      this.apiService.tokenLogin(auth.token!, auth.region).subscribe(
        (loginResponse: LoginResponse) => this.onLoginSuccess(loginResponse)
      );
    }
  }

  ngOnInit() {
    const cordova = (window as any).cordova;
    if ( cordova !== undefined && cordova.platformId === 'ios') {
      this.requestTrialText = contactUs;
    }

    this.apiService.buildUrl('/app/').pipe(
      tap(url => this.logger.debug('Single sign-on url', url)),
      tap(url => this.ssoLoginUrl = url),
    ).subscribe();

    // clear previous login data before logging in a new user
    this.accountService.clearLogin().pipe(
      mergeMap(() => this.autoLogin()),
      tap((loggedIn: boolean) => {
        // show tutorial only when user is not being logged-in automatically
        if (!loggedIn) this.showWalkthrough();
      }),
    ).subscribe();
    const UniversalLinks = (window as any).universalLinks;
    if (UniversalLinks) {
      UniversalLinks.subscribe(null, (eventData: DeepLinkEvent) => this.zone.run(() => this.handleDeepLink(eventData)));
    }
  }

  /**
   * Authenticate user automatically using token passed in url.
   * Does nothing if auth_token was not passed.
   */
  private autoLogin(): Observable<boolean> {
    return this.activatedRoute.queryParams.pipe(
      map((params: Params): UserAuth | null => {
        if (params.next !== undefined) this.nextUrl = params.next;
        if (params.auth !== undefined) return parseBase64Object(params.auth);
        else if (params.auth_token) return new UserAuth(params.auth_token);
        else return null;
      }),
      tap((auth: UserAuth | null) => {
        if (auth === null || auth.token === null ) throw new Error();
        else this.sendingRequest = true;
      }),
      mergeMap((auth: UserAuth) => {
        this.logger.debug('Using token for login', auth);
        return this.apiService.tokenLogin(auth.token!, auth.region);
      }),
      tap(loginResponse => this.onLoginSuccess(loginResponse)),
      map(() => true),
      catchError(() => {
        this.sendingRequest = false;
        return of(false);
      }),
    );
  }

  /** Shows walkthrough to user it it was not shown yet. Returns true if user is redirected to walkthrough */
  private showWalkthrough(): boolean {
    const show = !this.walkthroughService.wasShown();
    if (show) this.router.navigate(['intro']);
    return show;
  }

  public onLoginClicked() {
    this.attemptLogin();
  }

  /**
   * Sends login request to back-end
   */
  public attemptLogin(region?: Region) {
    this.sendingRequest = true;
    this.errors = null;
    const credentials = this.credentials;
    this.logger.debug(`Logging in as ${credentials}`);
    credentials.captcha = this.accountService.captchaTokenSubj;
    this.apiService.login(credentials, region).subscribe(
      loginResponse => this.onLoginSuccess(loginResponse),
      error => this.onLoginError(error)
    );
    this.analytics.trackButtonClick('Login');
  }

  public onRequestFreeTrialClicked() {
    this.analytics.trackButtonClick('Request free trial');
    this.analytics.trackAction('Request free trial');
    this.dialog.open(FeedbackDialogComponent, {
      width: '100%',
      data: null,
    }).afterClosed().pipe(
      mergeMap((result: boolean) => {
        if (result) return this.modalService.openInfoModal(this.translateService.get('contact.success-message'));
        else return of(false);
      }),
    ).subscribe();
  }

  cancelLogin() {
    this.credentials.password = '';
    this.sendingRequest = false;
  }

  onLoginSuccess(loginResponse: LoginResponse) {
    this.accountService.setLoggedIn(loginResponse).subscribe((result: boolean) => {
      this.analytics.trackAction('Login successful');
      if (loginResponse.require_token) {
        this.logger.debug(loginResponse);
        const token = prompt(this.translateService.instant('login.enter-mfa-token'));
        if (token) {
          this.credentials.two_factor_token = token;
          // make second factor request directly to the downstream region as
          // now we know the exact region where user has their account.
          this.attemptLogin(loginResponse.region || undefined);
        } else {
          this.cancelLogin();
        }
      } else {
        const user: User = loginResponse.user as User;
        if (user.auditor.language === null) {
          this.accountService.setLanguage('en');
          this.translateService.use('en');
        } else {
          this.accountService.setLanguage(user.auditor.language);
          this.translateService.use(user.auditor.language);
        }
        this.logger.debug(`Successfully logged in as ${user.username}`);
        this.router.navigateByUrl(this.nextUrl ? `/loading?next=${this.nextUrl}` : '/loading');
      }
    },
    (e) => {
      this.logger.error(e);
      this.cancelLogin();
      this.snackBar.open('default-error', undefined, {
        duration: 3000,
      });
    });
  }

  onLoginError(error: any) {
    this.logger.error(error);
    this.cancelLogin();
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case STATUS_REQUEST_ERROR:
          this.errors = error.error;
          this.analytics.trackAction('Login error: invalid request');
          break;
        case STATUS_UNAUTHORISED:
          this.modalService.openErrorModal(this.translateService.get('login.unauthorised-error')).subscribe();
          this.analytics.trackAction('Login error: invalid password');
          break;
        case STATUS_SERVER_ERROR:
          this.modalService.openErrorModal(this.translateService.get('server-error')).subscribe();
          this.analytics.trackAction('Login error: server error');
          break;
        default:
          this.modalService.openErrorModal(this.translateService.get('no-internet-error')).subscribe();
          this.analytics.trackAction('Login error: offline');
      }
    }
  }

  onForgotPasswordClicked() {
    this.analytics.trackButtonClick('Forgot Password');
    this.analytics.trackAction('Forgot password');
    this.dialog.open(ForgotPasswordDialogComponent);
  }
}
